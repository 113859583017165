import React, { useMemo } from 'react'
import {
  motion,
  useTransform
} from 'framer-motion'

import useIsMobile from 'hooks/useIsMobile'

import CustomImage from '@/atoms/Image'
import ImageMask from '@/molecules/ImageMask'

import {
  StyledInfoGraphicImages,
  StyledInfoGraphicImage
} from './styles'

const InfographicImages = ({
  images,
  masked,
  imagePosition,
  coordinates,
}) => {
  const isMobile = useIsMobile()
  const isMaskedImage = useMemo(() => masked || images?.data?.length > 1, [masked, images])

  const Component = (props) => {
    const isEven = props.idx % 2 === 0
    const limitX = isEven ? [15, -15] : [-15, 15]
    const limitY = isEven ? [-15, 15] : [15, -15]

    const newX = useTransform(props.coordinates.x, [0, 3000], limitX)
    const newY = useTransform(props.coordinates.y, [0, 2250], limitY)

    const style = useMemo(() => {
      return !isMobile ? {
        x: newX, y: newY
      } : {}
    }, [isMobile])

    const Comp = useMemo(() => (
      isMaskedImage ?
        (
          <StyledInfoGraphicImage
            as={motion.div}
            style={style}
            customClassName="InfographicImage">
            <ImageMask image={{ data: props }} />
          </StyledInfoGraphicImage>
        )
        : (
          <StyledInfoGraphicImage
            as={motion.div}
            style={style}>
            <CustomImage data={props} />
          </StyledInfoGraphicImage>
        )
    ), [isMaskedImage])

    return Comp
  }

  return (
    images &&
    images.data &&
    <StyledInfoGraphicImages imagePosition={imagePosition} isMaskedImage={isMaskedImage}>
      {
        images.data.map((image, idx) => <Component idx={idx} coordinates={coordinates} key={image.id} {...image} />)
      }
    </StyledInfoGraphicImages>
  )
}

export default InfographicImages
