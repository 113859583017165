import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Paragraph from '@/molecules/Paragraph'
import Addresses from '@/molecules/Addresses'

const ParagraphWithAddresses = ({
  heading,
  paragraph,
  addresses
}) => (
  <SectionContainer heading={heading}>
    {
      paragraph &&
      <Paragraph { ...paragraph }/>
    }

    <Addresses items={addresses}/>

  </SectionContainer>
)

export default ParagraphWithAddresses
