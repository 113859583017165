import styled from 'styled-components'

import Link from '@/atoms/Link'

export const StyledNextPageLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.default()};
`

export const StyledNextPageLinkContent = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.rem(45)};


  > * + * {
    margin-block-end: ${({ theme })=> theme.rem(16)};
  }

  h5{
  }

  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 25px;
    border-left: 2px dotted ${({ theme }) => theme.colors.secondary.default};
    z-index: 2;
  }
`
