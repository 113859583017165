import React from 'react'


import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
import ImageMask from '@/molecules/ImageMask'

import {
  StyledAttitudes,
  StyledAttitude,
} from './styles'

const Attitudes = ({ items }) => (
  items &&
  !!items.length &&
  <StyledAttitudes>
    {
      items.map(({
        title,
        text,
        id,
        image
      }) => (
        <StyledAttitude key={id + title}>
          {
            title &&
            <Label
              as="p"
              typo="subheading1"
              uppercase
              $title
              bold
              center
              dangerouslySetInnerHTML={{ __html: title }}
            />

          }

          {
            text &&
            <Richtext
              bold
              center
              typo="paragraph1"
              className="Attitude_text">
              {text}
            </Richtext>
          }
          {
            image &&
            image.data &&
            <ImageMask
              customClassName="Attitude_image"
              image={image} />
          }
        </StyledAttitude>
      ))
    }

  </StyledAttitudes>
)

export default Attitudes
