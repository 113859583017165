import React from 'react'
import { useRouter } from 'next/router'
import { useLenis } from '@studio-freight/react-lenis'
import { motion } from 'framer-motion'

import {
  hidden,
  visible
} from 'animations/ScaleAndShow'

import { Label } from '@/atoms/Typography'
import ImageFramed from '@/molecules/ImageFramed'
import {
  StyledBoxes,
  StyledBoxesWrapper,
  StyledBox,
} from './styles'

const boxesVariants = {
  hidden: {},
  visible: {
    transition: {
      duration: 1.4,
      staggerChildren: 0.07
    }
  }
}

const boxVariants = {
  hidden,
  visible,
}

const Box = ({
  boxTitle,
  image,
  anchorTo,
  boxProps,
}) => {
  const router = useRouter()
  const lenis = useLenis()

  return (
    <StyledBox
      {...boxProps}
      variants={boxVariants}
      transition={{ duration: 0.6 }}
      onClick={() => {
        const section = document.querySelector(`section[data-target="${anchorTo}"]`)
        if (!section) return
        const pathAndSlug = router.asPath.split('#')[0]
        const newPath = `${pathAndSlug}#${anchorTo}`

        window.location.replace(newPath)

        lenis.scrollTo(section.offsetTop - 100)
      }}>
      <ImageFramed className="Box_frame" image={image}/>
      <Label $title bold>{boxTitle}</Label>
    </StyledBox>
  )
}

const Boxes = ({
  title,
  value
}) => {
  return (
    <StyledBoxes>
      {
        title &&
        <Label as="strong" typo="h5" bold $title>{title}</Label>
      }

      {
        value &&
        !!value.length &&
        <StyledBoxesWrapper
          as={motion.div}
          variants={boxesVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {
            value.map(({
              id,
              title: boxTitle,
              image,
              anchorTo,
            }) => {
              const boxProps = { as: motion.div }
              if (anchorTo) {
                boxProps.as = motion.button
              }
              return (
                <Box
                  anchorTo={anchorTo}
                  boxTitle={boxTitle}
                  image={image}
                  key={id + title}
                  boxProps={boxProps}
                />
              )
            })
          }

        </StyledBoxesWrapper>
      }

    </StyledBoxes>
  )
}

export default Boxes
