import React, { useContext } from 'react'

import { generateHref } from 'api/utils'
import { MenuContext } from 'contexts/Menu'

import {
  Heading,
  Label
} from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'

import {
  StyledNextPageLink,
  StyledNextPageLinkContent
} from './styles'

const NextPageLink = ({
  data,
  label,
  children
}) => {
  const { menus } = useContext(MenuContext)
  const { header } = menus

  if (!data) return <></>
  const href = generateHref(data.attributes)
  const {
    title,
    slug
  } = data.attributes

  const findCurrentItem = header.items.find((item) => item.link.slug === slug)

  return (
    <StyledNextPageLink href={href}>
      <StyledNextPageLinkContent>
        {
          findCurrentItem &&
          findCurrentItem.withIndex &&
          <Label center bold typo="h5" $title>{findCurrentItem.withIndex}.</Label>
        }

        <Heading as="h5" typo="h5" $title bold center uppercase dangerouslySetInnerHTML={{ __html: title }} />

        {
          label &&
          <Richtext center bold typo="link2" $title>{label}</Richtext>
        }
      </StyledNextPageLinkContent>

      {children}
    </StyledNextPageLink>
  )
}

export default NextPageLink
