import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Paragraph from '@/molecules/Paragraph'
import Boxes from '@/molecules/Boxes'

const Values = ({
  heading,
  paragraph,
  boxes,
}) => (
  <SectionContainer heading={heading}>
    {
      paragraph &&
      <Paragraph {...paragraph} />
    }

    <Boxes {...boxes}/>
  </SectionContainer>
)

export default Values
