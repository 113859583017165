import React, {
  useMemo,
  useRef,
  useState
} from 'react'

import { motion } from 'framer-motion'

import CustomImage from '@/atoms/Image'
import PercentItem from './PercentItem'

import MapModal from '@/molecules/Modals/MapModal'
import {
  StyledMap,
  StyledMapContainer,
  StyledMapImage,
  StyledMapItems,
} from './styles'

const variants = {
  hidden: {},
  visible: {
    transition: {
      duration: 1.4,
      staggerChildren: 0.07
    }
  },
}


const Map = ({
  full = false,
  image,
  items,
  children
}) => {
  const containerRef = useRef(null)
  const [highlighted, setHighlighted] = useState(null)

  const content = useMemo(() => {
    if (!highlighted) return null

    const filteredItem = items.filter(item => item.id === highlighted)
    if (!!filteredItem.length) return filteredItem[0]

    return false
  }, [highlighted])

  return (
    <StyledMap full={full}>
      <MapModal containerRef={containerRef} isOpen={!!content} handler={() => setHighlighted(null)}  {...content} />

      <StyledMapContainer ref={containerRef}>
        {
          image &&
          <StyledMapImage>
            <CustomImage {...image}/>
          </StyledMapImage>
        }

        {
          items &&
          !!items.length &&
          <StyledMapItems
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants}
            as={motion.div}
          >
            {
              items.map(item =>(
                <PercentItem
                  {...item}
                  key={item.id + item.title}
                  onMouseEnter={setHighlighted}
                  onMouseLeave={setHighlighted}
                />
              ))
            }
          </StyledMapItems>
        }

      </StyledMapContainer>

      {children}
    </StyledMap>
  )
}

export default Map
