import styled from 'styled-components'
import styledMap from 'styled-map'

import Link from '@/atoms/Link'

export const StyledCircledLink = styled(Link)`
  aspect-ratio: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  padding: ${({ theme })=> theme.rem(24)};

  background-color: ${styledMap`
    default: ${({ theme })=> theme.colors.grayscale.white()};
    $secondary: ${({ theme })=> theme.colors.primary.default()};
  `};

  color: ${styledMap`
    default: ${({ theme })=> theme.colors.secondary.default};
    $secondary: ${({ theme })=> theme.colors.grayscale.white()};
  `};

  ${({
    theme,
    size
  }) => size && theme.size(size)}

  strong{
    margin-bottom: ${styledMap`
      default: ${({ theme })=> theme.rem(20)};
      $secondary: ${({ theme })=> theme.rem(8)};
    `};
  }

  svg{
    will-change: color;
    transition: color ease-in-out .4s;
  }

  &:hover{
      text-decoration: underline;
      i {
        background-color: ${styledMap`
          default: ${({ theme })=>theme.colors.secondary.default};
          $secondary: ${({ theme })=>theme.colors.grayscale.white()};
      `};
      }

      svg{
        color: ${styledMap`
          default: ${({ theme })=>theme.colors.grayscale.white()};
          $secondary: ${({ theme })=>theme.colors.primary.default()};
        `};
      }

    }


`

export const StyledCircledLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap:${({ theme })=> theme.rem(16)};

  strong{
    display: block;
    font-weight: 700;
    font-family: ${({ theme })=> theme.fonts.title};
  }

  i{
    padding: 0;
    border-radius: 50%;
    border: 1px solid currentColor;
    transition: background-color ease-in-out .4s;

    font-size: ${({ theme })=> theme.rem(12)};
    ${({ theme }) => theme.size(30)};

    ${({ theme }) => theme.mediaQuery.md(`
      font-size: ${theme.rem(16)};
      ${theme.size(50)};
    `)};
  }
`
