import styled from 'styled-components'

export const StyledLinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li{
    position: relative;
    margin-block-start: ${({ theme }) => theme.rem(16)};
    display: flex;
    align-items: center;
    column-gap:  ${({ theme }) => theme.rem(8)};
    &:before{
      content: '–';
    }

    a{
      color: ${({ theme }) => theme.colors.secondary.default};
      &:hover{
        text-decoration: underline;
      }
    }
  }
`
