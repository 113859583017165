import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import ExportProducts from '@/molecules/ExportProducts'

const Export = ({
  heading,
  products
}) => (
  <SectionContainer heading={heading}>
    <ExportProducts  items={products}/>
  </SectionContainer>
)

export default Export
