import React from 'react'

import {
  hidden,
  visible
} from 'animations/ScaleAndShow'

import { Label } from '@/atoms/Typography'
import CustomImage from '@/atoms/Image'

import {
  StyledProducts,
  StyledProductsRow,
  StyledProduct,
} from './styles'

const rowVariants = {
  hidden: {},
  visible: { transition: { staggerChildren: 0.15 } }
}

const productVariants = {
  hidden, visible
}
const Products = ({ items }) => (
  items &&
  !!items.length &&
  <StyledProducts>
    {
      items.map(row => (
        <StyledProductsRow
          variants={rowVariants}
          initial="hidden"
          whileInView="visible"
          key={row}>
          {
            row.map(({
              name,
              image,
              certificate
            }) =>(
              <StyledProduct
                key={image}
                certificate={certificate}
                variants={productVariants}
                transition={{ duration: 0.6 }}
              >
                {
                  name &&
                  <Label
                    as="strong"
                    typo="paragraph3"
                    center
                    $title
                    bold>
                    {name}
                  </Label>
                }

                {
                  image &&
                  image.data &&
                  <CustomImage {...image} />
                }

              </StyledProduct>
            ))
          }
        </StyledProductsRow>
      ))
    }
  </StyledProducts>
)

export default Products
