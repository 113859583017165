import SectionContainer from '@/molecules/SectionContainer'
import ParagraphMolecule from '@/molecules/Paragraph'


const Paragraph = ({
  heading,
  paragraphs
}) => {

  return (
    <>
      <SectionContainer heading={heading}>     
        {
          paragraphs &&
          !!paragraphs.length &&
          paragraphs.map(paragraph => <ParagraphMolecule key={paragraph.title} {...paragraph} />)
        }
      </SectionContainer>
    </>
  )
}

export default Paragraph

