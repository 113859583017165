import React from 'react'

import LinkWithIcon from '@/molecules/LinkWithIcon'
import CircledLink from '@/molecules/CircledLink'

import {
  StyledAttachments,
  StyledAttachment
} from './styles'

const Attachments = ({
  items,
  type = 'default'
}) => {
  return (
    <StyledAttachments className='Attachments'>
      {
        items.map(({
          // icon,
          label,
          file,

        }, idx) => {
          const { url } = file.data.attributes

          return (
            <StyledAttachment className='Attachment' key={`attachment_${idx}`}>
              {type !== 'default'
                ? <CircledLink label={label} icon="arrow-down" link={{ href: url }} />
                : <LinkWithIcon right icon="arrow-down" href={url}>{label}</LinkWithIcon>}
            </StyledAttachment>
          )
        })
      }
    </StyledAttachments>
  )
}

export default Attachments
