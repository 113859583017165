import React from 'react'
import { useField  } from 'formik'

import CustomField from './index'

const Address = ({
  label,
  dependsOn,
  ...props
}) => {
  // TODO: use google api to get data from address
  const [field, meta] = useField(props)
  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
      dependsOn={dependsOn}
    >
      <input id={field.name} {...field} {...props} />
    </CustomField>
  )
}

export default Address
