import React from 'react'

import CustomImg from '@/atoms/Image'
import Mask from '@/atoms/Mask'

import { StyledMask } from './styles'

const ImageMask = ({
  image,
  customClassName = null,
  coordinates,
  as,
  ...rest
}) => (
  image &&
  <StyledMask
    as={as}
    style={coordinates}
    className={customClassName}
  >
    <Mask {...rest}>
      <CustomImg {...image} />
    </Mask>
  </StyledMask>
)


export default ImageMask
