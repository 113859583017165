import React from 'react'


import Icon from '@/atoms/Icon'
import Button from '@/atoms/Button'

import { Heading } from '@/atoms/Typography'
import { Spacer } from '@/atoms/Layout'

import {
  BaseAccordion,
  BaseAccordionHeader,
  BaseAccordionContent,
  BaseAccordionHeaderContent,
  BaseAccordionIconLeft,
  BaseAccordionToggle
} from './styles.js'

const Base = ({
  refs, accordion, iconLeft, tabIndex, disabled, title, subtitle, content, handleClick, passedKey
}) => {
  return (
    <BaseAccordion tabIndex={tabIndex} ref={refs.wrapper} disabled={disabled} key={passedKey} aria-expanded={accordion.open}>
      <BaseAccordionHeader
        ref={refs.header}
        open={accordion.open}
      >
        <BaseAccordionIconLeft>
          <Icon icon={iconLeft} />
        </BaseAccordionIconLeft>
        <BaseAccordionHeaderContent>
          <Heading typo={'subheading1'}>
            { title }
          </Heading>
          <Spacer xs={0.5} />
          <Heading typo={'subheading2'} bold>
            { subtitle }
          </Heading>
        </BaseAccordionHeaderContent>
        <BaseAccordionToggle open={accordion.open}>
          <Button iconStart={'chevron-down'} tertiary handleClick={() => handleClick(accordion)}/>
        </BaseAccordionToggle>
      </BaseAccordionHeader>
      <BaseAccordionContent
        ref={refs.content}
        dangerouslySetInnerHTML={{ __html: content }}
        open={accordion.open}
        role={'region'}
      />
    </BaseAccordion>
  )
}

export default Base
