import Frame from '@/atoms/Frame'
import styled from 'styled-components'

export const StyledPercentItem = styled.div`
  position: absolute;

  /* ${({ theme }) => theme.mediaQuery.md(`
    max-width: 100px;
  `)} */

  div${Frame} {
    padding:  ${({ theme }) => theme.rem(4)};
    aspect-ratio: unset;
    ${({ theme }) => theme.mediaQuery.md(`
      padding: ${ theme.rem(8)};
    `)}
  }

`

export const StyledPercentItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  transition: background-color ease-in-out .4s;
  background-color: ${({ theme }) => theme.colors.grayscale.white()};
  padding: ${({ theme }) => theme.rem(14)};
  ${({ theme }) => theme.size('100%')};
  cursor: pointer;



  ${({ theme }) => theme.mediaQuery.md(`
    padding: ${theme.rem(20)};
  `)}

  &:hover{
    background-color: ${({ theme }) => theme.colors.background.lightBlue};

    .DiscoverMore{
      text-decoration: underline;
    }
  }
`
export const StyledPercentItemDetails = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.rem(2)};
  margin-bottom: ${({ theme }) => theme.rem(4)};


  ${({ theme }) => theme.mediaQuery.md(`
    column-gap: ${theme.rem(4)};
  `)}

  img{
    display: none;

    ${({ theme }) => theme.mediaQuery.md(`
      display: inline-block;
      width: 20px;
    `)}
  }
`
