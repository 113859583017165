import styled from 'styled-components'

export const StyledSingleProduct = styled.div`
  display: block;
  text-align: center;
`

export const StyledSingleProductLogo = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.rem(16)};
  margin-bottom: ${({ theme }) => theme.rem(80)};
  &::after{
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      height: ${({ theme }) => theme.rem(80)};
      width: 2px;
      border-right: 2px dotted ${({ theme }) => theme.colors.grayscale.black()};
    }
`

export const StyledSingleProductMain = styled.div`
  aspect-ratio: 1;
  display: block;
  max-width: 600px;
  margin: 0 auto;
`


export const StyledSingleProductImage = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  position: relative;
`

export const StyledSingleProductBoxes = styled.div`
  margin-top: ${({ theme }) => theme.rem(32)};
  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${theme.size('200%')};
  `)}

  .Circle_text{
    margin-top: ${({ theme }) => theme.rem(15)};
  }

  ${({ items, }) => {
    if (items === 4) {
      return `
      .CircleBox:nth-child(1){
        &::before{
          transform: translate(-50%, -100%) rotate(-60deg);
        }
      }
      .CircleBox:nth-child(2){
        &::before{
          transform: translate(-50%, -100%) rotate(-18deg);
        }
      }
      .CircleBox:nth-child(3){
        &::before{
          transform: translate(-50%, -100%) rotate(18deg);
        }
      }
      .CircleBox:nth-child(4){
        &::before{
          transform: translate(-50%, -100%) rotate(60deg);
        }
      }
      `
    }

    return `
    .CircleBox:nth-child(1){
        &::before{
          transform: translate(-50%, -100%) rotate(-25deg);
        }
      }
      .CircleBox:nth-child(2){
        &::before{
          transform: translate(-50%, -100%) rotate(25deg);
        }
      }
    `
  }}

  .CircleFrame{
    background-color: ${({ theme }) => theme.colors.background.beige};
  }



  .CircleBox{
    ${({
    theme, items
  }) => theme.mediaQuery.md(`
     &::before {
      content: '';
      height: ${items === 4 ? '110%' : '80%' };
      width: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -100%);
      border-right: 2px dotted ${theme.colors.secondary.default};
      position: absolute;
      transform-origin: bottom center;
      z-index: -1;
    }
    `)}

  }

`

export const StyledSingleProductAdditionalInfo = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-template-areas: "icon content";

  img{
    max-width: 12.5vw;
    width: 100%;
  }
`

export const StyledSingleProductAdditionalInfoContent = styled.div`
  grid-area: content;
  text-align: left;
  padding: ${({ theme }) => theme.rem(32)};
`

export const StyledAdditionalInfos = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.rem(24)};
  gap: ${({ theme }) => theme.rem(24)} ;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
  `)}
`
export const StyledAdditionalInfo = styled.div`
  text-align: left;
  max-width: 30%;
  strong{
    color: ${({ theme }) => theme.colors.secondary.default};
  }

  ${({ theme }) => theme.mediaQuery.xl(`
    max-width: 20%;
  `)}
`
