import React from 'react'

import OvalInfos from '../OvalInfos'
import WithHeading from '../WithHeading'
import Attitudes from '../Attitudes'

import {
  StyledInfos, StyledInfosMain
} from './styles'

const Infos = ({
  heading,
  attitudes,
  ovalInfos,
}) => (
  <StyledInfos>
    {
      heading &&
      <WithHeading
        full
        uppercase
        center
        heading={heading}
      />
    }
    <StyledInfosMain>
      <OvalInfos items={ovalInfos} />
      <Attitudes items={attitudes} />
    </StyledInfosMain>
  </StyledInfos>
)

export default Infos
