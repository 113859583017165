import React from 'react'

import {
  AnimatePresence,
  motion
} from 'framer-motion'

import Tab from './Tab'

import {
  StyledTabs,
  StyledTabsHeader,
  StyledTabsContent,
} from './styles'


const animationSettings = {
  initial: {
    x: 100, opacity: 0
  },
  animate: {
    x: 0, opacity: 1
  },
  exit: {
    x: -100, opacity: 0
  },
  transition: { duration: 0.3 }
}


const Tabs = ({
  full = false,
  header,
  children,
  onTabClick = null,
  tabsAnimationSettings = {},
  selectedTab,
}) => {
  return (
    <StyledTabs full={full}>
      <StyledTabsHeader
        data-lenis-prevent>
        {
          header.map(({
            id,
            title,
          }) => (
            <Tab
              key={id}
              id={id}
              onTabClick={onTabClick}
              selectedTab={selectedTab}
              title={title}
            />
          ))}
      </StyledTabsHeader>

      <StyledTabsContent>
        <AnimatePresence mode='wait' >
          <motion.div
            key={selectedTab ? selectedTab : 'empty'}
            { ...animationSettings }
            { ...tabsAnimationSettings }
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </StyledTabsContent>
    </StyledTabs>
  )
}

export default Tabs
