import styled from 'styled-components'

import Button from '@/atoms/Button'

export const StyledPreviewCityButton = styled(Button)`
  display: block;
  text-align: left;
  ${({ theme }) => theme.paddingY(16)};
  color:${({ theme }) => theme.colors.primary.default()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.gray2};
  border-radius: 0;

  .CityButton_icon{
    font-size: ${({ theme }) => theme.rem(28)};
    color: ${({ theme }) => theme.colors.grayscale.black()};
    margin-right: ${({ theme }) => theme.rem(15)} !important;
  }
  > span{
    display: grid;
    grid-template-areas: "icon city cta";
    grid-template-columns: 1fr 19fr 2fr;
    align-items: flex-end;
    text-transform: none;
  }

  .CityButton_cta{
    display: flex;
    align-items: center;
    column-gap: ${({ theme }) => theme.rem(8)};
    i{
      font-size: ${({ theme }) => theme.rem(12)};
    }
  }
`

export const StyledCityContentStepHeader = styled.div`
  display: grid;
  grid-template-areas:
    "icon empty"
    "title title"
    "description description";
  grid-template-columns: 1fr 11fr;
  padding: ${({ theme }) => `0 ${theme.rem(24)}`};
  color: ${({ theme }) => theme.colors.primary.default()};

  ${({ theme }) => theme.mediaQuery.md(`
  padding: ${`0 ${theme.rem(40)}`};
    grid-template-columns: 11fr 1fr;
    grid-template-areas:
      "title icon"
      "description description";
  `)};

  .Header_title{
    grid-area: title;
    text-align: center;
    margin-top: ${({ theme }) => theme.rem(64)};
    ${({ theme }) => theme.mediaQuery.md(`
      margin-top: 0;
      text-align: left;
    `)}
  }

  .Back{
    grid-area: icon;
    margin-right: ${({ theme }) => theme.rem(32)};
    color: ${({ theme }) => theme.colors.grayscale.black()};
    height: 20px;
    i{
      font-size: ${({ theme }) => theme.rem(20)};
    }
  }

  .Header_description{
    grid-area: description;
    max-width: 100%;
    max-height: 260px;
    margin-top: ${({ theme }) => theme.rem(16)};+
    overflow: auto;
    ${({ theme }) =>theme.mediaQuery.md(`
      max-width: 80%;

  `)}
  }
`

export const StyledCity = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.gray2};
  padding: ${({ theme }) => theme.rem(24)};
  margin-top: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>theme.mediaQuery.md(`
    padding: ${theme.rem(40)};
    margin-top: ${theme.rem(40)};
    min-height: 40%;
    position: relative;
  `)}

  .City_preview{
    right: 50%;
    transform: translateX(50%);
    width: 180px;
    top: -90px;
    position: absolute;

    ${({ theme }) =>theme.mediaQuery.md(`
      width: 250px;
      top: -125px;
      right: 0;
    `)}
  }
`

export const StyledCityList = styled.div`
  display: grid;

  align-items: center;
  gap:${({ theme }) => theme.rem(16)};
  width: 100%;
  overflow: auto;
  height: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: 1fr 1fr;
    max-width: 85%;
    max-height: 300px;
  `)}
`
export const StyledCityInfo = styled.div`
display: flex;
align-items: flex-start;
justify-content: flex-start;
column-gap: ${({ theme }) => theme.rem(16)};


.ImageIcon{
  flex-shrink: 0;
  width: 60px;
}
`
export const StyledCityInfoContent = styled.div`
  color:${({ theme }) => theme.colors.primary.default()};
  display: grid;
  gap:${({ theme }) => theme.rem(4)};
  `
