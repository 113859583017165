import React, {
  useEffect,
  useCallback
} from 'react'
import { useLenis } from '@studio-freight/react-lenis'
import { useRouter } from 'next/router'

import {
  motion,
  AnimatePresence
} from 'framer-motion'

import Portal from '@/atoms/Portal'

import {
  StyledStandardModal,
  StyledStandardModalContainer,
  StyledStandardModalClose
} from './styles'


const lockUnlockBody = (isOpen, lockBody, lenis) => {
  if (!lockBody) return
  if (isOpen) {
    lenis?.stop()
    return
  }
  lenis?.start()
}


const StandardModal = ({
  wrapperId = 'react-portal-modal',
  isOpen,
  setIsOpen,
  hideCloseButton,
  children,
  wrapperProps = {},
  containerProps = {},
  lockBody = true
}) => {
  const router = useRouter()
  const lenis = useLenis()

  useEffect(()=> {
    lockUnlockBody(isOpen, lockBody, lenis)
  }, [isOpen, lenis])

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) setIsOpen()
      lenis?.destroy()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [setIsOpen, isOpen, router.events])

  const onEscPress = useCallback((event) => {
    if (event.key === 'Escape' && isOpen) {
      setIsOpen()
    }
  }, [setIsOpen])

  useEffect(() => {
    document.addEventListener('keydown', onEscPress, false)

    return () => {
      document.removeEventListener('keydown', onEscPress, false)
    }
  }, [isOpen, onEscPress])

  return (
    <AnimatePresence>
      {
        isOpen &&
        <Portal wrapperId={wrapperId}>
          <>
            <StyledStandardModal
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              {...wrapperProps}
            >

              <StyledStandardModalContainer
                as={motion.div}
                {...containerProps}
              >
                {
                  !hideCloseButton &&
                  <StyledStandardModalClose onClick={() => setIsOpen(false)}>
                    chiudi
                  </StyledStandardModalClose>
                }


                {children}
              </StyledStandardModalContainer>

            </StyledStandardModal>
          </>
        </Portal>
      }
    </AnimatePresence>
  )
}

export default StandardModal
