import React from 'react'

import OvalCard from '@/molecules/OvalCard'
import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'

import {
  StyledOvalInfos,
  StyledOvalInfo
} from './styles'

const OvalInfos = ({ items }) => (
  items &&
  !!items.length &&
  <StyledOvalInfos>
    {
      items.map(({
        title,
        description,
        id
      }) => (
        <StyledOvalInfo key={id + title}>
          <OvalCard
            $secondary
          >
            {
              title &&
              <Label
                typo="h5"
                $title
                bold
                center>{title}</Label>
            }
          </OvalCard>

          {
            description &&
            <Richtext className="OvalInfo_description" center typo="paragraph1">
              {description}
            </Richtext>
          }
        </StyledOvalInfo>
      ))
    }

  </StyledOvalInfos>
)

export default OvalInfos
