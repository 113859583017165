import React from 'react'

import {
  hidden,
  visible
} from 'animations/ScaleAndShow'

import CustomImage from '@/atoms/Image'
import Richtext from '@/atoms/RichText'
import SimpleLink from '@/atoms/Link'

import {
  StyledLeadership,
  StyledLeadershipGroup,
  StyledLeadershipGroupPreview,
  StyledLeadershipGroupImages,
  StyledLeadershipGroupRow,
  StyledLeadershipGroupLogo,
  StyledLeadershipGroupLogoEl,
} from './styles'

const rowVariants = {
  hidden: {},
  visible: { transition: { staggerChildren: 0.2 } }
}

const logoVariants = {
  hidden, visible
}

const Leadership = ({ units }) => (
  units &&
  !!units.length &&
  <StyledLeadership>
    {
      units.map(({
        description,
        id,
        image,
        rows,
      }) => (
        <StyledLeadershipGroup key={id}>
          <StyledLeadershipGroupPreview withImage={!!image.data}>
            {
              image &&
              image.data &&
              <CustomImage {...image} />
            }
            {
              description &&
              <Richtext className="LeadershipPreview_content">
                {description}
              </Richtext>
            }
          </StyledLeadershipGroupPreview>

          {
            rows &&
            !!rows.length &&
            <StyledLeadershipGroupImages>
              {
                rows.map(({ logos }) => (
                  logos &&
                  !!logos.length &&
                  <StyledLeadershipGroupRow
                    key={logos}
                    length={logos.length}
                    variants={rowVariants}
                    initial="hidden"
                    whileInView="visible"
                  >
                    {
                      logos.map(({
                        url,
                        target,
                        image: logo
                      }) => (
                        logo &&
                        logo.data &&
                        <StyledLeadershipGroupLogo
                          variants={logoVariants}
                          transition={{ duration: 0.8 }}
                          key={logo}
                        >
                          <StyledLeadershipGroupLogoEl
                            as={url ? SimpleLink : 'div'}
                            href={url}
                            target={target}
                          >

                            <CustomImage {...logo} />
                          </StyledLeadershipGroupLogoEl>
                        </StyledLeadershipGroupLogo>
                      ))
                    }
                  </StyledLeadershipGroupRow>
                ))
              }
            </StyledLeadershipGroupImages>
          }
        </StyledLeadershipGroup>
      ))
    }
  </StyledLeadership>
)

export default Leadership
