import React from 'react'

import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'
import CustomImage from '@/atoms/Image'
import Richtext from '@/atoms/RichText'

import {
  StyledProjectsPreview,
  StyledProjectsPreviewItem,
  StyledProjectsPreviewItemImage,
  StyledProjectsPreviewItemContent,
} from './styles'

const ProjectsPreview = ({ items }) => (
  items &&
  !!items.length &&
  <StyledProjectsPreview>
    {
      items.map(({
        id, excerpt, link, previewImage, title
      }) => (

        <StyledProjectsPreviewItem key={id} {...link}>
          <StyledProjectsPreviewItemImage>
            <CustomImage {...previewImage} />
          </StyledProjectsPreviewItemImage>
          <StyledProjectsPreviewItemContent>
            <Label
              className='Preview_title'
              typo="subheading2"
              as="strong"
              bold
              $title
            >
              {title}
              <Icon icon="chevron-right" />
            </Label>
            <Richtext typo="paragraph2">
              {excerpt}
            </Richtext>
          </StyledProjectsPreviewItemContent>

        </StyledProjectsPreviewItem>
      ))
    }

  </StyledProjectsPreview>
)

export default ProjectsPreview
