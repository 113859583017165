import React from 'react'
import {
  FaqAccordion,
  FaqAccordionHeader,
  FaqAccordionContent,
  FaqAccordionHeaderContent,
  FaqAccordionToggle
} from './styles.js'

import { Heading } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import { Spacer } from '@/atoms/Layout'

const Faq = ({
  refs, accordion, tabIndex, disabled, title, content, link, handleClick, passedKey
}) => {
  return (
    <FaqAccordion tabIndex={tabIndex} ref={refs.wrapper} disabled={disabled} key={passedKey} aria-expanded={accordion.open}>
      <FaqAccordionHeader
        ref={refs.header}
        open={accordion.open}
      >
        <FaqAccordionToggle
          handleClick={() => handleClick(accordion)}
          open={accordion.open}
        >
          <span />
          <span />
        </FaqAccordionToggle>
        <FaqAccordionHeaderContent>
          <Heading typo={'subheading2'} medium>
            { title }
          </Heading>
        </FaqAccordionHeaderContent>
      </FaqAccordionHeader>
      <FaqAccordionContent
        ref={refs.content}
        open={accordion.open}
        role={'region'}
      >
        {content}
        {
          link ? (
            <>
              <Spacer xs={1} />
              <Button tertiary label={link.label} href={link.url} />
            </>
          ) : null
        }
      </FaqAccordionContent>
    </FaqAccordion>
  )
}

export default Faq
