import React from 'react'

import PersonPreview from '@/molecules/Person/Preview'
import { StyledPeopleList } from './styles'


const PeopleList = ({ people }) => (
  people &&
  !!people.length &&
  <StyledPeopleList>
    {
      people.map(person => <PersonPreview key={person.id} {...person} />)
    }
  </StyledPeopleList>
)

export default PeopleList
