import React from "react";
import { Styled } from "./styles";
import { useScreenDetector } from "hooks/useScreenDetector";

const Purpose = ({
  children,
  color,
  onClick
}) => {
  const {isTablet} = useScreenDetector();
  return(
    <>
      
      {isTablet ? 
        <Styled.Details $bgColor={color} onClick={onClick}>{children}</Styled.Details> : 
        <Styled.Button $bgColor={color} onClick={onClick}>{children}</Styled.Button>
      }
    </>
  )
}

export default Purpose