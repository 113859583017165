import styled from 'styled-components'

export const StyledParagraph = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-block-start: ${({ theme }) => theme.rem(76)};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
  `)}
`

export const StyledParagraphContent = styled.div`
  width: 100%;

  .Paragraph_heading{
    margin-bottom: ${({ theme }) => theme.rem(24)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    max-width: 55%;
    margin-bottom: 0;
  `)}
`

export const StyledParagraphMedia = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.rem(32)};

  > div{
    margin-block-start: ${({ theme }) => theme.rem(32)};
  }
  ${({ theme }) => theme.mediaQuery.md(`
    width: 45%;
    margin-top: 0;
    padding-left: ${theme.rem(48)};
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    flex-wrap: nowrap;
  `)}

`
export const StyledParagraphMediaContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.lg(`
    flex-wrap: nowrap;
  `)}

  > div{
    max-width: 250px;

    width: 100%;
    ${({ theme }) => theme.mediaQuery.lg(`
      max-width: 450px;
    `)}
  }

  > a{
    max-width: 250px;
    width: 100%;
  }
`


export const StyledParagraphContentLinks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(16)};

  margin-top: ${({ theme }) => theme.rem(32)};
  a{
    display: flex;
    align-items: center;
    padding: 0;
    column-gap: ${({ theme }) => theme.rem(16)};
  }
`
