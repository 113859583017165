import styled from 'styled-components'

export const StyledVideoWithModal = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.rem(50)};
  > button{
    width: 100%;
    span{

      width: 100%;
    }
  }

  &.ParagraphVideo{
    margin-bottom:0;
  }
`


export const StyledVideoCta = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  aspect-ratio: 1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.grayscale.white(0.5)};
  color: ${({ theme }) => theme.colors.grayscale.white()};
  padding: ${({ theme }) => theme.rem(16)};
  text-transform: none;



  ${({ theme }) => `
    ${theme.size('100%')};
    ${theme.size(126, true)};
  `};

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.size('70%')}
    ${theme.size(166, true)}
  `)};

  i{
    font-size: ${({ theme }) => theme.rem(39)};
    color: ${({ theme }) => theme.colors.grayscale.white()};
    ${({ theme }) => theme.mediaQuery.md(`font-size: ${theme.rem(49)};`)};
    svg{
      ${({ theme }) => theme.size('auto')};
    }
  }

  span{
    margin-top: ${({ theme }) => theme.rem(16)};
  }
`
