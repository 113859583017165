import Icon from '@/atoms/Icon'
import { StyledNavButton } from './styles'

export const VARIANT = {
  DEFAULT: 'default',
  ROUND: 'round',
}

const ARROW_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
}

const SliderNavigation = ({
  nextRef, prevRef, variant = VARIANT.DEFAULT
}) => {
  const isRound = variant === VARIANT.ROUND

  const icon = (direction) => {
    if (direction === ARROW_DIRECTION.LEFT) {
      return isRound ? 'bigSliderNext' : 'sliderPrev'
    }
    return isRound ? 'bigSliderNext' : 'sliderNext'
  }


  return (
    <>
      <StyledNavButton
        className={isRound ? 'roundPrev' : ''}
        left
        ref={ prevRef }
        isRound={isRound}
      >
        <Icon icon={icon(ARROW_DIRECTION.LEFT)}  />
      </StyledNavButton>
      <StyledNavButton
        className={isRound ? 'roundNext' : ''}
        right
        ref={nextRef}
        isRound={isRound}
      >
        <Icon icon={icon(ARROW_DIRECTION.RIGHT)} />
      </StyledNavButton>
    </>
  )
}

export default SliderNavigation
