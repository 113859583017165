import React from 'react'
import { motion } from 'framer-motion'

import CustomImage from '@/atoms/Image'
import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
import Icon from '@/atoms/Icon'

import {
  StyledPerson,
  StyledPersonImage,
  StyledPersonContent,
  StyledPersonWave,
  StyledPersonContentClose,
  StyledPersonContentContainer,
} from './styles'

const ctaVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    marginTop: 0
  },
  hover: {
    height: 'auto',
    opacity: 1,
    marginTop: 8
  }
}
const Person = ({
  name,
  surname,
  jobTitle,
  description,
  image,
  cta,
  onClick,
  closeModal,
  layout = 'column',
  preview = false,
}) => (
  <StyledPerson layout={layout} preview={preview}>
    <StyledPersonImage>
      {
        image &&
        image.data &&
        <CustomImage {...image}/>
      }
    </StyledPersonImage>

    {
      preview &&
      <StyledPersonContent
        as={motion.button}
        onClick={onClick}
        initial="hidden"
        whileHover="hover"
        variants={{
          hidden: {},
          hover: {}
        }}>
        <StyledPersonWave position="top" />
        <Label typo="paragraph3" bold as="span">{name}{' '}{surname}</Label>
        {
          cta &&
          <Label className='Person_cta' typo="paragraph4" as={motion.span} variants={ctaVariants} layout>{cta}<Icon icon="chevron-right"/> </Label>
        }
      </StyledPersonContent>
    }
    {
      !preview &&
      <>
        <StyledPersonContentClose aria-label='chiudi' onClick={closeModal}/>
        <StyledPersonContent as="div">
          <StyledPersonWave position="left" />
          <StyledPersonContentContainer data-lenis-prevent>
            <Label className='Person_name' typo="subheading1" $title bold as="span">{name}{' '}{surname}</Label>
            {
              jobTitle &&
            <Richtext className='Person_jobtitle' typo="paragraph2">{jobTitle}</Richtext>
            }
            {
              description &&
            <Richtext className='Person_description' typo="paragraph3">{description}</Richtext>
            }
          </StyledPersonContentContainer>
        </StyledPersonContent>
      </>
    }

  </StyledPerson>
)

export default Person
