import styled from 'styled-components'

export const StyledParagraphForm = styled.div`
  display: block;
  margin-top: ${({ theme }) => theme.rem(60)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(120)};
  `)}

`
