import CircleBox from '@/molecules/CircleBox'
import SectionContainer from '@/molecules/SectionContainer'
import { useRef } from 'react'

import { StyledCircleBoxesContainer } from './styles'

const ParagraphWithInfo = ({
  heading, circleBoxes
}) => {
  const areItemsValid = circleBoxes && !!circleBoxes.length
  const containerRef = useRef(null)
  return (
    <SectionContainer
      ref={containerRef}
      heading={heading}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <StyledCircleBoxesContainer>
        {areItemsValid
          ? circleBoxes.map((box, index) => (
            <CircleBox
              key={index}
              className={'simpleCircleBox'}
              {...box}
            />
          ))
          : null}
      </StyledCircleBoxesContainer>
    </SectionContainer>
  )
}

export default ParagraphWithInfo
