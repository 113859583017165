import React, {
  useState, useEffect, useRef
} from 'react'

import {
  open as openAnimation,
  close as closeAnimation
} from 'animations/accordion'
import Base from './Base'
import Faq from './Faq'
import Links from './Links'
import Paragraphs from './Paragraphs'

const normalizedComponents = {
  'base': Base,
  'faq': Faq,
  'links': Links,
  'paragraphs': Paragraphs
}

// TODO: useMemo per evitare rerender quando il padre aggiorna lo stato

const Accordion = ({
  data, disabled, parentHandler, open, passedKey
}) => {
  const [accordion, setAccordion] = useState({
    open,
    isAnimating: false
  })

  const refs = {
    wrapper: useRef(null),
    header: useRef(null),
    content: useRef(null)
  }

  const variant = data.variant || 'base'
  const Component = normalizedComponents[variant]

  useEffect(() => {
    setAccordion(previousState => ({
      ...previousState,
      open
    }))
  }, [open])

  const handleAnimation = (acc) => {
    if (acc.open) {
      openAnimation(refs, () => {
        setAccordion(previousState => ({
          ...previousState,
          isAnimating: false
        }))
      })
    } else {
      closeAnimation(refs, () => {
        setAccordion(previousState => ({
          ...previousState,
          isAnimating: false
        }))
      })
    }
  }

  useEffect(() => {
    handleAnimation(accordion)
  }, [accordion.open])

  const handleClick = ({
    open: isOpen, isAnimating
  }) => {
    if (isAnimating || disabled) return
    setAccordion({
      open: !isOpen,
      isAnimating: true
    })
    if (typeof parentHandler === 'function') parentHandler()
  }

  return (
    <Component {...data} refs={refs} accordion={accordion} handleClick={handleClick} key={passedKey} />
  )
}

export default Accordion
