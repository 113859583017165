import { useMemo } from 'react'
import YouTube from 'react-youtube'
import { StyledVideo } from './styles'

const providerSettings = {
  vimeo: {
    source: 'https://player.vimeo.com/video/',
    allow: 'autoplay; fullscreen; picture-in-picture',
    inlineOpts: {
      loop: true,
      title: false,
      byline: false,
      portrait: false,
      autoplay: true,
      muted: true,
    },
  },
  youtube: {
    source: 'https://www.youtube-nocookie.com/embed/',
    allow:
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;',
    inlineOpts: {
      rel: 0,
      controls: 0,
      loop: 1,
      autoplay: 1,
      disablekb: 1,
      iv_load_policy: 3,
      modestbranding: 1,
      mute: 1,
    },
  },
}

const Video = ({
  media,
  mediaProvider,
  className = null,
  title,
  additionalOptions = {},
  ...props
}) => {
  const options = useMemo(() => {
    const provider = providerSettings[mediaProvider]
    const videoId = media.data.attributes.src
    provider.inlineOpts.playlist = videoId
    const inlineQueryString = new URLSearchParams(
      provider.inlineOpts,
    ).toString()
    const src = `${provider.source}${videoId}?${inlineQueryString}`

    return {
      src,
      allow: provider.allow,
    }
  }, [media, mediaProvider])

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      ...providerSettings.youtube.inlineOpts,
    },
    ...additionalOptions,
  }
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  return (
    <StyledVideo
      className={className}
      {...props}
    >
      {mediaProvider === 'youtube' ? (
        <YouTube
          videoId={media.data.attributes.src}
          opts={opts}
          onReady={_onReady}
        />
      ) : (
        <iframe
          {...options}
          title={title}
          width='640'
          height='360'
          frameBorder='0'
          allowFullScreen
        />
      )}
    </StyledVideo>
  )
}

export default Video
