import React from 'react'

import { StyledLinkList } from './styles'
import SimpleLink from '@/atoms/Link'
import { Label } from '@/atoms/Typography'

const LinkList = ({ items }) => (
  items &&
  !!items.length &&
  <StyledLinkList>
    {items.map(({
      href, title
    }) => (
      <li key={href}>
        <SimpleLink  href={href}>
          <Label>{title}</Label>
        </SimpleLink>
      </li>
    ))}
  </StyledLinkList>
)


export default LinkList

