import React from 'react'

import Link from '@/atoms/Link'
import Button from '@/atoms/Button'

import { StyledCta } from './styles'
import LinkWithIcon from '@/molecules/LinkWithIcon'

const Cta = (props) => {
  const {
    href, icon
  } = props

  let component = Button

  if (href) component = Link
  if (href && icon) component = LinkWithIcon
  return (
    <StyledCta
      {...props}
      as={component}
    />
  )
}

export default Cta
