import React, { useContext } from 'react'

import { GeneralContext } from 'contexts/General'
import { Heading } from '@/atoms/Typography'
import CustomImage from '@/atoms/Image'


import Logo from '@/molecules/Logo'
import Cta from '@/molecules/Cta'
import Units from '@/molecules/Units'

import {
  StyledHomepage,
  StyledHomepageImage,
  StyledHomepageContent
} from './styles'

const Homepage = ({
  title,
  image,
  cta
}) => {
  const { generalOptions } = useContext(GeneralContext)
  const { unitLogos, logoLactalisWhite } = generalOptions

  return (
    <StyledHomepage>
      <StyledHomepageImage>
        <CustomImage {...image}  fill/>
      </StyledHomepageImage>
      <StyledHomepageContent>
        <Logo className="Logo" logoImage={logoLactalisWhite} />
        <Heading
          as="h1"
          typo="h3"
          $title bold
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Cta
          className="no-hover"
          {...cta}
          typo="link2"
          $secondary
        >
          {cta.label}
        </Cta>
        <Units units={unitLogos} className="Homepage_units" />
      </StyledHomepageContent>
    </StyledHomepage>
  )
}

export default Homepage
