import styled from 'styled-components'

import Richtext from '@/atoms/RichText'
import { sliderCommon } from '@/atoms/Slider/styles'

export const StyledParagraphSlider = styled.div`
  ${sliderCommon}
  > div {
    overflow: visible;

    ${({ theme }) =>
    theme.mediaQuery.sm(`
      padding-left: 2.45rem;
    `)}
    ${({ theme }) =>
    theme.mediaQuery.md(`
      padding-left: unset;
    `)}
  }
  .swiper-button-disabled {
    display: none;
  }

  .swiper-container {
    overflow: visible;
  }
`
export const StyledSliderSlide = styled.div`
  width: 100%;
  &:first-child {
    padding-left: 1.2rem;
  }

  ${({ theme }) =>
    theme.mediaQuery.sm(`
    &:first-child {
      padding-left: unset;
    }
    `)}
`
export const StyledSlideMediaContainer = styled.div`
  position: relative;
  min-width: 750px;
  max-width: 850px;
  width: calc(100vw * 0.3779);
  aspect-ratio: 4/3;
  border-radius: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.xs(`
    min-width: 290px;
    max-width: 350px;
    `)}

  ${({ theme }) =>
    theme.mediaQuery.md(`
    min-width: 450px;
    `)}
  ${({ theme }) =>
    theme.mediaQuery.lg(`
    min-width: 750px;
    `)}

  iframe {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledParagraphSliderDescription = styled(Richtext).attrs({ typo: 'paragraph3', })`
  padding-block: ${({ theme }) => theme.rem(16)};
  width: 100%;
  margin: 0 auto;
`
