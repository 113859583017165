import React, { useState } from 'react'
import { StyledAccordionList } from './styles.js'
import Accordion from './Accordion'

const AccordionList = ({
  list, multiple = false
}) => {
  const [expanded, setExpanded] = useState([])

  const handleExpanded = (index, expandedIdx) => {
    if (expandedIdx.indexOf(index) !== -1) {
      const indexOfExpanded = expandedIdx.indexOf(index)
      const newExpanded = expandedIdx
      newExpanded.splice(indexOfExpanded, 1)
      setExpanded(newExpanded)
    } else {
      const newExpanded = multiple ? [...expandedIdx, index] : [index]
      setExpanded(newExpanded)
    }
  }

  return list ? (
    <StyledAccordionList>
      {list.map((item, index) => {
        return (
          <>
            <Accordion
              data={item}
              passedKey={index}
              open={expanded.indexOf(index) !== -1}
              parentHandler={() => handleExpanded(index, expanded)}
            />
          </>
        )
      })}
    </StyledAccordionList>
  ) : null
}

export default AccordionList
