import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledInputFileLabel = styled.span`
  text-indent: ${({ theme }) => theme.rem(4)};
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: ${({ theme }) =>theme.rem(48)};
  border-bottom: 2px solid;
  pointer-events: none;
`
export const StyledField = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.rem(32)};

  &:last-child { margin-bottom: 0; }

  &[type="checkbox"],
  &[type="radio"] {
    width: auto;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  ${styledMap('type', {
    default: `
      label {
        pointer-events: none;
        width: 100%;
      }
    `,
    hidden: 'margin-bottom: 0;',
    radio: `
      min-width: 150px;

      label {
        pointer-events: auto;
        width: auto;
      }
    `,
    checkbox: `
      label {
        pointer-events: auto;
        width: auto;
      }
    `,
  })}
`

export const StyledFieldContainer = styled.div`
  position: relative;
  width: 100%;

  input[type="search"] {
    &:focus-visible {
      outline: none;
      border: 1px solid $secondary !important;

      border-radius: 30px;
    }
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    height: ${({ theme }) =>theme.rem(48)};
    text-indent: ${({ theme }) =>theme.rem(15)};
    border: none;
    border-radius: 3px;
    background-color: ${({ theme }) =>theme.colors.grayscale.gray2};
    color: inherit;
    ${({ theme }) => theme.typo('inputValue1')};
    font-family: ${({ theme }) => theme.fonts.text};
  }

  input:not([type="checkbox"]):not([type="radio"]),
  textarea {
    width: 100%;
    padding: 0;

    &::placeholder {
      all: unset;
      color: ${({ theme }) =>theme.colors.grayscale.gray3};
      ${({ theme }) => theme.typo('inputPlaceholder1')};
    }

    &:focus,
    &:not(:placeholder-shown) { border: 1px solid ${({ theme }) => theme.colors.primary.default()}; }
  }

  textarea {
    width: 100%;
    display: block;
    padding: ${({ theme }) => theme.rem(16)} 0;
    min-height: ${({ theme }) => theme.rem(180)};
  }


  input[type="checkbox"],
  input[type="radio"] {
    ${({ theme }) => theme.size(32)};
    padding: 0;
    margin: 0;
    opacity: 0;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    display: block;
    padding-left: ${({ theme }) => theme.rem(16)};
    margin: 0;
    position: relative;
    top: unset;
    transform: none;
    cursor: pointer;

    &:before {
      content: '';
      ${({ theme }) => theme.size(32)};
      transition: background-color ease-in-out .3s, border-color ease-in-out .3s, border-width ease-in-out .2s;
      border: 2px solid ${({ theme }) => theme.colors.primary.default()};
      position: absolute;
      right: 100%;
      top: -4px;
    }

    &:hover:before {
      border-color: ${({ theme }) => theme.colors.primary.default()};
    }
  }

  input[type="radio"] + label {
    &:before { border-radius: 50%; }
  }

  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {
    &:before {
      border-color: inherit;
      background-color: inherit;
    }
  }

  input[type="checkbox"]:checked + label {
    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      content: '✓';
      color: ${({ theme }) => theme.colors.grayscale.white()};
      background-color: ${({ theme }) => theme.colors.primary.default()};
    }
  }

  input[type="file"] {
    opacity: 0;
    &:not(:placeholder-shown) + label {
      all: unset;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) scale(1) !important;
      color: ${({ theme }) => theme.colors.grayscale.gray2};
      display: inline-block;
    }

    &::-webkit-file-upload-button { display: 'none' }
  }

  select {
    padding: 0 ${({ theme }) => theme.rem(32)} 0 0;
    /* padding: 0; */
    margin: 0;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8 6.78148L1.53432 0L0 1.60926L8 10L16 1.60926L14.4657 0L8 6.78148Z" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 20px;
    background-color: ${({ theme }) => theme.colors.primary.default()};
    color: ${({ theme }) => theme.colors.grayscale.white()};

    option {
      color: ${({ theme }) => theme.colors.primary.default()};
      padding: 0;

    }
  }

  ${styledMap('type', {
    checkbox: `
      display: flex;
      align-items: flex-start;
    `,
    radio: `
      display: flex;
      align-items: center;
    `,
    default: `
      display: flex;
      align-items: flex-start;

      flex-direction: column-reverse;
    `
  })}

  ${styledMap('error', {
    default: '',
    true: `
      input:not([type="checkbox"]):not([type="radio"]),
      textarea {
        border: 1px solid red;

        &:focus,
        &:not(:placeholder-shown) {
          border-color: red;
        }
      }

      select { border: 1px solid red; }

      input[type="checkbox"] + label,
      input[type="radio"] + label {
        color: red;
        &:before { border-color: red; }
      }

      input[type="file"] + span {
        border-color: red;
        color: red;
      }
    `
  })}
`

export const StyledFieldLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.rem(9)};
  color:${({ theme }) => theme.colors.secondary.default};
  font-family: ${({ theme }) => theme.fonts.title};
  ${({ theme }) => theme.typo('label1')}
`

export const StyledFieldError = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.rem(16)};
  line-height: ${({ theme }) => theme.rem(21)};

  margin-top: ${({ theme }) => theme.rem(8)};
`

export const StyledGroup = styled.div`
  display: block;
  margin-bottom: ${({ theme }) => theme.rem(24)};
  padding-bottom: ${({ theme }) => theme.rem(16)};
  overflow: auto;

  &::-webkit-scrollbar { height: 1px; }

  &::-webkit-scrollbar-track { background: ${({ theme }) => theme.colors.grayscale.white()}; };
  &::-webkit-scrollbar-thumb { background-color: ${({ theme }) => theme.colors.secondary.default};};

  strong {
    display: block;
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`

export const StyledGroupContainer = styled.div`
  display: inline-flex;
  // width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${({ theme }) => theme.rem(16)};
  row-gap: ${({ theme }) => theme.rem(16)};

  [data-type="radio"],
  [data-type="checkbox"] {
    position: relative;
    label {
      height: 50px;
      background-color: ${({ theme }) => theme.colors.grayscale.white()};
      border: 1px solid ${({ theme }) => theme.colors.grayscale.black()};
      color: ${({ theme }) => theme.colors.grayscale.black()};

      padding-top: ${({ theme }) => theme.rem(16)} !important;
      padding-bottom: ${({ theme }) => theme.rem(16)};
      padding-left: ${({ theme }) => theme.rem(32)}!important;
      padding-right: ${({ theme }) => theme.rem(32)};
      text-align: center;
      border-radius: 100%;
      transition: background-color ease-in-out .4s, border-color ease-in-out .4s;
    }

    input {
      position: absolute;
      size: 100%;
      left: 0;
      top: 0;

      &:checked+label {
        /* background-color: $secondary; */
        /* border-color: $secondary; */
      }
    }

  }

  [data-type="radio"] {
    min-width: 0;
    label { &:before { display: none } }
  }

  [data-type="checkbox"] {
    label {
      display: flex !important;
      align-items: center;
      justify-content: center;
      column-gap: ${({ theme }) => theme.rem(12)};

      &:before {
        border-radius: 2px;
        size: 18px !important;
        right: 0 !important;
        position: relative !important
      }
    }

    input {
      &:checked + label {
        &:before {
          /* background-color: $white; */
          /* color: $black */
        }
      }
    }
  }
`
