import React from 'react'
import { useField  } from 'formik'

import CustomField from './index'

const Select = ({
  options = [],
  label,
  dependsOn,
  ...props
}) => {
  const [field, meta] = useField(props)
  return (
    <CustomField
      label={label}
      field={field}
      meta={meta}
      dependsOn={dependsOn}
    >
      <select {...field} {...props} >
        {
          options.map(({
            value,
            label: optionLabel
          }, idx) => (
            <option key={idx} value={value} label={optionLabel}>{optionLabel}</option>
          ))
        }
      </select>
    </CustomField>
  )
}

export default Select
