import styled from 'styled-components'

export const StyledVideo = styled.div`
  height: 100%;
  width: 100%;
  > div{
    height: 100%;
    width: 100%;
  }

  ${({ hero }) => !!hero && `
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    &::after{
      content: "";
      background-color: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);

      @media (min-aspect-ratio: 16/9) {
        height: 56.25vw;
      }

      @media (max-aspect-ratio: 16/9) {
        width: 177.78vh;
      }
    }
  `}
`

