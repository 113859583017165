import React, { useRef } from 'react'
import {
  useScroll,
  motion,
  useTransform,
} from 'framer-motion'

import { StyledDrop } from './styles'

const Drop = ({
  hideBase = false,
  animated = true
}) => {
  const { scrollY } = useScroll()
  const ref = useRef(null)
  const height = useTransform(scrollY, [0, 800], [170, 200])
  const y = useTransform(scrollY, [0, 800], [-3, 800])
  const style = {}

  if (animated) Object.assign(style, { y })
  return (
    <StyledDrop
      animated={animated}
      ref={ref}
      as={motion.div}
      transition={{
        duration: 0,
        ease: 'linear',
        type: 'spring',
        mass: 0.1
      }}
      className='Drop' style={style}>
      {
        !hideBase &&
        <svg id="Drop_top" width="44" height="20" viewBox="0 0 44 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H44C32.9543 0 24 8.95431 24 20H20C20 8.95431 11.0457 0 0 0Z" fill="currentColor"/>
        </svg>
      }

      <motion.span id="Drop_body"  style={{ height: animated ? height : 170 }}/>
      <svg id="Drop_bottom" viewBox="0 0 39.146 59.841">
        <path d="M957.132-884.591c-3,5-15.029,24.179-15.029,33.686s4.962,22.405,19.179,22.405,20-12.076,19.967-22.405-12.117-28.686-15.117-33.686S960.132-889.591,957.132-884.591Z" transform="translate(-942.103 888.341)" fill="currentColor"></path>
      </svg>
    </StyledDrop>
  )
}

export default Drop
