import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import StorylineOverview from '@/molecules/Storyline'


const Storyline = ({
  heading,
  tabs,
}) => (
  <SectionContainer heading={heading}>
    <StorylineOverview {...tabs}/>
  </SectionContainer>
)

export default Storyline
