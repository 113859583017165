import React from 'react'

import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'

import { StyledPreviewCityButton } from './styles'

const City = ({
  onClick,
  data
}) => (
  <StyledPreviewCityButton
    onClick={() => onClick(data)}
  >
    <Icon className="CityButton_icon" icon="establishment" useIconColor/>
    {data.city}
    <Label
      as="span"
      className="CityButton_cta"
      typo="paragraph4"
      bold
    >
        Scopri <Icon icon="chevron-right" />
    </Label>
  </StyledPreviewCityButton>
)

export default City
