import React from 'react'

import { Label } from '@/atoms/Typography'
import CircledLink from '@/molecules/CircledLink'
import CustomImage from '@/atoms/Image'

import {
  StyledBalanceList,
  StyledBalance,
} from './styles'

const Balance = ({ items }) => (
  items &&
  !!items.length &&
  <StyledBalanceList>
    {
      items.map(({
        title, subtitle, attachment, logo
      }) => (
        <StyledBalance key={title}>
          <Label as="h4" typo="h4" center $title bold uppercase>{title}</Label>
          <Label center>{subtitle}</Label>
          {
            logo &&
            logo.data &&
            <CustomImage {...logo}/>
          }
          {
            attachment &&
            <CircledLink
              className="Balance_download"
              link={{ href: attachment.file.data.attributes.url }}
              label={attachment.label}
              icon='arrow-down'
            />
          }

        </StyledBalance>
      ))
    }
  </StyledBalanceList>

)

export default Balance
