import React from "react"
import { useState } from "react"
import SectionContainer from '@/molecules/SectionContainer'
import RichText from '@/atoms/RichText'
import Purpose from "@/molecules/Purpose"
import { Styled } from './styles'
import { useScreenDetector } from "hooks/useScreenDetector"

const Purposes = ({
  title,
  molecule,
}) => {
  const [activeSection, setActiveSection] = useState(0);  
  const handleSectionChange = (index) => {
    setActiveSection(index)
  }
  const {isTablet} = useScreenDetector();
  return (
    <>
      {isTablet ? 
        <>
        <SectionContainer>
          <Styled.MainContent>
            <Styled.ButtonMain>
              {
                molecule &&
                !!molecule.length &&
                molecule.map((moleculeButton) =><Styled.SpanCircle key={moleculeButton.id} {...moleculeButton} $bgColor={moleculeButton.color}></Styled.SpanCircle>)
              }
              <Styled.TitleCircle>
                <Styled.TitleText>
                  {title}
                </Styled.TitleText>
              </Styled.TitleCircle>
            </Styled.ButtonMain>
            <Styled.DescriptionMain>
              {
                molecule &&
                !!molecule.length &&
                molecule.map((moleculeButton, index) => (
                  <Purpose onClick={() => handleSectionChange(index)} key={moleculeButton.id} {...moleculeButton}>
                    <Styled.Summary>{moleculeButton.title}</Styled.Summary>
                    <RichText>{moleculeButton.description}</RichText>
                  </Purpose>
                ))
              }
            </Styled.DescriptionMain>
          </Styled.MainContent>
        </SectionContainer>
        </>
      : 
        <SectionContainer>
          <Styled.MainContent>
            <Styled.ButtonMain>
              {
                molecule &&
                !!molecule.length &&
                molecule.map((moleculeButton, index) => (<Purpose onClick={() => handleSectionChange(index)} key={moleculeButton.id} {...moleculeButton}>{moleculeButton.title}</Purpose>))
              }
              <Styled.TitleCircle>
                <Styled.TitleText>
                  {title}
                </Styled.TitleText>
              </Styled.TitleCircle>
            </Styled.ButtonMain>
              <Styled.DescriptionMain>
                {
                  molecule &&
                  !!molecule.length &&
                  molecule.map((moleculeTitle, index) => ( 
                    activeSection === index &&
                    <> 
                    <Styled.DescriptionTitle $titleColor={moleculeTitle.color} key={moleculeTitle.id}>{moleculeTitle.title}</Styled.DescriptionTitle>
                    <RichText key={moleculeTitle.id}>{moleculeTitle.description}</RichText>
                    </>
                  ))
                }
              </Styled.DescriptionMain>
          </Styled.MainContent>
        </SectionContainer>
      }
    </>
  )
}

export default Purposes