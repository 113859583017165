import styled from 'styled-components'
import Richtext from '@/atoms/RichText'
import styledMap from 'styled-map'

export const StyledInfoGraphic = styled.div`
  position: relative;
  display: block;
  margin: ${({ theme }) => theme.rem(64)} auto;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    margin: ${theme.rem(64)} auto ${theme.rem(120)};
  `)}
`

export const StyledInfoGraphicSubject = styled.div`
  ${({ theme }) => theme.size(['100%', 'auto'])};
  max-width: 500px;
  margin: 0 auto ${({ theme }) => theme.rem(55)};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaQuery.md(`
    aspect-ratio: 1;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  `)}
`

export const StyledInfoGraphicSubjectContainer = styled.div`

  position: relative;

  ${({ theme }) => theme.size(['100%', 'auto'])};
  ${({ theme }) => theme.mediaQuery.md(`
    aspect-ratio: 1;
    transform: scale(.8);
  `)}
`

export const StyledInfoGraphicImages = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  ${({
    theme, isMaskedImage
  }) => isMaskedImage ?  theme.size('100%') : theme.size('120%')}


  ${({
    imagePosition,
    theme
  }) => imagePosition !== 'center' && `
    top: 75%;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: -40px;
    column-gap: ${theme.rem(16)};

    .InfographicImage {
      max-width: 100px;
      width: 100%;
    }

    img {
        width: 80%;
        z-index: 2;
      }

    ${theme.mediaQuery.md(`
      top: 50%;
      position: absolute;
      flex-direction: column;
      column-gap: 0;
      margin-top: 0;

      .InfographicImage {
        max-width: 300px;

        &:nth-child(odd){
          max-width: 170px;
        }

        &:nth-child(even){
          margin-top: -20%;
          margin-bottom: -20%;
        }
      }
    `)}
  `}

  ${({ theme }) => styledMap('imagePosition', {
    left: `
      ${theme.mediaQuery.md(`
        left: 10%;
        transform: translate(-95%,-50%);

        .InfographicImage {
          &:nth-child(odd){
            margin-right: -20%;
            align-self: flex-end;
          }
        }
      `)}
    `,
    right: `
      ${theme.mediaQuery.md(`
        left: 0;
        right: 10%;

        transform: translate(85%,-50%);

        .InfographicImage {
          &:nth-child(odd){
            margin-left: -20%;
            align-self: flex-start;
          }
        }
      `)}
    `,
    center: `
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    `
  })};

  img{
    object-fit: contain;
    ${({ isMaskedImage }) => isMaskedImage && 'object-fit: cover;'}
  }
`

export const StyledInfoGraphicImage = styled.div``

export const StyledInfoGraphicInfoboxes = styled.div`
  display: table;
  margin: 0 auto;
  /* max-width: 750px; */
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    aspect-ratio: 1;
    width: clamp(500px, 65vw,750px);
  `)}
`
export const StyledInfoGraphicInfoboxesContainer = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 0;

  `)}
  width: 100%;
  .CircleFrame{
    margin-bottom: ${({ theme }) => theme.rem(15)};
  }

  .Circle_title{
    font-size: 18px;
    line-height: 18px;
  }
  .Circle_text{
    font-size: 14px;
    line-height: 14px;
  }

`


export const StyledInfoGraphicTextWrapper = styled.div`
  z-index: 1;

  ${({ masked }) => masked && `
    z-index: 3;
  `}

  ${({ theme }) => theme.mediaQuery.md(`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `)}
`

export const StyledInfoGraphicText = styled(Richtext)`
  text-align: left;
  z-index: 1;
  margin-bottom: ${({ theme }) => theme.rem(45)};

  z-index: 1;

  ${({ theme }) => theme.mediaQuery.md(`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: ${theme.rem(24)} ${theme.rem(32)};
    color: ${theme.colors.primary.default()};
    margin-bottom: 0;
    aspect-ratio: 1;
  `)}

  ${({
    floating, theme
  }) => !floating && `
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: ${theme.rem(24)} ${theme.rem(32)};
    transform: translate(-50%, -50%);
    text-align: center;
  `}

  ${({
    theme,
    position,
    floating
  }) => floating && theme.mediaQuery.md(`
    border-radius: 50%;
    top: -8%;
    text-align: left;
    ${position === 'left' ? 'left: unset; right: 75%;' : 'right: unset; left: 75%;' }
    transform: none;
    background-color: ${theme.colors.secondary.default};
    color: ${theme.colors.grayscale.white()};
    ${theme.size('20vw')};
    text-align:center;
    max-width: 330px;
    max-height: 330px;
  `)}

  p{
    font-family: ${({ theme })=> theme.fonts.title};
    font-weight: bold;
    font-size: clamp(24px,1.6vw, 32px);
    line-height: 120%;
  }
`

export const StyledInfoGraphicSubjectContent = styled.div`
position: relative;
`
