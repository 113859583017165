import React, { useState } from 'react'
import Image from 'next/image'

import colors from 'theme/colors'

import Frame from '@/atoms/Frame'
import Icon from '@/atoms/Icon'

import EstablishmentModal from '@/molecules/Modals/EstablishmentModal'

import RegionContent from './RegionContent'

import {
  StyledRegionList,
  StyledRegionListDecoration,
  StyledRegionListItems,
  StyledRegionListItemTrigger,
} from './styles'

const RegionList = ({ items }) => {
  const [region, setRegion] = useState(null)


  const modalClose = () => {
    setRegion(null)
  }

  const onRegionClick = (regionKey) => {
    setRegion(regionKey)
  }


  return (
    <StyledRegionList>
      <StyledRegionListDecoration>
        <Frame backgroundColor={colors.background.lightBlue} />
        <Image
          alt="Stabilimenti in Italia"
          src="/images/italy.svg"
          width={479}
          height={543}
        />
      </StyledRegionListDecoration>

      {
        items &&
        !!items.length &&
        <>
          <StyledRegionListItems>
            {
              items.map(({
                coordinates,
                region: regionName,
                items: cities
              }) => (
                <StyledRegionListItemTrigger
                  key={regionName}
                  data-region={regionName}
                  onClick={() =>onRegionClick(regionName)}
                  style={{
                    top: `${coordinates.y}%`,
                    left: `${coordinates.x}%`,
                  }}>
                  <Icon class="Establishment_icon" icon="establishment" useIconColor />
                  <span>{cities.length}</span>
                  <Icon icon="chevron-right"/>
                </StyledRegionListItemTrigger>
              ))
            }

          </StyledRegionListItems>
          <EstablishmentModal
            isOpen={!!region}
            modalHandler={modalClose}
          >
            <RegionContent
              region={region}
              items={items}
              modalClose={modalClose}
            />

          </EstablishmentModal>
        </>
      }


    </StyledRegionList>
  )
}

export default RegionList
