import CustomImage from '@/atoms/Image'
import Video from '@/atoms/Video'
import SectionContainer from '@/molecules/SectionContainer'
import SliderNavigation, { VARIANT } from '@/molecules/Slider/Navigation'
import useIsMobile from 'hooks/useIsMobile'
import React from 'react'
import {
  A11y, Navigation
} from 'swiper'
import {
  Swiper, SwiperSlide
} from 'swiper/react'
import {
  StyledParagraphSlider,
  StyledParagraphSliderDescription,
  StyledSlideMediaContainer,
  StyledSliderSlide,
} from './styles'

const ParagraphSlider = ({
  heading, items
}) => {
  const navPrevButton = React.useRef(null)
  const navNextButton = React.useRef(null)
  const areItemsValid = items && !!items.length
  const isMobile = useIsMobile()

  return (
    <>
      <SectionContainer
        heading={heading}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: !isMobile ? '3.125rem' : 'unset',
        }}
      >
        {areItemsValid ? (
          <StyledParagraphSlider>
            <Swiper
              spaceBetween={32}
              modules={[Navigation, A11y]}
              // draggable={true}
              freeMode={true}
              navigation={{
                prevEl: navPrevButton.current,
                nextEl: navNextButton.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navPrevButton.current
                swiper.params.navigation.nextEl = navNextButton.current
                swiper.navigation.update()
              }}
              setWrapperSize={true}
              breakpoints={{
                0: {
                  slidesPerView: '1.20',
                  spaceBetween: 42,
                },
                400: {
                  slidesPerView: 'auto',
                  spaceBetween: 32,
                },
              }}
            >
              {items.map(({
                description, image, video
              }, index) => (
                <SwiperSlide key={index}>
                  <StyledSliderSlide key={index}>
                    {image ? (
                      <StyledSlideMediaContainer>
                        <CustomImage
                          {...image}
                          fill
                          alt={image.data.attributes.alt}
                        />
                      </StyledSlideMediaContainer>
                    ) : null}
                    {video ? (
                      <StyledSlideMediaContainer>
                        <Video
                          {...video.video}
                          additionalOptions={{
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      </StyledSlideMediaContainer>
                    ) : null}
                    <StyledParagraphSliderDescription typo={'paragraph3'}>
                      {description}
                    </StyledParagraphSliderDescription>
                  </StyledSliderSlide>
                </SwiperSlide>
              ))}
            </Swiper>
            <SliderNavigation
              variant={VARIANT.ROUND}
              prevRef={navPrevButton}
              nextRef={navNextButton}
            />
          </StyledParagraphSlider>
        ) : null}
      </SectionContainer>
    </>
  )
}

export default ParagraphSlider
