import React, { useMemo } from 'react'

import colors from 'theme/colors'

import { Label } from '@/atoms/Typography'
import VideoInModal from '@/molecules/VideoInModal'
import WithHeading from '@/molecules/WithHeading'
import RichText from '@/atoms/RichText'
import ImageMask from '@/molecules/ImageMask'
import CircledLink from '@/molecules/CircledLink'
import LinkWithIcon from '@/molecules/LinkWithIcon'

import {
  StyledParagraph,
  StyledParagraphContent,
  StyledParagraphMedia,
  StyledParagraphMediaContainer,
  StyledParagraphContentLinks,
} from './styles'

import VideoModel from 'models/Video'

const Paragraph = ({
  title,
  titleTag,
  description,
  images,
  video: videoList = null,
  cta,
  file,
  className = null,
}) => {
  const ctaDefault = {
    content: [], aside: []
  }
  const videoItems = useMemo(() => {
    if (!videoList) return null
    return videoList.map((v) => VideoModel(v, { heading: { title } }))
  }, [videoList])

  const ctas = useMemo(() => {
    if (!cta || cta.length <= 0) return ctaDefault

    return cta.reduce((acc, item) => {
      if (!!item.inline) {
        acc.content.push(item)
      } else {
        acc.aside.push(item)
      }
      return acc
    }, ctaDefault)
  }, [cta])


  return (
    <StyledParagraph className={className}>
      <StyledParagraphContent className='Paragraph_content'>
        {
          title &&
          <WithHeading
            className="Paragraph_heading"
            uppercase
            heading={{
              title: title,
              titleTag: titleTag,
            }}
          />
        }

        <RichText as="main" >{description}</RichText>

        {
          ctas.content &&
          !!ctas.content.length &&
          <StyledParagraphContentLinks>
            {
              ctas.content.map(link => (
                <LinkWithIcon key={link.externalUrl}
                  href={link.externalUrl}
                  left
                  icon={link.icon}
                >
                  <Label as="span" bold $title typo="link1">{link.label}</Label>
                </LinkWithIcon>
              ))
            }
          </StyledParagraphContentLinks>
        }
      </StyledParagraphContent>

      {
        (
          (videoItems && !!videoItems.length) ||
          (images && images.data) ||
          (ctas.aside && !!ctas.aside.length) ||
          file
        ) &&
        <StyledParagraphMedia>
          {
            videoItems &&
          !!videoItems.length &&
          <StyledParagraphMediaContainer>
            {
              videoItems.map(videoItem => <VideoInModal key={videoItem.video.title} className="ParagraphVideo" {...videoItem} />)
            }
          </StyledParagraphMediaContainer>
          }
          {
            images &&
          images.data &&
          <StyledParagraphMediaContainer>
            {
              images.data.map(image => (
                <ImageMask
                  key={`ParagraphImage-${image.id}`}
                  className="ParagraphImage"
                  border
                  outerBorder
                  color={colors.grayscale.gray3}
                  image={{ data: image }}
                />
              ))
            }
          </StyledParagraphMediaContainer>
          }
          {
            ctas.aside &&
          !!ctas.aside.length &&
          <StyledParagraphMediaContainer
            style={{ flexWrap: 'nowrap' }}>
            {
              ctas.aside.map(({
                label,
                externalUrl
              }) => (
                <CircledLink
                  key={label}
                  label={label}
                  link={{
                    href: externalUrl,
                    target: '_blank'
                  }}
                  icon="arrow-oblique"
                  $secondary
                />
              ))
            }
          </StyledParagraphMediaContainer>
          }
          {
            file &&
          <StyledParagraphMediaContainer
            style={{ flexWrap: 'nowrap' }}>
            <CircledLink
              primary
              link={{
                href: file.file.data?.attributes?.url,
                target: '_black'
              }}
              label={file.label}
              icon={'arrow-down'}
            />
          </StyledParagraphMediaContainer>
          }
        </StyledParagraphMedia>
      }

    </StyledParagraph>
  )
}

export default Paragraph
