import React from 'react'

import WithHeading from '@/molecules/WithHeading'
import ParagraphMolecule from '@/molecules/Paragraph'

import {
  BaseAccordion,
  BaseAccordionHeader,
  ParagraphsHeaderContent,
  ParagraphsContent,
} from './styles'

const ParagraphsAccordion = ({
  refs,
  accordion,
  tabIndex,
  disabled,
  handleClick,
  passedKey,
  heading,
  paragraphs
}) => (
  <BaseAccordion
    tabIndex={tabIndex}
    ref={refs.wrapper}
    disabled={disabled}
    key={passedKey}
    aria-expanded={accordion.open}
  >
    <BaseAccordionHeader
      ref={refs.header}
      open={accordion.open}
      onClick={() => handleClick(accordion)}
    >
      <ParagraphsHeaderContent
        onClick={() => handleClick(accordion)}
      >
        {
          heading &&
          <WithHeading heading={heading}/>
        }
      </ParagraphsHeaderContent>
    </BaseAccordionHeader>

    <ParagraphsContent
      ref={refs.content}
      open={accordion.open}
      role={'region'}
    >
      {
        paragraphs &&
        !!paragraphs.length &&
        paragraphs.map(paragraph => <ParagraphMolecule className="AccordionParagraphs" key={paragraph.title} {...paragraph} />)
      }
    </ParagraphsContent>
  </BaseAccordion>
)

export default ParagraphsAccordion
