import styled from 'styled-components'


export const StyledDeepenings  = styled.div`
  display: grid;
  align-items: start;
  gap: ${({ theme }) => theme.rem(32)};
  margin-top: ${({ theme }) => theme.rem(32)};
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: repeat(3, 1fr);
  `)}
`

export const StyledDeepening  = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  gap: ${({ theme }) => theme.rem(24)};
  color: ${({ theme }) => theme.colors.primary.default()};

  .Deepening_description{
    color: ${({ theme }) => theme.colors.secondary.default};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    [data-richtext-wrapper] {
      width: 70%;
    }
  `)}
`
