import styled from 'styled-components'

import { sliderCommon } from '@/atoms/Slider/styles'
import { Label } from '@/atoms/Typography'

export const StyledHeadquartersList = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.marginX(0)}
    width: 100%;
  `)}
`

export const StyledHeadquartersListContainer = styled.div`
  ${sliderCommon}
`

export const StyledHeadquartersItem = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  .Headquarters_image{
    /* margin: 0 auto; */
    max-width: 260px;
    > div{
      aspect-ratio: unset;
    }
  }

  ${Label}{
    margin: ${({ theme }) => theme.rem(32)} 0 ${({ theme }) => theme.rem(8)};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingX(16)};
    min-width: 240px;
    min-width: 0px;
  `)}
`
