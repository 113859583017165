import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Balance from '@/molecules/Balance'

const Budget = ({ balance }) => (
  <SectionContainer>
    <Balance items={balance} />
  </SectionContainer>
)

export default Budget
