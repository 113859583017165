import React from 'react'

import CustomImage from '@/atoms/Image'

import {
  StyledCollaborationWrapper,
  StyledCollaboration
} from './styles'

const Collaboration = ({ images }) => (
  images &&
  !!images.data.length &&
  <StyledCollaborationWrapper>
    {
      images.data.map((image, idx) => (
        <StyledCollaboration key={image.id + idx}>
          <CustomImage data={image} />
        </StyledCollaboration>
      ))
    }

  </StyledCollaborationWrapper>
)

export default Collaboration
