import styled from 'styled-components'

export const StyledHomepage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

export const StyledHomepageImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  img{
    object-fit: cover;
  }

  &::before{
    content: "";
    background-color: ${({ theme }) => theme.colors.background.home};
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
`

export const StyledHomepageContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayscale.white() };
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(50)};
  padding: ${({ theme }) => theme.rem(24)};

  .Homepage_units > main{
    flex-direction: row;
  }
  .no-hover{
    column-gap: ${({ theme }) => theme.rem(8)};
    color: ${({ theme }) => theme.colors.primary.default() };
    ${({ theme }) => theme.paddingY(16)};
    ${({ theme }) => theme.paddingX(32)};

    img{
      height: 34px;
      width: auto;
    }
  }
`


