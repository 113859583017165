import styled from 'styled-components'

export const StyledOvalInfos = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

    ${({ theme })=> theme.mediaQuery.md(`
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    `)}
`

export const StyledOvalInfo = styled.div`
  padding: ${({ theme }) => theme.rem(16)};
  place-self: center;
  max-width: 300px;

  .OvalInfo_description{
    margin-top: ${({ theme }) => theme.rem(16)};
  }
`
