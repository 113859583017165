import { motion } from 'framer-motion'
import useIsMobile from 'hooks/useIsMobile'
import {
  useEffect,
  useState,
} from 'react'
import colors from 'theme/colors'

import {
  Heading,
  Paragraph
} from '@/atoms/Typography'

import Frame from '@/atoms/Frame'
import Icon from '@/atoms/Icon'

import { StyledCircleBox } from './styles'

const frameVariants = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: { duration: 0.6, }
  }
}

const textVariants = {
  hidden: {
    opacity: 0,
    top: '150%'
  },
  visible: {
    opacity: 1,
    top: '100%',
  }
}

const CircleBox = ({
  index,
  image,
  text,
  title,
  distanceParams = { radius: 0 },
  className,
  details,
}) => {
  const [distance, setDistance] = useState(null)
  const isMobile = useIsMobile()

  // TODO: useMemo?
  useEffect(() => {
    const calculateDistance = () => {
      if (!distanceParams || isMobile) return setDistance(null)

      const {
        radius,
        step,
        offset,
        dir
      } = distanceParams

      const x =  Math.round(radius * (dir * Math.sin((step * index) + offset)))
      const y = Math.round(radius * (Math.cos((step * index) + offset)))

      return setDistance({
        x: x,
        y: y,
      })
    }

    calculateDistance()

    return calculateDistance
  }, [distanceParams.radius])

  const imageExists =  image && image.data

  const style = distance && !isMobile
    ? { transform: `translate(calc(-50% + ${distance?.x}px),calc(-50% - ${distance?.y}px))` }
    : { transform: 'none' }
  return (
    <StyledCircleBox
      className={className}
      style={style}
    >
      {
        (title || imageExists) &&
          <Frame
            as={motion.div}
            variants={frameVariants}
            className='CircleFrame'
            border
            color={colors.secondary.default}
          >
            {
              imageExists &&
              <Icon className="CircleImage" icon={image}/>
            }
            {
              !imageExists &&
              <Heading as="strong" typo="subheading1" $title bold uppercase>{title}</Heading>
            }
          </Frame>
      }
      {
        imageExists &&
          title &&
          <Heading typo="subheading1" className='Circle_title' $title bold uppercase>{title}</Heading>
      }

      <motion.div
        className='Circle_text'
        variants={textVariants}
        transition={{
          opacity: { duration: 0.6, },
          top: { duration: 0.4 }
        }}
      >

        {
          text &&
            <Paragraph as="div" typo="paragraph3" $title bold uppercase dangerouslySetInnerHTML={{ __html: text }} />
        }
        {
          details &&
            <Paragraph as="div" typo="paragraph3" $title bold dangerouslySetInnerHTML={{ __html: details }} />
        }
      </motion.div>
    </StyledCircleBox>
  )
}

export default CircleBox
