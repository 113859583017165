import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'

import {
  StyledField,
  StyledFieldContainer,
  StyledFieldLabel
} from './styles'

import FieldError from './Error'

const Field = ({
  id = null,
  type,
  label,
  field,
  meta,
  children,
  dependsOn,
}) => {
  const { values } = useFormikContext()
  const foundMatch = useMemo(() => {
    if (!dependsOn) return null

    const keys = Object.keys(dependsOn)
    const found = keys.filter(k => values[k] === dependsOn[k])

    return !!found.length
  }, [dependsOn, values])

  return (
    (!dependsOn || foundMatch) &&
    <StyledField
      type={type}
      data-type={type}
      disabled={field.disabled}
    >
      <StyledFieldContainer
        type={type}
        error={meta.touched && !!meta.error}
        withValue={!!field.value.length}>

        {children}

        {
          label &&
          <StyledFieldLabel htmlFor={id || field.name} dangerouslySetInnerHTML={{ __html: label }} />
        }
      </StyledFieldContainer>

      <FieldError {...meta} />
    </StyledField>
  )
}

export default Field
