import React from 'react'

import Richtext from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'

import OvalCard from '@/molecules/OvalCard'

import {
  StyledDeepenings,
  StyledDeepening
} from './styles'

const Deepenings = ({ items }) => {
  return (
    items &&
    !!items.length &&
    <StyledDeepenings>
      {
        items.map(({
          id,
          title,
          description
        }) => (
          <StyledDeepening key={title + id}>
            <OvalCard $secondary>
              <Label bold $title as="span" typo="h4" dangerouslySetInnerHTML={{ __html: title }}/>
            </OvalCard>
            <Richtext className="Deepening_description" typo="paragraph3" bold>
              {description}
            </Richtext>
          </StyledDeepening>
        ))
      }
    </StyledDeepenings>
  )
}

export default Deepenings
