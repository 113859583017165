import React from 'react'

import colors from 'theme/colors'

import Frame from '@/atoms/Frame'
import Icon from '@/atoms/Icon'
import { Label } from '@/atoms/Typography'
import RichText from '@/atoms/RichText'

import { StyledLocation } from './styles'

const Location = ({
  title,
  details,
  description
}) => (
  <StyledLocation>
    <Frame
      className='Location_icon'
      border
      color={colors.grayscale.gray3}
      size={66}
    >
      <Icon icon="pin"/>
    </Frame>

    {
      details &&
      <Label
        bold
        as="span"
        typo="paragraph1">
        {details}
      </Label>
    }
    {
      title &&
      <Label
        bold
        $title
        uppercase
        as="strong"
        typo="subheading2">
        {title}
      </Label>
    }

    {
      description &&
      <RichText className="Location_description">{description}</RichText>
    }

  </StyledLocation>
)

export default Location
