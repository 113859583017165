import styled from 'styled-components'

export const StyledVideoModalContainer = styled.div`
  max-width: 1180px;
  width: 100%;
  ${({ theme }) => theme.paddingX(50)};

  button{
    color: white;
    float: right;
    margin-bottom: ${({ theme }) => theme.rem(16)};
    span{
      font-size: 14px;
    }
  }

  .VideoModal{
    clear: both;
    aspect-ratio: 16 / 9;

    iframe{
      width: 100%;
      height: 100%;
    }
  }
`
