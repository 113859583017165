import styled from 'styled-components'

export const Styled = {
  Button: styled.button`
    appearance: none;
    border: none;
    border-radius: 50%;
    background-color:  ${props => props.$bgColor || "var(--Blue-Primary)"};
    width: 12.5rem;
    height: 12.5rem;
    color: var(--White);
    
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
    border: 5px solid var(--Bg-Landing);
    outline: 2px dotted var(--Border-color);
    text-transform: uppercase;
    font-size: 1.3rem;
    transform-origin: center;
    font-weight: 700;
    text-align: center;

    &:nth-child(1){
      grid-column: 2 / 2;
      grid-row: 1;
      transform: var(--Position-Button-01);
      transform-origin: center;
    }
    &:nth-child(1):hover{
      transform: var(--Position-Button-01) var(--Button-Hover);
    }
  
    &:nth-child(2){
      grid-column: 1 / 1;
      grid-row: 2;
      transform: var(--Position-Button-02);
    }
    &:nth-child(2):hover{
      transform: var(--Position-Button-02) var(--Button-Hover);
    }
  
    &:nth-child(3){
      grid-column: 1 / 1;
      grid-row: 3;
      transform: var(--Position-Button-03);
    }
    &:nth-child(3):hover{
      transform: var(--Position-Button-03) var(--Button-Hover);
    }
  
    &:nth-child(4){
      grid-column: 2;
      grid-row: 4;
      transform: var(--Position-Button-04);
    }
    &:nth-child(4):hover{
      transform: var(--Position-Button-04) var(--Button-Hover);
    }
  
    &:nth-child(5){
      grid-column: 3;
      grid-row: 4;
      transform: var(--Position-Button-05);
    }
    &:nth-child(5):hover{
      transform: var(--Position-Button-05) var(--Button-Hover);
    }
  
    &:nth-child(6){
      grid-column: 4;
      grid-row: 3;
      transform: var(--Position-Button-06);
    }
    &:nth-child(6):hover{
      transform: var(--Position-Button-06) var(--Button-Hover);
    }
  
    &:nth-child(7){
      grid-column: 4;
      grid-row: 1;
      transform: var(--Position-Button-07);
    }
    &:nth-child(7):hover{
      transform: var(--Position-Button-07) var(--Button-Hover);
    }
    
  `,
  Details: styled.details`
    overflow: hidden;
    margin-top: 0.125em;  
    border-radius: 3px;
    color: ${props => props.$bgColor || "var(--Blue-Primary)"};
    border-bottom: 2px dotted rgba(0,0,0,0.4);
    width: 100%;
    span {
      padding-left: 21px;
    }
    ul {
      margin-bottom: 12px;
      margin-left: 21px;
    }
    &[open]{
      summary::after {
        content: '-';
      }
      summary {
        padding: 1.4em 0 0 0;
      }
    }
  `
}