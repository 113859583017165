import { ENDPOINTS } from './config'
import OptionsModel from 'models/Options'
import MenusModel from 'models/Menus'

export const getApiUrl = () => {
  return process.env.API_URL
}

export const fetchData = async (url, opt = {}) => {
  let response

  console.log('Fetching data', url)

  try {
    const req = await fetch(url, opt)
    response = await req.json()
  } catch (err) {
    console.error(err)
  }

  return response
}

export const getPageData = async ({
  entity, id = null
}) => {
  if (!id) throw new Error('Page ID is missing!')

  const baseUrl = getApiUrl()
  const url = `${baseUrl}${ENDPOINTS[entity]}?populate=deep&filters[id]=${id}`
  const data = await fetchData(url)

  return data
}

export const getEntity = async ({
  entity,
  params = {},
}) => {
  const baseUrl = getApiUrl()

  let endpoint = ENDPOINTS[entity]
  const queryString = new URLSearchParams(params).toString()
  if (queryString) endpoint = `${endpoint}?${queryString}`

  const apiURL = `${baseUrl}${endpoint}`

  const data = await fetchData(apiURL)
  data.entity = entity

  return data
}

export const getOptions = async () => {
  const data = await getEntity({
    entity: 'options',
    params: { populate: 'deep' }
  })

  if (!data.data) return {}

  const options = await OptionsModel(data)

  return options
}

export const getMenus = async () => {
  const data = await getEntity({ entity: 'menus' })

  if (!data.data) return {}

  const menus = await MenusModel(data)

  return menus
}


