import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledOvalCard = styled.div`
  position: relative;
  display: table;
  margin: 0 auto;

  ${({
    theme,
    $withBackground
  }) => $withBackground && `
    &:before{
      content: '';
      position: absolute;
      z-index: 0;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotate(-23deg);
      background-color: ${theme.colors.grayscale.white()};
      ${theme.size('110%')}
    }
  `}

  svg{
    position: relative;
    width: 100%;
    color: ${styledMap`
      default: ${({ theme }) => theme.colors.primary.default()};
      $secondary: ${({ theme }) => theme.colors.background.lightBlue};
    `}
  }

  .OvalImage{
    display: table;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 64px;
    width: auto;
    bottom: -32px;
    object-fit: contain;
    max-width: 100px;
  }
`

export const StyledOvalCardContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: ${({ theme })=> theme.colors.secondary.default}; */
`
