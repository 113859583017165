import styled from 'styled-components'

import Frame from '@/atoms/Frame'
import Button from '@/atoms/Button'

export const StyledRegionList = styled.div`
  display: table;
  margin: 0 auto;
  position: relative;
  margin-top: ${({ theme }) => theme.rem(64)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: 0;
  `)}
`

export const StyledRegionListDecoration = styled.div`

  ${Frame}{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index:0;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 75%;
    `)}
    ${({ theme }) => theme.mediaQuery.lg(`
      max-width: 55%;
    `)}
  }


  img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;

  }
`
export const StyledRegionListItems = styled.div``

export const StyledRegionListItemTrigger = styled(Button)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grayscale.white()};
  z-index: 2;
  box-shadow: -2px 2px 6px rgba(94, 97, 131, 0.4);
  border-radius: 32px;
  padding: ${({ theme }) => theme.rem(4, 14)};
  transition: background-color ease-in-out .4s;

  &:hover{
    background-color: ${({ theme }) => theme.colors.background.lightBlue};
  }

  ${({ theme }) => theme.mediaQuery.md(`
    padding: ${theme.rem(10, 15)};
  `)}

  .Establishment_icon{
    display: none;
    ${({ theme }) => theme.mediaQuery.md(`
      display: inline-block;
    `)}
  }
  > span{
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
      ${({ theme }) =>theme.typo('paragraph3')};
      ${({ theme }) =>theme.marginX([0, 4])};

      ${({ theme }) => theme.mediaQuery.md(`
       ${theme.marginX([12, 8])}
    `)}

    }
  }

  i{
    &:first-child{
    font-size: ${({ theme }) => theme.rem(24)};
  }
  &:last-child{
    font-size: ${({ theme }) => theme.rem(12)};
  }
  }


`
