import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Paragraph from '@/molecules/Paragraph'
import Map from '@/molecules/Map'
import Deepenings from '@/molecules/Deepenings'

const MapWithPercents = ({
  heading,
  paragraph,
  deepenings,
  image,
  regions,
}) => (
  <SectionContainer heading={heading}>
    <Map image={image} items={regions} full/>
    <Paragraph {...paragraph} />
    <Deepenings items={deepenings} />
  </SectionContainer>
)

export default MapWithPercents
