import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Accordion from '@/molecules/Accordion'
const AccordionList = ({
  heading,
  accordions
}) => (
  <SectionContainer heading={heading}>
    <Accordion list={accordions} />
  </SectionContainer>
)

export default AccordionList
