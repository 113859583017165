import React from 'react'
import { object } from 'yup'

import { createValidationSchema } from 'models/Form'

import CustomForm from '@/atoms/CustomForm'

const Form = ({
  form,
  ...rest
}) => {
  if (!form) return <></>
  const {
    fields,
    endpoint,
    successMessage,
    submitButton,
    method = 'POST',
  } = form

  let validationSchema = {}

  fields.originalFieldset.forEach(({ fields: f }) => {
    const fieldsetValidationSchema = f.reduce(createValidationSchema, {})
    validationSchema = {
      ...validationSchema,
      ...fieldsetValidationSchema
    }
  })

  return (
    <CustomForm
      {...rest}
      {...fields}
      submitButton={submitButton}
      method={method}
      successMessage={successMessage}
      endpoint={endpoint}
      validateOnMount
      validationSchema={object().shape(validationSchema)}
      settings={fields.settings}
    />
  )
}


export default Form
