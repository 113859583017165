import React, {
  useState,
  useMemo
} from 'react'

import Button from '@/atoms/Button'
import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'

import CityPreview from './City/Preview'
import CityContent from './City/Content'

import {
  StyledRegionContent,
  StyledRegionContentSteps,
  StyledRegionContentStep,
  StyledRegionContentStepContainer,
  StyledRegionContentCities,
  StyledRegionContentStepHeader,
} from './styles'

const container = {
  first: { },
  last: { }
}

const RegionContent = ({
  items,
  region,
  modalClose
}) => {
  const cities = useMemo(() => {
    return items.find(({ region: regionName }) => regionName === region)
  }, [region])

  const {
    stepToShow,
    cityToShow,
    showBack
  } = useMemo(() => {
    if (!!cities.items.length && cities.items.length > 1) {
      return {
        stepToShow: 'first',
        cityToShow: null,
        showBack: true,
      }
    }
    return {
      stepToShow: 'last',
      cityToShow: cities.items[0],
      showBack: false,
    }
  }, [region, cities])


  const [city, setCity] = useState(cityToShow)
  const [step, setStep] = useState(stepToShow)


  const onCityClick = (cityItem) => {
    setCity(cityItem)
    setStep('last')
  }

  const onBack = () => {
    setCity(null)
    setStep('first')
  }

  return (
    <StyledRegionContent>
      <StyledRegionContentSteps>
        <StyledRegionContentStepContainer
          variants={container}
          initial={'first'}
          animate={step}

        >
          {
            step === 'first' &&
            <StyledRegionContentStep>
              <StyledRegionContentStepHeader>
                <Label
                  $title
                  bold
                  typo="subheading1"
                  style={{ gridArea: 'title' }}
                >
                    Gli stabilimenti in {region}
                </Label>
              </StyledRegionContentStepHeader>

              <StyledRegionContentCities rows={!!cities.items.length ? Math.floor(cities.items.length / 2) : 8} data-lenis-prevent>
                {
                  cities.items &&
                  !!cities.items.length &&
                  cities.items.map(singleCity => <CityPreview key={singleCity.id} onClick={onCityClick} data={singleCity}  />)
                }
              </StyledRegionContentCities>

            </StyledRegionContentStep>
          }

          {
            step === 'last' &&
            <StyledRegionContentStep
              style={{
                paddingBottom: 0,
                paddingLeft: 0
              }}
            >
              {
                city &&
                <CityContent {...city} showBack={showBack} onBack={onBack} />
              }
            </StyledRegionContentStep>
          }
        </StyledRegionContentStepContainer>
      </StyledRegionContentSteps>

      <Button className="Modal_close" onClick={modalClose}>
        <Icon icon="close" useIconColor />
      </Button>
    </StyledRegionContent>
  )
}

export default RegionContent
