import React from 'react'

import colors from 'theme/colors'

import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
import ImageFramed from '@/molecules/ImageFramed'


import {
  StyledCards,
  StyledCard,
  StyledCardContent,
} from './styles'

const Cards = ({ items }) => (
  items &&
  !!items.length &&
  <StyledCards>
    {
      items.map(({
        text, title, image
      }) => (
        <StyledCard key={title}>
          <ImageFramed
            className="CardIcon"
            image={image}
            frameProps={{
              background: false,
              border: true,
              color: colors.secondary.default
            }}
          />
          <StyledCardContent>
            <Label $title bold as="strong" typo="h5">{title}</Label>
            <Richtext typo="paragraph1">
              {text}
            </Richtext>
          </StyledCardContent>
        </StyledCard>
      ))
    }
  </StyledCards>
)

export default Cards
