import React, { useMemo } from 'react'
import {
  motion,
  useTransform
} from 'framer-motion'

import useIsMobile from 'hooks/useIsMobile'

import {
  StyledInfoGraphicText,
  StyledInfoGraphicTextWrapper
} from './styles'

const InfographicText = ({
  text,
  position,
  floating,
  masked = false,
  coordinates = {}
}) => {
  const isMobile = useIsMobile()
  const x = useTransform(coordinates.x, [0, 3000], [-20, 20])
  const y = useTransform(coordinates.y, [0, 2250], [20, -20])

  const style = useMemo(() => {
    return !isMobile ? {
      x, y
    } : {}
  }, [isMobile])

  return (
    text &&
    <StyledInfoGraphicTextWrapper
      as={motion.div}
      masked={masked}
      style={style}
    >
      <StyledInfoGraphicText
        floating={floating}
        position={position}
        bold uppercase
      >
        {text}
      </StyledInfoGraphicText>
    </StyledInfoGraphicTextWrapper>
  )
}

export default InfographicText
