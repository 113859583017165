import styled from 'styled-components'

import { sliderCommon } from '@/atoms/Slider/styles'

export const StyledStorylineContent = styled.div`
  ${sliderCommon}
`

export const StyledStoryLineContentSingle = styled.div`
  width: 250px;
  display: table;
  margin: 0 auto;
  flex-shrink: 0;
  ${({ theme }) => theme.paddingY(16)};

  ${({
    theme,
    light
  }) => !!light && `
    .StoryLineContent_detail,
    .StoryLineContent_title{
      color: ${theme.colors.secondary.default}
    }
  ` };
`

export const StyledStoryLineContentSingleText = styled.div`
  display: block;
  text-align: center;
  margin-top: ${({ theme }) => theme.rem(48)};

  span{

    margin-bottom: ${({ theme }) => theme.rem(4)};
  }

  strong {
    display: block;
    text-transform: uppercase;
    ${({ theme }) => theme.marginY([0, 10])}
  }
`
