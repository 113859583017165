import React, { useRef } from 'react'
import { useLenis } from '@studio-freight/react-lenis'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import { Container } from '@/atoms/Layout/Grid'
import Button from '@/atoms/Button'
import Drop from '@/atoms/Drop'

import WithHeading from '@/molecules/WithHeading'
import Wave from '@/molecules/Wave'
import Media from '@/molecules/Media'

import {
  StyledHero,
  StyledHeroDrop,
  StyledHeroDropContainer,
  StyledHeroMedia,
  StyledHeroCta,
  StyledHeroFooter
} from './styles'

const Hero = ({
  heading,
  fluid,
  media,
  wave,
  ctaLabel,
}) => {
  const containerRef = useRef(null)
  const ref = useRef(null)
  const lenis = useLenis()
  return (
    <Container
      ref={containerRef}
      $fluid={fluid}
      style={{
        marginTop: 0,
        marginBottom: 0,
      }}>

      <StyledHeroDrop ref={ref}>
        <StyledHeroDropContainer >
          <Drop />
        </StyledHeroDropContainer>
      </StyledHeroDrop>

      <StyledHero>

        {
          heading &&
          <WithHeading heading={heading} $decorator={false} outline uppercase $noPadding/>
        }

        {
          media &&
          <StyledHeroMedia>
            <Media {...media} hero />
          </StyledHeroMedia>
        }

        {
          ctaLabel &&
          <StyledHeroCta>
            <Button
              bold
              $title
              typo="link2"
              label={ctaLabel}
              onClick={() => {
                const nextSection = containerRef.current.parentElement.nextSibling
                if (nextSection) lenis.scrollTo(nextSection)
              }}
            />
          </StyledHeroCta>
        }
        {
          wave &&
          <StyledHeroFooter>
            <Wave id={wave} position="bottom" template="transparent"/>
          </StyledHeroFooter>
        }
      </StyledHero>

    </Container>
  )
}

export default Hero
