import styled from 'styled-components'
import Link from '@/atoms/Link'


export const StyledProjectsPreview = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.rem(32)};
  margin-top: ${({ theme }) => theme.rem(80)};

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: repeat(2, 1fr);
  `)};
`

export const StyledProjectsPreviewItem = styled(Link)`
  display: grid;
  grid-template-columns: 150px 10fr;
  grid-template-areas: "preview content";
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colors.grayscale.white()};
  min-height: 190px;
`

export const StyledProjectsPreviewItemImage = styled.div`
  img{
    ${({ theme }) => theme.size('100%')};
    object-fit: cover;
  }
`

export const StyledProjectsPreviewItemContent = styled.div`
  padding: ${({ theme }) => theme.rem(40)};

  .Preview_title{
    display: block;
    margin-bottom: ${({ theme }) => theme.rem(16)};

    i{

      margin-left: ${({ theme }) => theme.rem(14)};
    }
  }
`
