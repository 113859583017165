import React from 'react'
import dynamic from 'next/dynamic'

import colors from 'theme/colors'
import Media from '@/molecules/Media' // Evaluate to add it in dynamic mode
import Button from '@/atoms/Button'
const StandardModal = dynamic(() => import('../StandardModal'), { ssr: false, })

import { StyledVideoModalContainer, } from './styles'

const wrapperExitAnimation = {
  opacity: 0,
  transition: {
    delay: 0.5,
    duration: 0.4
  }
}

const Video = ({
  video,
  isOpen,
  modalHandler
}) =>  (
  <StandardModal
    wrapperId='video-modal'
    isOpen={isOpen}
    setIsOpen={modalHandler}
    hideCloseButton
    wrapperProps={{
      exit: wrapperExitAnimation,
      style: {
        height: '100%',
        with: '100%',
        display: 'block',
        backgroundColor: colors.grayscale.black(0.7),
        zIndex: 20
      }
    }}
    containerProps={{
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }
    }}
  >
    <StyledVideoModalContainer>
      <Button onClick={modalHandler} >Chiudi</Button>
      <Media className={'VideoModal'} {...video} />
    </StyledVideoModalContainer>
  </StandardModal>
)

export default Video
