import React, {
  useRef, useMemo
} from 'react'

import {
  motion,
  useMotionValue,
  useTransform
} from 'framer-motion'

import useIsMobile from 'hooks/useIsMobile'
import colors from 'theme/colors'

import CircleBoxes from '@/molecules/CircleBoxes'
import Frame from '@/atoms/Frame'

import InfographicImages from './Images'
import InfographicText from './Text'

import {
  StyledInfoGraphic,
  StyledInfoGraphicSubject,
  StyledInfoGraphicSubjectContainer,
  StyledInfoGraphicSubjectContent,
  StyledInfoGraphicInfoboxes,
  StyledInfoGraphicInfoboxesContainer,
} from './styles'

// TODO: Animations
// 1. add parallax
// 2. add movement on mouse distance

const Infographic = ({
  circleBoxes,
  circleBoxStart,
  imageText,
  offset,
  mainImage,
  positions,
  columnDisposition,
}) => {
  const containerRef = useRef(null)
  const ref = useRef(null)
  const x = useMotionValue(0)
  const y = useMotionValue(0)
  const posX = useTransform(x, [100, 3000], [25, -25])
  const posY = useTransform(y, [100, 2250], [-25, 25])
  const isMobile = useIsMobile()

  function handleMouse(event) {
    x.set(event.clientX)
    y.set(event.clientY)
  }

  const style = useMemo(() => {
    return !isMobile ? {
      x: posX, y: posY
    } : {}
  }, [isMobile])

  return (
    <StyledInfoGraphic
      as={motion.div}
      ref={containerRef}
      onMouseMove={handleMouse}
    >
      <StyledInfoGraphicSubject>
        <StyledInfoGraphicSubjectContainer >
          {
            imageText &&
            <InfographicText
              coordinates={{
                x, y
              }}
              direction={circleBoxStart}
              masked={mainImage.masked}
              {...imageText}
            />
          }

          <StyledInfoGraphicSubjectContent>
            {
              (!mainImage.masked || isMobile) &&
              <Frame
                style={style}
                as={motion.div}
                backgroundColor={colors.background.lightBlue}
              />
            }

            <InfographicImages
              coordinates={{
                x, y
              }}
              {...mainImage}
            />
          </StyledInfoGraphicSubjectContent>
        </StyledInfoGraphicSubjectContainer>


      </StyledInfoGraphicSubject>

      <StyledInfoGraphicInfoboxes>
        <StyledInfoGraphicInfoboxesContainer ref={ref}>
          <CircleBoxes
            containerRef={ref}
            items={circleBoxes}
            columnDisposition={columnDisposition}
            positions={positions}
            start={circleBoxStart}
            offset={offset}
          />

        </StyledInfoGraphicInfoboxesContainer>
      </StyledInfoGraphicInfoboxes>
    </StyledInfoGraphic>
  )
}


export default Infographic
