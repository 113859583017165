import React from 'react'

import Location from '@/molecules/Location'

import { StyledAddresses } from './styles'

const Addresses = ({ items }) => (
  items &&
  !!items.length &&
  <StyledAddresses>
    {
      items.map(({
        title,
        description,
        detail
      }) => (
        <Location
          key={title}
          title={title}
          description={description}
          details={detail}
        />
      ))
    }
  </StyledAddresses>
)

export default Addresses
