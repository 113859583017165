import styled from 'styled-components'
import Frame from '@/atoms/Frame'

export const StyledMaskContainer = styled(Frame)`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(16, 16, 16, 0.25), rgba(16, 16, 16, 0.25));
    ${({ theme }) => theme.size('100%')};
  }
`

