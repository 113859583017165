import styled from 'styled-components'

export const StyledCircleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 160px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: 0;
    position: absolute;
    transform-origin: center center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `)}

  .CircleFrame {
    max-width: 80px;
    width: 100%;
    ${({ theme }) => theme.marginX('auto')};
    padding: ${({ theme }) => theme.rem(16)};

    ${({ theme }) =>
    theme.mediaQuery.md(`
      max-width: 120px;
      `)}
  }

  .CircleImage {
    width: 100%;
  }

  img {
    max-height: 30px;
    width: 100%;
    height: auto;
    ${({ theme }) =>
    theme.mediaQuery.md(`
      max-height: 50px;
      `)}
  }

  .Circle_text {
    color: ${({ theme }) => theme.colors.secondary.default};
    ${({ theme }) =>
    theme.mediaQuery.md(`
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 110%;
  `)}
  }

  &.simpleCircleBox {
    justify-content: flex-start;
    gap: ${({ theme }) => theme.rem(8)};
    /* max-width: ${({ theme }) => theme.rem(100)}; */
    padding: unset;
    max-width: 45%;


    ${({ theme }) =>
    theme.mediaQuery.md(`
        padding: 0;
        position: inherit;
        top: unset;
        left: unset;
        transform: unset;
        flex-basis: 25%;
        max-width: 20%;
      `)}

    img {
      height: 30px;
      width: auto;
      aspect-ratio: 1;

      ${({ theme }) =>
    theme.mediaQuery.md(`
        height: 50px;
      `)}
    }

    .Circle_title {
      margin-bottom: -${({ theme }) => theme.rem(8)};
    }

    .Circle_text {
      ${({ theme }) =>
    theme.mediaQuery.md(`
        position: inherit;
        transform: unset;
        top: unset;
        left: unset;
        width: 100%;
      `)}
    }
  }
`
