import React, { useMemo } from 'react'
import {
  Swiper,
  SwiperSlide
} from 'swiper/react'

import {
  Navigation,
  Scrollbar,
  A11y
} from 'swiper'


import Richtext from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'

import OvalCard from '@/molecules/OvalCard'
import SliderNavigation from '@/molecules/Slider/Navigation'

import {
  StyledStorylineContent,
  StyledStoryLineContentSingle,
  StyledStoryLineContentSingleText,
} from './styles'

const StoryLineContent = ({
  current,
  items
}) => {
  const navPrevButton = React.useRef(null)
  const navNextButton = React.useRef(null)
  const currentItems = useMemo(
    () => items.filter(({ id }) => id === current),
    [current, items]
  )

  return (
    <StyledStorylineContent
      data-lenis-prevent
    >
      <Swiper
        spaceBetween={64}
        slidesPerView="auto"
        centeredSlides={true}
        modules={[Navigation, Scrollbar, A11y]}
        scrollbar={{ draggable: true }}
        breakpoints={{ 480: { centeredSlides: false } }}
        navigation={{
          prevEl: navPrevButton.current,
          nextEl: navNextButton.current,
        }}
        onBeforeInit={(swiper)=> {
          swiper.params.navigation.prevEl = navPrevButton.current
          swiper.params.navigation.nextEl = navNextButton.current
          swiper.navigation.update()
        }}
      >
        {
          currentItems[0].items.map(({
            background,
            description,
            id,
            image,
            title,
            year,
            details,
          }) => (
            <SwiperSlide key={id} >
              <StyledStoryLineContentSingle
                light={background}
              >
                <OvalCard
                  $secondary
                  image={image}
                  $withBackground={background}
                >
                  {
                    year &&
                    <Label as="span" typo="h4" light>{year}</Label>
                  }
                </OvalCard>

                <StyledStoryLineContentSingleText light={background}>
                  {
                    details &&
                    <Label className='StoryLineContent_detail' as="span" bold typo="paragraph3">
                      {details}
                    </Label>
                  }
                  {
                    title &&
                    <Label className='StoryLineContent_title' as="strong" bold typo="paragraph2">
                      {title}
                    </Label>
                  }

                  {
                    description &&
                    <Richtext typo="paragraph4">
                      {description}
                    </Richtext>
                  }
                </StyledStoryLineContentSingleText>
              </StyledStoryLineContentSingle>
            </SwiperSlide>
          ))
        }

        <SliderNavigation prevRef={navPrevButton} nextRef={navNextButton} />
      </Swiper>
    </StyledStorylineContent>
  )
}

export default StoryLineContent
