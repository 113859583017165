import React from 'react'
import colors from 'theme/colors'

import CustomImage from '@/atoms/Image'
import RichText from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'
import Frame from '@/atoms/Frame'

import {
  StyledInfoGroup,
  StyledInfoGroupContainer,
  StyledInfoGroupDetails,
  StyledInfoGroupDetail,
  StyledInfoGroupIcon,
} from './styles'

const InfoGroup = ({
  title,
  icon,
  description,
  image,
  info,
}) => {
  const withIcon = !!icon.data

  return (
    <StyledInfoGroup withIcon={withIcon}>
      {
        title &&
        withIcon &&
        <Label className="InfoGroup_title" as="strong" bold $title>{title}</Label>
      }

      <StyledInfoGroupContainer withIcon={withIcon}>
        <CustomImage className="InfoGroup_image" {...image} style={{ gridArea: 'unit' }}/>

        {
          (title || description) &&
          <div style={{ gridArea: 'description' }}>
            {
              title &&
            !withIcon &&
            <Label className="InfoGroup_title" as="strong" bold $title>{title}</Label>
            }
            <RichText className="InfoGroup_description">{description}</RichText>
          </div>
        }
        {
          icon.data &&
          <StyledInfoGroupIcon>
            <Frame border color={colors.secondary.default} />
            <CustomImage {...icon}  style={{ gridArea: 'icon' }}/>
          </StyledInfoGroupIcon>
        }
        {
          info &&
          !!info.length &&
          <StyledInfoGroupDetails  withIcon={withIcon}>
            {
              info.map(({
                title: infoTitle,
                description: infoDescription,
                image: infoImage
              }) => (
                <StyledInfoGroupDetail key={infoTitle}>
                  {
                    infoImage.data &&
                    <CustomImage {...infoImage} />
                  }

                  <Label
                    bold
                    $title
                    uppercase
                    as="h5"
                    typo="h5"
                    dangerouslySetInnerHTML={{ __html: infoTitle }}
                  />

                  <RichText className="GroupDetail_description" typo="paragraph1" bold>{infoDescription}</RichText>

                </StyledInfoGroupDetail>
              ))
            }
          </StyledInfoGroupDetails>
        }
      </StyledInfoGroupContainer>
    </StyledInfoGroup>
  )
}

export default InfoGroup
