import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Infos from '@/molecules/Infos'

const Objectives = ({
  heading,
  info
}) => (
  <SectionContainer heading={heading}>
    <Infos {...info} />
  </SectionContainer>
)
export default Objectives
