import styled from 'styled-components'

export const StyledFieldset = styled.fieldset`
  all: unset;
  display: block;
  width: 100%;
  border-color: transparent;
  margin: 0 0 ${({ theme })=> theme.rem(72)} 0;
  padding: 0;
  max-width: 650px;
  width: 100%;
  &:last-child { margin: 0 }
`

export const StyledFieldsetTitle = styled.div`
  display:  block;
  margin-bottom:${({ theme })=> theme.rem(40)};

  strong {
    color: ${({ theme })=> theme.colors.primary.default()}
  }
`

export const StyledFieldsetContent = styled.div`

`

export const StyledFormSubmit = styled.div`
  .FormSubmit {
    justify-content: center;
    display: table;
    background-color: ${({ theme }) => theme.colors.primary.default()};
    color: ${({ theme }) => theme.colors.grayscale.white()};
    border-radius: 32px;
    padding: 20px 24px 16px;

    span{
      line-height: 100%;
      font-family: ${({ theme }) => theme.fonts.title};
      font-size: ${({ theme }) => theme.rem(16)};
      font-weight: 700;
    }
  }
`

export const StyledFormFooter = styled.div`
  ${({ theme })=> theme.typo('paragraph1')};
  margin-bottom: ${({ theme })=> theme.rem(24)};
  color: ${({ theme })=> theme.colors.grayscale.gray3};
`
