import styled from 'styled-components'

export const StyledHeroMedia = styled.main`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
`

export const StyledHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  position: relative;
  ${({ theme }) => theme.paddingX(24)};
  ${({ theme }) => theme.size(['100vw', '80vh'])};
  color: ${({ theme }) => theme.colors.grayscale.white()};
  ${({ theme }) => theme.mediaQuery.md(theme.size(['100vw', '100vh']))};

  > header {
    position: relative;
    z-index: 1;
    width: 100%;
    > div{
      ${({ theme }) => theme.mediaQuery.lg(`
        max-width: 50%;
      `)};
    }
    .Heading_title{
      display: table;
      color: ${({ theme }) => theme.colors.grayscale.white()};

      > span{
        margin: 0 auto;
        text-align: center;
      }
    }
  }
`

export const StyledHeroDrop = styled.div`
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  z-index: 1;
`
export const StyledHeroDropContainer = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center; */
  /* left: 50%;
  transform: translateX(-50%); */
  color: ${({ theme }) => theme.colors.grayscale.white()};
`


export const StyledHeroCta = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);

  button{
    position: relative;
    padding-left: ${({ theme }) => theme.rem(40)};
    letter-spacing: 1px;
    &::before{
      content: '';
      position: absolute;
      top: 35%;
      left: 0;
      ${({ theme }) => theme.size([30, 1])};
      background-color: ${({ theme }) => theme.colors.grayscale.white()};
    }
  }
`


export const StyledHeroFooter = styled.footer`
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 20;
`
