import styled from 'styled-components'

export const StyledWave = styled.div`
  ${({ theme }) => theme.paddingY(48)};
  .Attachments{
    margin: 0;
  }

  .Attachment,
  .WaveLink,
  .Attachment a{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .WaveLink,
  .Attachment a{
    ${({ theme }) => theme.paddingX(0)};
    ${({ theme }) => theme.typo('h5')};
    row-gap: ${({ theme }) => theme.rem(24)};
    color: ${({ theme }) => theme.colors.grayscale.white()};

    i{
      border-color: ${({ theme }) => theme.colors.grayscale.white()};
    }
  }
`
