import React from 'react'
import dynamic from 'next/dynamic'

import Image from '@/atoms/Image'

const Video = dynamic(() => import('@/atoms/Video'))

const videoProviders = ['youtube', 'vimeo']

const checkMediaType = (media) => {
  const mediaProvider = videoProviders.find(provider => provider === media.data.attributes.provider)

  const isVideo = media && media.data && media.data.attributes.mime?.includes('video') || !!mediaProvider

  return {
    isVideo,
    mediaProvider
  }
}

const Media = ({
  title,
  media,
  hero = false,
  className = null,
}) => {
  const {
    isVideo,
    mediaProvider
  } = checkMediaType(media)

  const mediaProps = isVideo ? {
    title,
    media,
    mediaProvider,
    hero,
    className,
  } : {
    className,
    as: 'div',
    ...media,
  }

  return (
    isVideo
      ? <Video {...mediaProps} />
      : <Image {...mediaProps} />
  )
}


export default Media
