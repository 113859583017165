import React, { useState } from 'react'
import dynamic from 'next/dynamic'

import Person from '.'

const PersonModal = dynamic(() => import('@/molecules/Modals/PersonModal'), { ssr: false, })

const PersonPreview = ({
  name,
  surname,
  image,
  cta,
  jobTitle,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const modalHandler = () => {
    setIsOpen(false)
  }

  const onClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <Person
        preview
        name={name}
        surname={surname}
        cta={cta}
        image={image}
        onClick={onClick}
      />

      <PersonModal
        isOpen={isOpen}
        modalHandler={modalHandler}
      >
        <Person
          layout='row'
          name={name}
          surname={surname}
          image={image}
          jobTitle={jobTitle}
          description={description}
          closeModal={modalHandler}
        />
      </PersonModal>
    </>
  )
}

export default PersonPreview
