import Icon from '@/atoms/Icon'
import Richtext from '@/atoms/RichText'
import {
  StyledBackContainer, StyledBackLink
} from './styles'

const Back = ({
  title, href
}) => {
  return (
    <StyledBackLink
      href={href}
      style={{ display: 'inline-block' }}
    >
      <StyledBackContainer>
        <Icon icon={'arrow-left'} />
        <Richtext
          typo={'subheading2'}
          style={{ fontWeight: '700' }}
        >
          {title}
        </Richtext>
      </StyledBackContainer>
    </StyledBackLink>
  )
}
export default Back
