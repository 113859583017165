import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import LeadershipContent from '@/molecules/Leadership'

const Leadership = ({
  heading,
  units,
}) => (
  <SectionContainer heading={heading}>
    <LeadershipContent units={units} />
  </SectionContainer>
)
export default Leadership
