import styled from 'styled-components'

export const Styled = {
  MainContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (width <= 48rem){
      margin: 31px 0 0 0; 
      gap: 50px;
    }
  `,
  ButtonMain: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    gap: 10px;
    @media (width <= 64rem){
      width: 65%;
    }
    @media (width <= 48rem){
      width: auto;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: minmax(50px, auto);
      gap: 5px;
    }
  `,
  TitleCircle: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
    background-color: var(--White);
    width: 20.6rem;
    height: 20.6rem;
    border-radius: 50%;
    padding-inline: 65px;
    padding-block: 110px;
    border: 5px solid var(--Bg-Landing);
    outline: 2px dotted rgb(0, 0, 0, 0.4);
    transform: translate(0%, 2%);
    @media (width <= 64rem){
      width: 15rem;
      height: 15rem;
      padding: 0;
    }
    @media (width <= 48rem) {
      width: 185px;
      height: 185px;
      border-radius: calc(185px / 2);
      padding: 0;
    }
  `,
  TitleText: styled.h3`
    color: var(--Blue-Main);
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    @media (width <= 64rem){
      font-size: 1.5rem;
    }
    @media (width <= 48rem){
      font-size: 1.2rem;
    }
  `,
  DescriptionMain: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 1000ms;
    width: fit-content;
    @media (width <= 48rem){
      flex-direction: column-reverse;
    }
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  `,
  DescriptionTitle: styled.h2`
    font-size: 2.3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: ${props => props.$titleColor};
  `,
  DetailUl: styled.ul`
    margin-bottom: 12px;
    text-indent: -21px;
    margin-left: 21px;
  `,
  Summary: styled.summary`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1.4em 0em;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  gap: 10px;
  &::after{
    content: '+';
    font-size: 2rem;
    color: var(--Blue-Third);
    width: 24px;
    height: 24px;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
  }`,
  SpanCircle: styled.span`
    background-color: ${props => props.$bgColor || "var(--Blue-Primary)"};
    border: 2px solid var(--Bg-Landing);
    outline: 2px dotted rgb(0, 0, 0, 0.4);
    @media (width <= 64rem){
      width: 5rem;
      height: 5rem;
      border-radius: calc(5rem / 2);
    }
    @media (width <= 48rem){
      width: 3.125rem;
      height: 3.125rem;
    }
    &:nth-child(1){
      grid-column: 2 / 2;
      grid-row: 1;
      transform: translate(calc(0% + 3.125rem / 3), calc(0% + 3.125rem / 6));
    }
    &:nth-child(2){
      grid-column: 1 / 1;
      grid-row: 2;
      transform: translate(calc(0% + 3.125rem / 6), calc(40% + 3.125rem / 2));
    }
    &:nth-child(3){
      grid-column: 1 / 1;
      grid-row: 3;
      transform: translate(calc(20% + 3.125rem / 2), calc(70% + 3.125rem / 2));
    }
    &:nth-child(4){
      grid-column: 3;
      grid-row: 4;
      transform: translate(calc(0% - 3.125rem / 2), calc(0% + 3.125rem / 4));
    }
    &:nth-child(5){
      grid-column: 4;
      grid-row: 3;
      transform: translate(calc(40% - 3.125rem / 2), calc(50% + 3.125rem / 3));
    }
    &:nth-child(6){
      grid-column: 4;
      grid-row: 2;
      transform: translate(calc(0% + 3.125rem / 6), calc(40% + 3.125rem / 2));
    }
    &:nth-child(7){
      grid-column: 3;
      grid-row: 1;
      transform: translate(calc(20% + 3.125rem / 2), calc(0% + 3.125rem / 4));
    }
  `
}
