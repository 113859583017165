
import Frame from '@/atoms/Frame'

import { StyledMaskContainer } from './styles'

const Mask = ({
  style,
  color,
  outerBorder,
  children,
  border,
  square,
  ...rest
}) => (
  <Frame
    border={border}
    color={color}
    outerBorder={outerBorder}
    style={style}
    square={square}
  >
    <StyledMaskContainer square={square} {...rest}>{children}</StyledMaskContainer>
  </Frame>
)

export default Mask
