import styled from 'styled-components'


export const StyledNextPage = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaQuery.md(`
    padding-top: ${theme.rem(100)};
  `)}

  .WaveContainer::before{
    display: none;
  }
  .WithWave
  .Drop{
    color: ${({ theme }) => theme.colors.background.beige } !important;
    max-width: 28px;

    #Drop_body{
      width: 2px;
      height: 13vh;
    }
  }
`
