import React from 'react'
import {
  Swiper,
  SwiperSlide
} from 'swiper/react'

import {
  Navigation,
  Scrollbar,
  A11y
} from 'swiper'

import colors from 'theme/colors'

import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'

import ImageMask from '@/molecules/ImageMask'
import SliderNavigation from '@/molecules/Slider/Navigation'

import {
  StyledHeadquartersList,
  StyledHeadquartersItem,
  StyledHeadquartersListContainer,
} from './styles'

const HeadquartersList = ({ items }) => {
  const navPrevButton = React.useRef(null)
  const navNextButton = React.useRef(null)
  return (
    items &&
    !!items.length &&
    <StyledHeadquartersList>
      <StyledHeadquartersListContainer>
        <Swiper
          spaceBetween={32}
          slidesPerView="auto"
          centeredSlides={true}
          modules={[Navigation, Scrollbar, A11y]}
          scrollbar={{ draggable: true }}
          breakpoints={{
            720: {
              centeredSlides: false,
              slidesPerView: 3,
              spaceBetween: 0
            }
          }}
          navigation={{
            prevEl: navPrevButton.current,
            nextEl: navNextButton.current,
          }}
          onBeforeInit={(swiper)=> {
            swiper.params.navigation.prevEl = navPrevButton.current
            swiper.params.navigation.nextEl = navNextButton.current
            swiper.navigation.update()
          }}
        >
          {
            items.map(({
              title,
              description,
              image
            }) => (
              <SwiperSlide key={title}>
                <StyledHeadquartersItem >
                  <ImageMask
                    customClassName="Headquarters_image"
                    border
                    outerBorder
                    color={colors.grayscale.gray3}
                    image={ image }
                  />

                  <Label $title bold center>{title}</Label>
                  {
                    description &&
              <Richtext
                center
                typo="paragraph2">
                {description}
              </Richtext>
                  }

                </StyledHeadquartersItem>
              </SwiperSlide>
            ))
          }
          <SliderNavigation prevRef={navPrevButton} nextRef={navNextButton} />
        </Swiper>
      </StyledHeadquartersListContainer>
    </StyledHeadquartersList>
  )
}

export default HeadquartersList
