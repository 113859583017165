import Link from '@/atoms/Link'
import styled from 'styled-components'

export const StyledBackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.rem(10)};
`

export const StyledBackLink = styled(Link)`
  position: absolute;
  top: -${({ theme }) => theme.rem(80)};
`
