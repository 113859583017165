import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import ParagraphMolecule from '@/molecules/Paragraph'
import Form from '@/molecules/Form'

import { StyledParagraphForm } from './styles'

const ParagraphWithForm = ({
  heading,
  paragraphs,
  form
}) => (
  <SectionContainer heading={heading}>
    {
      paragraphs &&
      !!paragraphs.length &&
      paragraphs.map(paragraph => <ParagraphMolecule key={paragraph.title} {...paragraph} />)
    }

    {
      form &&
      <StyledParagraphForm>
        <Form form={form} />
      </StyledParagraphForm>
    }
  </SectionContainer>
)

export default ParagraphWithForm
