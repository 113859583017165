import styled from 'styled-components'
import styledMap from 'styled-map'

import colors from 'theme/colors'

export const StyledWithWave = styled.div`
  position: relative;
  overflow: hidden;
  height: ${styledMap`
    default: 100%;
    $fixed: 300px;
  `};
`
export const StyledWithWaveContent = styled.div`
position: relative;
${({ $fixed }) => $fixed && `
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  z-index:-1;
` }
  background-color: ${styledMap('template', {
    primary: 'transparent',
    secondary: colors.secondary.default
  })};
  ${({ theme }) => theme.size('100%')}

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const StyledWithWaveDecorator = styled.div`
  pointer-events: none;
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 0;
  ${({ theme }) => theme.size('100%')}

  .Drop{
    color: ${({ theme }) => theme.colors.grayscale.white()};
    margin-top: -60px;
  }
`
