import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Products from '@/molecules/Products'

const DopProducts = ({
  heading,
  products,
}) => (
  <SectionContainer heading={heading}>
    <Products items={products} />
  </SectionContainer>
)

export default DopProducts
