import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import HeadquartersList from '@/molecules/Headquarters'

const Headquarters = ({
  heading,
  headquarters,
}) => (
  <SectionContainer heading={heading}>
    <HeadquartersList items={headquarters} />
  </SectionContainer>
)

export default Headquarters
