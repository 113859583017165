import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Infographic from '@/molecules/Infographic'

const InfoBoxes = ({
  heading,
  ...props
}) => {
  return (
    <SectionContainer heading={heading}>
      <Infographic {...props} />
    </SectionContainer>
  )
}
export default InfoBoxes
