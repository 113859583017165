import React from 'react'
import * as Components from '@/organisms'

import Layout from '@/organisms/Layout'

import { Section } from '@/atoms/Layout'

const SectionBuilder = ({ data }) => (
  data ? (
    <Layout>
      {data.map((props, i) => {
        const Component = Components[props.type]
        const fallBack = process.env.NODE_ENV === 'development' ? props.type : <></>

        const section = props.type.toLowerCase()
        const sectionProps = {
          'data-section': section,
          'data-theme': props['data-theme'] || null
        }

        if (props.internalNavigation) {
          Object.assign(sectionProps, { 'data-target': props.target })
        }

        return (
          <Section
            key={`${section}_${i}`}
            {...sectionProps}>                
            {
              Component ?
                <Component {...props} />
                : fallBack
            }
          </Section>
        )
      })}
    </Layout>
  ) : null
)

export default SectionBuilder