import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import LinkList from '@/molecules/LinkList'

const List = ({
  heading,
  pages
}) => (

  <SectionContainer heading={heading}>
    <LinkList  items={pages}/>
  </SectionContainer>
)

export default List
