import styled from 'styled-components'
import { Label } from '@/atoms/Typography'
export const StyledBoxes = styled.div`
  margin-top: ${({ theme })=> theme.rem(114)};
  position: relative;

  /* &::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    ${({ theme }) => theme.size([2, 50])};
    border-left: 2px dotted ${({ theme }) => theme.colors.primary.default()};
  } */

  strong{
    padding-top: ${({ theme }) =>theme.rem(32)};
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.default}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(48)};

    // &::before{
    //   height: 100px;
    // }
  `)}
`

export const StyledBoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${({ theme })=> theme.rem(24)};
  row-gap: ${({ theme })=> theme.rem(32)};
  margin: ${({ theme })=> theme.rem(64)} auto;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
    margin: ${theme.rem(80)} auto ${theme.rem(112)};
    grid-template-columns: repeat(4, 1fr);
  `)}
`

export const StyledBox = styled.div`
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  .Box_frame{
    max-width: 150px;
    margin: 0 auto;
    filter: drop-shadow(5px 5px 5px rgba(138, 132, 132, 0.18));
  }

  ${Label}{
    color: ${({ theme }) => theme.colors.primary.default()};
    margin-top: ${({ theme })=> theme.rem(24)};
  }
`
