import styled from 'styled-components'


export const StyledCollaborationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  row-gap: ${({ theme }) => theme.rem(32)};
  column-gap: ${({ theme }) => theme.rem(16)};
  margin-top: ${({ theme }) => theme.rem(32)};
  background-color: ${({ theme }) => theme.colors.grayscale.white()};
  padding: ${({ theme }) => theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(64)};
    grid-template-columns: repeat(3,1fr);
    padding: ${theme.rem(64)};
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    grid-template-columns: repeat(5,1fr);
  `)}
`

export const StyledCollaboration = styled.div`
  img{
    display: table;
    margin: 0 auto;
    max-width: 120px;
    max-height: 180px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

