import React, { useEffect, } from 'react'
import { useField } from 'formik'

import CustomField from './index'

const Search = ({
  label,
  customOnChange = null,
  ...props
}) => {
  const [field, meta] = useField(props)

  useEffect(() => {
    if (!!meta.touched && !!meta.error) return
    if (
      // field.value.trim() !== '' &&
      customOnChange &&
      customOnChange instanceof Function
    ) customOnChange(field.value)
  }, [field.value, meta.error, meta.touched])


  const onKeyDown = e => {
    e.which === 13 && e.preventDefault()
  }

  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
    >
      <input
        autoFocus={true}
        id={field.name}
        onKeyDown={onKeyDown}
        {...field}
        {...props}
      />
    </CustomField>
  )
}

export default Search
