import React from 'react'

import SingleProduct from './SingleProduct'

import {
  StyledExportProducts,
  StyledExportProduct,
} from './styles'

const ExportProducts = ({ items }) => (
  items &&
  !!items.length &&
  <StyledExportProducts>
    {
      items.map((item, idx) => {
        return (
          <StyledExportProduct key={item} idx={idx}>
            <SingleProduct {...item} />
          </StyledExportProduct>
        )
      })
    }
  </StyledExportProducts>
)


export default ExportProducts
