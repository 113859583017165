import styled from 'styled-components'

export const StyledCards = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(50)};
  gap: ${({ theme }) => theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
  `)}
`

export const StyledCard = styled.div`
  display: inline-flex;
  align-items: flex-start;
  column-gap: ${({ theme }) => theme.rem(24)};

  strong{
    display: block;
    color: ${({ theme }) => theme.colors.secondary.default};
    margin-bottom: ${({ theme }) => theme.rem(8)};
  }

  .CardIcon{
    flex-shrink: 0;
    width: 70px;

    ${({ theme }) => theme.mediaQuery.md(`
      width: 100px;
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
      column-gap: ${theme.rem(32)};
  `)}
`

export const StyledCardContent = styled.div``
