import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import VideoInModal from '@/molecules/VideoInModal'
import Units from '@/molecules/Units'

const VideoWithUnitLogos = ({
  heading,
  units,
  video
}) => (
  <SectionContainer heading={heading}>
    {
      video &&
      <VideoInModal {...video} />
    }
    {
      units &&
      <Units className="VideoUnits" title={units.title} units={units.logos}/>
    }
  </SectionContainer>
)

export default VideoWithUnitLogos
