import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useField  } from 'formik'

import CustomField from './index'


// TODO: Try manual implementation
const RecaptchaField = ({
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers


  const onChange = (value) => {
    if (value) setValue(value)
  }

  const resetValue = () => {
    setValue('')
  }

  if (!process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT) return null

  return (
    <CustomField
      id={props.id}
      type={props.type}
      label={label}
      field={field}
      meta={meta}
    >
      <ReCAPTCHA
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT}
        onChange={onChange}
        onExpired={resetValue}
        onErrored={resetValue}
      />
    </CustomField>
  )
}

export default RecaptchaField
