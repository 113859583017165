import styled from 'styled-components'

export const StyledExportProducts = styled.div`
  ${({ theme }) => theme.mediaQuery.md(`
    margin-bottom:${theme.rem(160)};
  `)}
`
export const StyledExportProduct = styled.div`
  ${({ theme }) => theme.mediaQuery.lg(`
    display: grid;

    &:not(:first-child) {
      > div{
        width: 50%;
      }


      .Boxes_wrapper{
        ${theme.mediaQuery.md(theme.size('170%'))}
      }

      &:nth-child(even){
        > div{
          place-self: end;
        }
      }

      &:nth-child(odd){
        margin-top: ${theme.rem(-64)};
      }
    }
  `)}
`
