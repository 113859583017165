import React from 'react'
import dynamic from 'next/dynamic'

import colors from 'theme/colors'
import useIsMobile from 'hooks/useIsMobile'

import Highlighted from './Highlighted'

const dynamicOpts = { ssr: false }

const StandardModal = dynamic(() => import('@/molecules/Modals/StandardModal'), { ...dynamicOpts })

const MapModal = ({
  isOpen,
  handler,
  title,
  regionList,
  image,
  details,
}) => {
  const isMobile = useIsMobile()
  return (
    isMobile ?
      <StandardModal
        wrapperId='map-modal'
        isOpen={isOpen}
        hideCloseButton
        wrapperProps={{ style: { pointerEvents: isMobile ? 'auto' : 'none' } }}
        containerProps={{
          style: {
            height: '100%',
            with: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isMobile ? colors.primary.default(0.75) : 'transparent'
          }
        }}
      >
        <Highlighted
          title={title}
          regionList={regionList}
          details={details}
          image={image}
          handler={handler}
        />
      </StandardModal>
      :
      (
        isOpen &&
        <Highlighted
          isOpen={isOpen}
          isMobile={isMobile}
          title={title}
          regionList={regionList}
          details={details}
          image={image}
          handler={handler}
        />
      )

  )
}

export default MapModal
