import styled from 'styled-components'
import styledMap from 'styled-map'

import { labelButton } from '@/atoms/Typography'

export const StyledCta = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.rem(12)};
  border-radius: 80px;
  padding: ${({ theme }) => theme.rem(20)} ${({ theme }) => theme.rem(40)};
  background-color: ${styledMap`
    default: ${({ theme }) => theme.colors.primary.default()};
    $secondary: ${({ theme }) => theme.colors.grayscale.white()};
  `};
  color: ${styledMap`
    default: ${({ theme }) => theme.colors.grayscale.white()};
    $secondary: ${({ theme }) => theme.colors.secondary.default};
  `};

  border: ${styledMap`
    default: 1px solid ${({ theme }) => theme.colors.primary.default()};
    $secondary: 1px solid ${({ theme }) => theme.colors.secondary.default};
    $noBorder: none;
  `};



  &:not([disabled]):not(.no-hover),
  &:not([aria-disabled="true"]):not(.no-hover){
    transition: background-color ease-out 0.4s, border-color ease-out 0.4s, color ease-out 0.4s;
    // HOVER
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      &:hover {
        background-color: ${styledMap`
          default: ${({ theme }) => theme.colors.primary.dark1};
          $secondary: ${({ theme }) => theme.colors.secondary.default};
        `};
        color: ${styledMap`
          default: ${({ theme }) => theme.colors.grayscale.white()};
          $secondary: ${({ theme }) => theme.colors.grayscale.white()};
        `};
        border: ${styledMap`
          default: 1px solid ${({ theme }) => theme.colors.primary.dark1};
          $secondary: 1px solid ${({ theme }) => theme.colors.secondary.default};
        `};
      }
    }
  }


  &.no-hover{
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      &:hover {
        text-decoration: underline
      }
    }
  }

  a&{
    ${labelButton};
  }
`
