import React from 'react'

import { StyledLinkWithIcon } from './styles'

import Icon from '@/atoms/Icon'

const LinkWithIcon = (props) => {
  const {
    icon,
    children,
    right
  } = props
  return (
    <StyledLinkWithIcon
      {...props}
    >
      {
        icon &&
        !right &&
        <Icon icon={icon} />
      }
      {
        children
        && <span>{children}</span>
      }

      {
        icon &&
        right &&
        <Icon icon={icon} />
      }
    </StyledLinkWithIcon>
  )
}

export default LinkWithIcon
