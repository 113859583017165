import gsap from 'gsap'

const config = { duration: 0.4 }

const open = (refs, handleOnComplete) => {
  const {
    wrapper, header, content
  } = refs
  const height = header.current.offsetHeight + content.current.offsetHeight

  gsap.to(wrapper.current, {
    height: `${height}px`,
    duration: config.duration,
    onComplete: () => {
      wrapper.current.style.height = 'auto'
      handleOnComplete()
    }
  })
}

const close = (refs, handleOnComplete) => {
  const {
    wrapper, header
  } = refs
  const height = header.current.offsetHeight

  gsap.to(wrapper.current, {
    height: `${height}px`,
    duration: config.duration,
    onComplete: () => {
      handleOnComplete()
    }
  })
}

export {
  open,
  close
}
