import React from 'react'

import Icon from '@/atoms/Icon'
import CustomImg from '@/atoms/Image'

import {
  StyledOvalCard,
  StyledOvalCardContainer
} from './styles'

const OvalCard = ({
  children,
  image = null,
  ...rest
}) => (
  <StyledOvalCard
    {...rest}
  >
    <Icon unlockedProportions icon="oval-decoration" />

    {
      children &&
      <StyledOvalCardContainer>
        {children}
      </StyledOvalCardContainer>
    }
    {
      image &&
      <CustomImg className="OvalImage" {...image} />
    }
  </StyledOvalCard>
)


export default OvalCard
