import styled from 'styled-components'

export const StyledLocation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: auto;
  row-gap: ${({ theme })=> theme.rem(12)};
  width: 100%;

  .Location_icon{
    display: none;
    ${({ theme })=> theme.mediaQuery.md(`
      color: ${theme.colors.primary.default()};
      display: inherit;
      margin-bottom: ${theme.rem(12)};
    `)}
  }

  span{
    color: ${({ theme })=> theme.colors.primary.default()};
  }

  strong{
    font-family: ${({ theme })=> theme.fonts.title};
    color: ${({ theme })=> theme.colors.secondary.default};
  }

  .Location_description{
    ${({ theme }) => theme.typo('paragraph2')}
    color: ${({ theme })=> theme.colors.primary.default()};
  }
`
