import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Paragraph from '@/molecules/Paragraph'
import UnitsInfo from '@/molecules/UnitsInfo'

const ParagraphUnitInfo = ({
  heading,
  paragraph,
  unitInfo,
}) => (
  <SectionContainer heading={heading}>
    {
      !!paragraph &&
      <Paragraph {...paragraph}/>
    }
    <UnitsInfo units={unitInfo} />

  </SectionContainer>
)

export default ParagraphUnitInfo
