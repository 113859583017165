import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'

import CollaborationImages from '@/molecules/Collaboration'

const Collaboration = ({
  heading,
  images,
}) => (
  <SectionContainer heading={heading}>
    <CollaborationImages images={images} />
  </SectionContainer>
)

export default Collaboration
