import styled from 'styled-components'

import Link from '@/atoms/Link'

export const StyledLinkWithIcon = styled(Link)`
  border-radius: 80px;

  color: ${({ theme })=> theme.colors.primary.default()};
  font-family: ${({ theme }) => theme.fonts.title};

  ${({ theme }) => theme.typo('subheading2')}
  ${({ theme })=> theme.paddingY(8)}
  ${({ theme })=> theme.paddingX([8, 16])}

  &:hover{
    text-decoration: underline;
  }

  span{
    /* line-height: 100%; */
  }

  img{
    ${({ theme }) => theme.size(50)};
  }
`
