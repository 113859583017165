import styled from 'styled-components'

export const StyledPeopleList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.rem(32)};
  margin-top: ${({ theme }) => theme.rem(50)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(100)};
    grid-template-columns: repeat(4, 1fr);
  `)}
  ${({ theme }) => theme.mediaQuery.xl(`
    grid-template-columns: repeat(5, 1fr);
  `)}
`
