import styled from 'styled-components'
import { motion } from 'framer-motion'
const dottedLine = `
  background-image: linear-gradient(to right, #babec3 33%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`

export const StyledProducts = styled.div`
  ${({ theme }) => theme.mediaQuery.md(dottedLine)}
`

export const StyledProductsRow = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.rem(16)};
  padding-top: ${({ theme }) => theme.rem(64)};

  ${({ theme }) => theme.mediaQuery.md(`
    display: grid;
    grid-template-columns: repeat(5,1fr);
    position: relative;
    &::after{
      content: "";
      position: absolute;
      bottom: 55px;
      width: 100%;
      left: 0;
      ${dottedLine}
      height: 2px;
      z-index: -1;
    }
  `)}
`


export const StyledProduct = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(8)};
  flex-basis: 50%;
  order: 1;

  strong{
    color: ${({ theme }) => theme.colors.secondary.default};
    padding:${({ theme }) => theme.rem(8)};
  }

  img{
    max-width: 140px;
    width: 100%;
  }

  ${({ certificate }) =>certificate && `
    order: 0;
    flex-basis: 100%;
    align-items: flex-start;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      bottom: 55px;
      width: 100%;
      left: 0;
      ${dottedLine}
      height: 2px;
      z-index: -1;
    }
  `}

  ${({ theme }) =>  theme.mediaQuery.md(`
    flex-basis: 20%;
  `)}

  ${({
    certificate,
    theme
  }) => certificate && theme.mediaQuery.md(`
    align-items: center;
    order: 1;
    &::after{
      display: none;
    }

    img{
      transform: translate(25%,-50%);
    }
  `)}
`
