const Video = (video, rest) => {
  const {
    videoUrl,
    layout,
    imagePreview,
    provider
  } = video

  const preview = {
    square: layout === 'square',
    image: imagePreview
  }
  const videoProps = {
    title: rest.heading?.title,
    media: {
      data: {
        attributes: {
          src: videoUrl,
          provider,
        }
      }
    }
  }

  return {
    preview,
    video: videoProps
  }
}


export default Video
