import styled from 'styled-components'
import styledMap from 'styled-map'


export const StyledPersonContent = styled.button`
  display: block;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary.default()};
  color: ${({ theme }) => theme.colors.grayscale.white()};
  position:relative;

  span{
    display: block;
  }

  .Person_cta{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    i{
      font-size: 10px;
    }
  }

  .Person_name{
    padding-right: ${({ theme }) => theme.rem(14)};
    margin-bottom: ${({ theme }) => theme.rem(8)};
  }

  .Person_jobtitle{
    position: relative;
    padding-bottom: ${({ theme }) => theme.rem(20)};
    margin-bottom: ${({ theme }) => theme.rem(20)};

    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.grayscale.white()};
      ${({ theme }) => theme.size([78, 1])};
    }
  }
`
const bg = `
  background-image: url('/images/personWave.svg');
  background-size: auto 100%;
  background-position: bottom center;
  background-repeat-y: no-repeat;
  height: 23px;
  width: 100%;
  bottom: 98%;
  left: 0;
`

export const StyledPersonWave = styled.div`
  display: block;
  position: absolute;

  ${({ theme }) => styledMap('position', {
    top: bg,
    left: `
      ${bg};
      ${theme.mediaQuery.md(`
        background-image: url('/images/personWaveH.svg');
        background-position: top right;
        background-repeat-x: no-repeat;
        background-size: 100% auto;
        height: 100%;
        width: 23px;
        left: unset;
        bottom:unset;
        top: 0;
        right: 100%;
      `)}
    `
  })}
`


export const StyledPersonImage = styled.div`
  position: relative;
  z-index: 0;
  margin-bottom: ${({ theme }) => theme.rem(38)};

`

export const StyledPerson = styled.div`
  position: relative;
  display: flex;

  ${({
    preview,
    theme
  }) => preview && `

    ${StyledPersonContent}{
      cursor: pointer;
      position:absolute;
      padding: ${theme.rem(8)} ${theme.rem(16)} ${theme.rem(24)};
    }
  `}

  ${({
    preview,
    theme
  }) => !preview && `
    max-width: 335px;
    margin: 0 auto;
    flex-direction: column;

    ${StyledPersonImage}{
      margin-bottom: 0;
      width: 100%;
      ${theme.size('100%', true)};
      ${theme.mediaQuery.md(`
        ${theme.size(260, true)};
      `)}

      img{
        width: 100%;
      }
    }

    ${theme.mediaQuery.md(`
      max-width: 100%;
    `)}

  `}

  ${({
    theme,
    layout
  }) => theme.mediaQuery.md(`
    flex-direction: ${layout};
  `)}
`

export const StyledPersonContentClose = styled.button`
  display: block;
  position: absolute;
  cursor: pointer;
  border: none;
  margin: 0;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.primary.default()};
  padding:  ${({ theme }) => theme.rem(16)};
  z-index: 10;
  ${({ theme }) => theme.size(14)};

  &::before,
  &::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    ${({ theme }) => theme.size(['60%', 2])};
    background-color: ${({ theme }) => theme.colors.grayscale.white()};

    ${({ theme }) => theme.mediaQuery.md(`
      ${theme.size(['100%', 2])};
    `)}
  }

  &::before{
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after{
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  ${({ theme }) => theme.mediaQuery.md(`
    padding: 0;
    right: ${theme.rem(24)};
    top: ${theme.rem(24)};
    background-color: transparent;
  `)}
`
export const StyledPersonContentContainer = styled.div`
  padding: ${({ theme }) => theme.rem(32)};
  max-height: 300px;
  overflow: auto;

  ${({ theme }) => theme.mediaQuery.md(`
    max-height: 200px;
  `)}
`
