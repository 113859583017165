import React, {
  useRef, useMemo
} from 'react'

import { motion } from 'framer-motion'
import colors from 'theme/colors'
import useIsMobile from 'hooks/useIsMobile'
import { Label } from '@/atoms/Typography'
import Frame from '@/atoms/Frame'
import CustomImage from '@/atoms/Image'

import {
  StyledPercentItem,
  StyledPercentItemContent,
  StyledPercentItemDetails,
} from './styles'

const itemVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
}

const PercentItem = ({
  id,
  coordinates,
  image,
  excerpt,
  onMouseEnter,
  onMouseLeave,
}) => {
  const ref = useRef(null)
  const isMobile = useIsMobile()

  const onEnter = () => {
    if (!onMouseEnter) return
    onMouseEnter(id)
  }
  const onLeave = () => {
    if (!onMouseLeave) return
    onMouseLeave(null)
  }

  const itemHandlers = useMemo(() => {
    if (isMobile) return  { onClick: onEnter }
    return {
      onMouseEnter: onEnter,
      onMouseLeave: onLeave,
    }
  }, [isMobile, onEnter, onLeave])


  return (
    <StyledPercentItem
      ref={ref}
      {...itemHandlers}
      as={motion.div}
      variants={itemVariants}
      transition={{ duration: 0.6 }}
      style={{
        top: `${coordinates?.y}%`,
        left: `${coordinates?.x}%`,
      }}
    >

      <Frame
        color={colors.primary.default()}
        border
        outerBorder
      >
        <StyledPercentItemContent>
          <StyledPercentItemDetails>
            <Label
              typo="paragraph3" $
              title bold
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
            <CustomImage {...image} />
          </StyledPercentItemDetails>
          <Label className='DiscoverMore' typo="paragraph4">Scopri</Label>
        </StyledPercentItemContent>

      </Frame>
    </StyledPercentItem>
  )
}

export default PercentItem
