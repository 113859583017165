import styled from 'styled-components'

export const StyledAddresses = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(32)};
  margin-top: ${({ theme }) =>theme.rem(64)};

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: repeat(3, 1fr);
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    grid-template-columns: repeat(4, 1fr);
  `)}
`
