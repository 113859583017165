import React from 'react'
import colors from 'theme/colors'

import { Label } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import Icon from '@/atoms/Icon'
import Richtext from '@/atoms/RichText'
import ImageMask from '@/molecules/ImageMask'
import ImageFramed from '@/molecules/ImageFramed'


import {
  StyledCityContentStepHeader,
  StyledCity,
  StyledCityList,
  StyledCityInfo,
  StyledCityInfoContent,
} from './styles'


const CityContent = ({
  city,
  description,
  image,
  infos,
  onBack,
  showBack,
}) => (
  <>
    <StyledCityContentStepHeader>
      <Label className="Header_title" $title bold typo="subheading1">{city}</Label>
      {
        !!showBack &&
        <Button
          className="Back"
          onClick={onBack}>
          <Icon icon="arrow-left" />
        </Button>}
      <Richtext
        typo="paragraph3"
        className="Header_description"
        data-lenis-prevent
      >
        {description}
      </Richtext>
    </StyledCityContentStepHeader>
    <StyledCity>
      {
        image.data &&
        <ImageMask customClassName="City_preview" image={image}/>
      }

      {
        infos &&
          !!infos.length &&
          <StyledCityList data-lenis-prevent>
            {
              infos.map(({
                id,
                title,
                description: infoDescription,
                icon
              }) =>(
                <StyledCityInfo key={id}>
                  {
                    icon &&
                    icon.data &&
                    <ImageFramed
                      className="ImageIcon"
                      image={icon}
                      frameProps={{
                        border: true,
                        color: colors.secondary.default,
                        background: false,
                        backgroundColor: 'transparent'
                      }}
                    />
                  }
                  <StyledCityInfoContent>
                    <Label bold typo="paragraph3">{title}</Label>
                    <Richtext typo="paragraph3">
                      {infoDescription}
                    </Richtext>
                  </StyledCityInfoContent>
                </StyledCityInfo>
              ))
            }
          </StyledCityList>
      }


    </StyledCity>
  </>
)


export default CityContent
