import React, {
  useEffect,
  useState
} from 'react'
import { motion } from 'framer-motion'

import CircleBox from '@/molecules/CircleBox'
import { StyledCircleBoxes } from './styles'

import { degToRad } from 'theme/helpers'

const variants = {
  hidden: {},
  visible: {
    transition: {
      duration: 1,
      staggerChildren: 0.03
    }
  },
}

const CircleBoxes = ({
  containerRef,
  items,
  start = 'right',
  offset = 2,
  positions,
  columnDisposition = 0,
}) => {
  const [radius, setRadius] = useState(null)

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) setRadius(containerRef.current.clientWidth / 2)
    }

    window.addEventListener('resize', updateDimensions)
    updateDimensions()

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [containerRef])

  const dir = start === 'right' ? 1 : -1
  const itemsN = positions || items.length
  const stepInDeg = (360 / (itemsN + offset))
  const stepInRad = degToRad(stepInDeg)
  const offSetInRad = (stepInRad * offset)

  return (
    items &&
    <StyledCircleBoxes
      as={motion.div}
      initial="hidden"
      variants={variants}
      whileInView="visible"
      viewport={{ once: true }}
      columnDisposition={columnDisposition}>
      {
        items.map((box, i) => (
          <CircleBox
            className="CircleBox"
            index={i}
            key={box.id}
            {...box}
            distanceParams={{
              step: stepInRad,
              offset: offSetInRad,
              radius,
              dir: Number(dir)
            }}
          />))
      }
    </StyledCircleBoxes>
  )
}

export default CircleBoxes
