import React from 'react'

import { Label } from '@/atoms/Typography'

import { StyledTabsHeaderButton, } from './styles'

const Tab = ({
  id,
  onTabClick,
  selectedTab,
  title,
}) => {
  /* eslint eqeqeq: "off"*/
  return (
    <StyledTabsHeaderButton
      selected= {id == selectedTab}
      onClick={() => onTabClick(id)}
    >
      <Label as="span" typo="paragraph3">
        {title}
      </Label>
    </StyledTabsHeaderButton>
  )
}

export default Tab
