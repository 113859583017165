import styled from 'styled-components'

export const StyledEstablishmentModalContainer = styled.div`
  ${({ theme }) => theme.size(['90%', 'none'], true)};
  ${({ theme }) => theme.size('100%')};
  background-color: ${({ theme }) => theme.colors.background.beige};

  ${({ theme }) => theme.mediaQuery.md(`
    height: auto;
    ${theme.size([800, 'auto'], true)};
  `)}
`
