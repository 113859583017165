import { useEffect, useState } from "react";

import theme from 'theme/config'

export const useScreenDetector = (breakpoint = 'ml') => {
  const [isTablet, setIsTablet] = useState(undefined);

  const handleWindowSizeChange = () => {
    setIsTablet(window.innerWidth < theme.breakpoints[breakpoint] );
  };

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isTablet };
}