import React from 'react'

import { Label } from '@/atoms/Typography'
import Icon from '@/atoms/Icon'

import {
  StyledCircledLink,
  StyledCircledLinkContainer
} from './styles'

const CircledLink = ({
  size,
  link,
  label,
  icon,
  ...rest
}) => (
  <StyledCircledLink
    size={size}
    {...link}
    {...rest}
  >
    <StyledCircledLinkContainer>
      {
        label &&
        <Label as="strong" typo="link1">{label}</Label>
      }

      {
        icon &&
        <Icon icon={icon} />
      }
    </StyledCircledLinkContainer>


  </StyledCircledLink>
)

export default CircledLink
