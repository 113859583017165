import { css } from 'styled-components'

export const sliderCommon = css`
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  display: block;

  > div{
    width: 100%;
    padding-bottom: ${({ theme }) => theme.rem(32)};
    ${({ theme }) => theme.paddingX(16)};
    ${({ theme }) => theme.mediaQuery.md(theme.paddingX(16))}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    width: 100%;
    left: unset;
    transform: none;
  `)}


  .swiper-slide { width: fit-content }
  .swiper-button-next, .swiper-button-prev{
    --swiper-navigation-size: ${({ theme }) => theme.rem(16)};
    --swiper-navigation-color: ${({ theme }) => theme.colors.primary.default()};
    top: unset;
    bottom: 0;
  }
  .swiper-scrollbar{
    --swiper-scrollbar-sides-offset: ${({ theme }) => theme.rem(32)};
    --swiper-scrollbar-bottom: 6px;

    background: ${({ theme }) => theme.colors.primary.default(0.1)}
  }
  .swiper-scrollbar-drag{
    background: ${({ theme }) => theme.colors.primary.default()}
  }
`
