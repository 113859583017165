import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import WithWave from '@/molecules/WithWave'
import Attachments from '@/molecules/Attachments'
import LinkWithIcon from '@/molecules/LinkWithIcon'
import { Label } from '@/atoms/Typography'

import { StyledWave } from './styles'

const Wave = ({
  position,
  template,
  attachment,
  showDrop = false,
  cta
}) => (
  <WithWave
    id={template === 'secondary' ? 'bottomWave' : 'topWave'}
    position={position}
    template={template}
    withDrop={showDrop}
    limit={position === 'bottom' ? '100px' : '200px'}
  >
    {
      <SectionContainer style={{ margin: 0 }}>
        <StyledWave>
          {
            attachment &&
            <Attachments items={[attachment]} />
          }

          {
            cta &&
            <LinkWithIcon
              className="WaveLink"
              key={cta.externalUrl}
              href={cta.externalUrl}
              right
              icon={cta.icon}
            >
              <Label as="span" bold $title typo="h5" dangerouslySetInnerHTML={{ __html: cta.label }} />
            </LinkWithIcon>
          }
        </StyledWave>
      </SectionContainer>
    }

    {console.log('cta', cta)}


  </WithWave>
)

export default Wave
