import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import ProjectsPreview from '@/molecules/Projects/Preview'

const PreviewProjects = ({
  heading,
  projects
}) => {
  return (
    <SectionContainer heading={heading}>
      <ProjectsPreview items={projects}/>
    </SectionContainer>
  )
}

export default PreviewProjects
