import styled from 'styled-components'
import { motion } from 'framer-motion'
export const StyledLeadership = styled.div`
  margin-top: ${({ theme }) => theme.rem(48)};
`

export const StyledLeadershipGroup = styled.div`
  display: grid;
  grid-template-areas:
    "preview"
    "content";
  padding-bottom: ${({ theme }) => theme.rem(60)};

  ${({ theme }) => theme.mediaQuery.md(`
    padding-bottom: ${theme.rem(160)};
    grid-template-areas:  "preview content";
    grid-template-columns: 4fr 8fr;
  `)}

  ${({ theme }) => theme.mediaQuery.xl(`
    padding-bottom: 5.2083333333vw;
  `)};

  &:last-child{
    padding-bottom: 0;
  }
`

export const StyledLeadershipGroupPreview = styled.div`
  grid-area: preview;
  padding-right: ${({ theme }) => theme.rem(32)};

  img{
    max-width: 180px;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 210px;
    `)}
  }

  .LeadershipPreview_content{
    p{
      margin-block-start: ${({ theme }) => theme.rem(24)};
      ${({
    theme,
    withImage
  }) => withImage ? theme.typo('paragraph3') : theme.typo('subheading1')};


    ${({
    withImage,
    theme
  }) => !withImage && `
        text-transform: uppercase;
        color: ${theme.colors.secondary.default};
        font-weight: 700;
        font-family: ${theme.fonts.title}
      `}
    }

    strong{}

    i{
      color: ${({ theme }) => theme.colors.secondary.default};
    }
  }
`

export const StyledLeadershipGroupImages = styled.div`
  grid-area: content;

  background-image: linear-gradient(to right, #757d86 33%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;

  margin-top: ${({ theme }) => theme.rem(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(64)};
    padding-left: ${theme.rem(50)};
  `)}

  ${({ theme }) => theme.mediaQuery.lg(`
    padding-left: ${theme.rem(100)};
  `)}

  ${({ theme }) => theme.mediaQuery.xl(`
    margin-top: 3.125vw;
  `)}
`


export const StyledLeadershipGroupRow = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${({
    theme,
    length
  }) => theme.mediaQuery.lg(`
    grid-template-columns: ${length > 3 ? 'repeat(5, 1fr)' : 'repeat(3, 1fr)' }
  `)}
`

export const StyledLeadershipGroupLogo = styled(motion.div)`
  display: table;
  text-align: center;

  img{
    max-width: 100px;

    ${({ theme }) => theme.mediaQuery.md(`
    max-width: 65%;
    `)}
  }
`

export const  StyledLeadershipGroupLogoEl = styled.div``
