import React, {
  useEffect,
  useState,
  useRef
} from 'react'

import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
import CustomImage from '@/atoms/Image'
import Icon from '@/atoms/Icon'

import {
  StyledMapHighlighted,
  StyledMapHighlightedContainer,
  StyledMapHighlightedHeader,
  StyledMapHighlightedContent,
  StyledMapHighlightedClose,
} from './styles'

const Highlighted = ({
  handler,
  isMobile,
  regionList,
  title,
  image,
  details,
}) => {
  const ref = useRef()
  const [position, setPosition] = useState('top')

  useEffect(() => {
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      )
    }

    if (!isMobile) {
      const inViewport = isElementInViewport(ref.current)
      if (!inViewport && position === 'top') setPosition('bottom')
      if (!inViewport && position === 'bottom') setPosition('top')
    }
  }, [ref])

  return (
    <StyledMapHighlighted
      ref={ref}
      position={position}
    >
      <StyledMapHighlightedContainer>
        <StyledMapHighlightedHeader>
          <Richtext
            $title bold
            typo="paragraph1"
            style={{ gridArea: 'region' }}>{regionList}</Richtext>

          <Label
            $title bold
            typo="paragraph1"
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ gridArea: 'title' }} />

          <CustomImage style={{ gridArea: 'image' }} {...image}/>

          <StyledMapHighlightedClose
            onClick={handler}
            style={{ gridArea: 'image' } }
          >
            <Icon icon="close" useIconColor />
          </StyledMapHighlightedClose>
        </StyledMapHighlightedHeader>

        {
          details &&
          <StyledMapHighlightedContent>
            <Richtext
              typo="paragraph2"
            >
              {details}
            </Richtext>
          </StyledMapHighlightedContent>
        }

      </StyledMapHighlightedContainer>
    </StyledMapHighlighted>
  )
}

export default Highlighted
