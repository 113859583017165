import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import PeopleList from '@/molecules/People'

const People = ({
  heading,
  people,
}) => (
  <SectionContainer heading={heading}>
    <PeopleList people={people} />
  </SectionContainer>
)

export default People
