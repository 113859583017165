import React from 'react'

import StandardModal from '@/molecules/Modals/StandardModal'

import { StyledEstablishmentModalContainer } from './styles'

import colors from 'theme/colors'

const EstablishmentModal = ({
  isOpen,
  modalHandler,
  children
}) => (
  <StandardModal
    wrapperId='establishment-modal'
    isOpen={isOpen}
    setIsOpen={modalHandler}
    hideCloseButton
    wrapperProps={{
      style: {
        zIndex: 300,
        position: 'fixed',
        backgroundColor: colors.primary.default(0.75),
        display: 'flex',
        justifyContent: 'center',
      }
    }}
    containerProps={{
      style: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transformOrigin: 'center center',
      }
    }}
  >
    <StyledEstablishmentModalContainer data-lenis-prevent>
      {children}
    </StyledEstablishmentModalContainer>
  </StandardModal>
)

export default EstablishmentModal
