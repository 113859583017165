import styled from 'styled-components'

export const StyledMap = styled.div`
  position: relative;


  ${({
    full, theme
  }) => !!full && `
    width: 120vw;
    left: 50%;
    transform: translateX(-50%);

    ${theme.mediaQuery.md(`
      width: auto;
      left: unset;
      transform: none;
    `)}
  `}
  `

export const StyledMapContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  background-image: url('/images/grid.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

export const StyledMapImage = styled.div``

export const StyledMapItems = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.size('100%')}
`
