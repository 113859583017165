import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import Paragraph from '@/molecules/Paragraph'
import Cards from '@/molecules/Cards'

const ParagraphWithCards = ({
  heading,
  paragraph,
  cards,
}) => (
  <SectionContainer heading={heading}>
    {
      !!paragraph &&
      <Paragraph {...paragraph}/>
    }

    <Cards items={cards} />

  </SectionContainer>
)

export default ParagraphWithCards
