import styled from 'styled-components'
import Frame from '@/atoms/Frame'


export const StyledInfoGroup = styled.div`
  .InfoGroup_title{
    display: block;
    margin-bottom: ${({
    theme, withIcon
  }) => withIcon && theme.rem(60)};

    ${({
    theme, withIcon
  }) => withIcon &&  theme.mediaQuery.lg(`
      margin-bottom: ${theme.rem(76)};
    `)}
  }
`


export const StyledInfoGroupContainer = styled.div`
  flex-direction: column;

  ${({
    theme,
    withIcon
  }) => theme.mediaQuery.lg(`
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 8fr 2fr;
    column-gap: ${theme.rem(26)};
    ${!withIcon ? `
      column-gap: ${theme.rem(98)};
      grid-template-areas:
        "unit description icon"
        "space infos empty";
    `
    : `
      grid-template-areas:
        "unit description icon"
        "infos infos empty";
    `}
    row-gap: ${theme.rem(74)};
  `)}

  .InfoGroup_image{
    max-width: 195px;
    margin-bottom: ${({ theme }) => theme.rem(24)};

    ${({ theme }) => theme.mediaQuery.lg(`
      margin-bottom: 0;
    `)}
  }

  .InfoGroup_description{
  ${({
    theme,
    withIcon
  }) => withIcon && theme.mediaQuery.lg(`
      ${theme.paddingX(72)};
    `)}
  }
`

export const StyledInfoGroupDetails = styled.div`
  grid-area: infos;
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.rem(32)};
  position: relative;

  ${({
    withIcon, theme
  }) => !withIcon && `
    &::before{
      content: '';
      left: 0;
      width: 100%;
      height 2px;
      border-top: 2px dashed ${theme.colors.primary.default()};
      margin-top: ${theme.rem(32)};

      ${theme.mediaQuery.lg(`
        position: absolute;
        top: -30px;
        margin-top: 0;
      `)}
    }
  `}
`
export const   StyledInfoGroupDetail = styled.div`
  display: block;
  text-align: left;
  width: 100%;
  padding-right:  ${({ theme }) => theme.rem(16)};
  flex-grow: 1;
  flex-basis: 50%;

  ${({ withIcon }) => withIcon && `
    &:last-child{
      flex-grow: 2;
    }
  `}

  ${({
    theme, withIcon
  }) =>withIcon && theme.mediaQuery.lg(`
    flex-basis: 25%;
  `)}
  ${({
    theme, withIcon
  }) => !withIcon && theme.mediaQuery.xl(`
    flex-basis: 25%;
  `)}

  img{
    margin-bottom:  ${({ theme }) => theme.rem(30)};
  }

  .GroupDetail_description{
    color: ${({ theme }) => theme.colors.secondary.default};
  }
`

export const   StyledInfoGroupIcon = styled.div`
  position: relative;
  float: right;
  clear: both;

  div${Frame}{
    position: absolute;
    width: 180%;
    max-width: 180px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ theme }) => theme.mediaQuery.lg(`
      width: 140%;
    `)}
  }

  img{
    display: table;
    margin: 0 auto;
    max-width: 100px;

    ${({ theme }) => theme.mediaQuery.lg(`
      max-width: 120px;
    `)}
  }
`
