import React from 'react'
import { Label } from '@/atoms/Typography'

import Input from './Input'

import {
  StyledGroup,
  StyledGroupContainer
} from './styles'

const Group = ({
  label,
  items,
  dependsOn,
}) => {
  return (
    <StyledGroup>
      {
        label &&
        <Label as='strong' size="subheading2">{label}</Label>
      }
      <StyledGroupContainer>
        {
          items.map((item, index) => (<Input dependsOn={dependsOn} key={index} {...item}  />))
        }
      </StyledGroupContainer>
    </StyledGroup>
  )
}

export default Group
