import styled, { css } from 'styled-components'
import Button from '@/atoms/Button'

export const StyledAccordionList = styled.div`
margin-top:${({ theme }) => theme.rem(64)};
  max-width: 600px;
  width: 100%;
`

/**
 * Utils
 */
const preventChildrenFromBeingTabbed = css`
  visibility: hidden;
  opacity: 0;
  transition: all ${({ theme }) => theme.timing.fast} ease-in 0s;

  // Prevent children from being tabbed
  ${({ open }) => open && css`
    visibility: visible;
    opacity: 1;
  `}
`


/**
 * BASE
 */

export const BaseAccordion = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary.default()};
  height: ${({ theme }) => theme.rem(152)};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({
    theme, disabled
  }) => disabled && css`
    pointer-events: none;
    background-color: ${theme.colors.primary.light4};
    box-shadow: none!important;
    color: ${theme.colors.primary.light3};

    * {
      box-shadow: none!important;
      color: ${theme.colors.primary.light3};
    }
  `}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const BaseAccordionHeader = styled.div`
  height: ${({ theme }) => theme.rem(52)};
  background-color: ${({ theme }) => theme.colors.primary.default()};

  padding: ${({ theme }) => theme.rem(15)};
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;

  button{
    header > div{
      max-width: 100%;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  }
`

export const BaseAccordionHeaderContent = styled.div`

`

export const BaseAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(50, 60)};
  background-color: ${({ theme }) => theme.colors.primary.light6};
  ${preventChildrenFromBeingTabbed};

  strong {
    font-weight: 700;
  }

  a {
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.light4};
    }
  }
`

export const BaseAccordionIconLeft = styled.div`
  margin-right: ${({ theme }) => theme.rem(16)};

  i {
    font-size: ${({ theme }) => theme.rem(48)};
  }
`

export const BaseAccordionIconRight = styled.div`

`

export const BaseAccordionToggle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.rem(60)};
  transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

  i {
    font-size: ${({ theme }) => theme.rem(32)};
  }

  button {
    color: ${({ theme }) => theme.colors.primary.default()};
  }


  ${({ open }) => open && css`
    transform: translateY(-50%) scaleY(-1);
  `}
`

/**
 * FAQ
 */

export const FaqAccordion = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light4};
  height: ${({ theme }) => theme.rem(48)};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({
    theme, disabled
  }) => disabled && css`
    pointer-events: none;
    background-color: ${theme.colors.primary.light4};
    box-shadow: none!important;
    color: ${theme.colors.primary.light3};

    * {
      box-shadow: none!important;
      color: ${theme.colors.primary.light3};
    }
  `}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const FaqAccordionHeader = styled.div`
  height: ${({ theme }) => theme.rem(48)};
  padding: ${({ theme }) => theme.rem(0, 8)};
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  }
`

export const FaqAccordionHeaderContent = styled.div`

`

export const FaqAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(16, 56)};
  ${preventChildrenFromBeingTabbed};

  strong {
    font-weight: 700;
  }

  a {
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.light4};
    }
  }
`

export const FaqAccordionToggle = styled(Button)`
  width: ${({ theme }) => theme.rem(16)};
  height: ${({ theme }) => theme.rem(16)};
  margin-right: ${({ theme }) => theme.rem(32)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;

  span {
    width: ${({ theme }) => theme.rem(16)};
    height: ${({ theme }) => theme.rem(2)};
    border-radius: ${({ theme }) => theme.rem(2)};
    background-color: ${({ theme }) => theme.colors.primary.default()};
    display: block;
    pointer-events: none;

    &:first-child {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

      ${({ open }) => open && css`
        transform: translate(-50%, -50%) rotate(0deg);
      `}
    }
  }
`

/**
 * LINKS
 */

export const LinksAccordion = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light4};
  height: ${({ theme }) => theme.rem(48)};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({
    theme, disabled
  }) => disabled && css`
    pointer-events: none;
    box-shadow: none!important;
    color: ${theme.colors.primary.light3};

    * {
      box-shadow: none!important;
      color: ${theme.colors.primary.light3};
    }
  `}

  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.light4};
  }
`

export const LinksAccordionHeader = styled.div`
  height: ${({ theme }) => theme.rem(48)};
  display: flex;
  align-items: center;
  transition: background-color ${({ theme }) => theme.timing.fast} ease-in 0s;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.light6};
  }
`

export const LinksAccordionHeaderContent = styled.div`

`

export const LinksAccordionContent = styled.div`
  ${({ theme }) => theme.typo('paragraph1')};
  padding: ${({ theme }) => theme.rem(18, 0, 0, 16)};
  ${preventChildrenFromBeingTabbed};

  a {
    color: ${({ theme }) => theme.colors.primary.light3};
  }
`

export const LinksAccordionIconLeft = styled.div`
  margin-right: ${({ theme }) => theme.rem(16)};

  i {
    font-size: ${({ theme }) => theme.rem(48)};
  }
`

export const LinksAccordionIconRight = styled.div`

`

export const LinksAccordionToggle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.rem(4)};
  transition: transform ${({ theme }) => theme.timing.fast} ease-in 0s;

  i {
    font-size: ${({ theme }) => theme.rem(32)};
  }

  button {
    color: ${({ theme }) => theme.colors.primary.default()};
  }


  ${({ open }) => open && css`
    transform: translateY(-50%) scaleY(-1);
  `}
`


export const ParagraphsHeaderContent = styled(Button)`
  cursor: pointer;

  > span{
    text-transform: none;
  }

  .Heading_title{
    color: ${({ theme }) => theme.colors.grayscale.white()};
    ${({ theme }) => theme.typo('paragraph2')};
    font-weight: 500;
  }
`


export const ParagraphsContent = styled.div`
  ${preventChildrenFromBeingTabbed};
  padding: ${({ theme }) => theme.rem(40, 24)};
  background-color: ${({ theme }) => theme.colors.background.beige};

  strong {
    font-weight: 700;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary.default};
    &:focus,
    &:active:focus {
      box-shadow: 0 0 0 ${({ theme }) => theme.rem(4)} ${({ theme }) => theme.colors.secondary.default};
    }
  }

  strong{
    color: ${({ theme }) => theme.colors.secondary.default};
  }

  p{
    ${({ theme }) => theme.typo('paragraph3')}
  }

  .AccordionParagraphs{
    margin-block-start: 0;
    margin-block-end: ${({ theme }) => theme.rem(32)};
    display: block;
    > div{

      max-width: 100%;
    }
  }

  .Paragraph_heading{
    margin-bottom: ${({ theme }) => theme.rem(8)};
    ${({ theme }) => theme.typo('subheading2')}
  }
`
