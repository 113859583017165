import styled from 'styled-components'

export const StyledEstablishment = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

const common = (theme) => `
flex-basis: 100%;

  ${theme.mediaQuery.lg(`
    flex-basis: 50%;
  `)}
`
export const StyledEstablishmentContent = styled.main`
  ${({ theme }) => common(theme)}
  padding-bottom: ${({ theme }) => theme.rem(32)};

  header > div{
    max-width: 100%;
  }

  .Establishment_paragraph{
    margin-block-start: 0;
    > .Paragraph_content{
      max-width: 100%;
    }
  }

  ${({ theme }) => theme.mediaQuery.lg(`
    padding-bottom: 0;
    padding-right: ${theme.rem(32)};
  `)};

`
export const StyledEstablishmentMap = styled.aside`
  ${({ theme }) => common(theme)}

`
