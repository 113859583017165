import styled from 'styled-components'

export const StyledNavButton = styled.button`
  position: absolute;
  flex-shrink: 0;
  bottom: -4px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 10;
  ${({ left }) => left && 'left: 0;'}
  ${({ right }) => right && 'right: 0;'}
  ${({
    isRound, theme, left
  }) =>
    isRound &&
    `
    &.roundPrev {
      bottom: 45%;
      left: -8rem;
    }

    &.roundNext {
      bottom: 45%;
      right: -8rem;
    }

    background-color: ${theme.colors.primary.default() };
    width: ${ theme.rem(123) };
    height: ${ theme.rem(123) };
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    > i {
      rotate: ${ left ? '180deg' : '0deg' };
      > svg {
      scale: 2.5;
      view-box: 0 0 45 45;
      > path  {
        fill: ${ theme.colors.grayscale.white()};
      }
    }
  }
    `}
`
