import styled from 'styled-components'

export const StyledAttitudes = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.rem(32)};
  margin-bottom: ${({ theme }) => theme.rem(60)};


  ${({ theme })=> theme.mediaQuery.md(`
    margin-bottom: ${theme.rem(120)};
    display: grid;
    align-items: start;
    padding-top: ${theme.rem(192)};
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));


    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-right: 4px dotted #62b4e5;
      width: 1px;
      height: ${theme.rem(192)};
    }
  `)}
`

export const StyledAttitude = styled.div`
  padding: ${({ theme }) => theme.rem(16)};
  position: relative;
  place-self: center;
  width: 100%;

  .Attitude_text{
    color: ${({ theme }) => theme.colors.secondary.default};
  }

  .Attitude_image{
    display: table;
    margin: 0 auto;
    margin-top: ${({ theme }) => theme.rem(32)};
    max-width: 280px;
    width: 100%;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 350px;
      margin-top: ${theme.rem(46)};
    `)}
  }

  ${({ theme }) => theme.mediaQuery.md(`
    padding: 0 ${theme.rem(16)};
    place-self: start;
    justify-self: center;

    &:nth-child(even):not(:last-child){
      padding-top: ${theme.rem(64)};

      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-top: 4px dotted #62b4e5;
        height: 1px;
        width: 100%;
      }
    }
  `)}
`
