import styled from 'styled-components'
import { Label } from '@/atoms/Typography'
import Richtext from '@/atoms/RichText'
export const StyledMapHighlighted = styled.div`
  max-width: 320px;
  /* min-height: 180px; */
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayscale.white()};
  padding: ${({ theme }) => theme.rem(24)};
  box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.1);

  ${({
    theme, position
  }) => theme.mediaQuery.md(`
    max-width: 360px;
    position: absolute;
    transform: none;
    z-index: 1;
    ${position === 'top' ? `
      top: 0;
      right: 0;
      bottom: unset;
      left: unset;
    ` : ''}
    ${position === 'bottom' ? `
      top: unset;
      right: unset;
      bottom: 20%;
      left: 0;
    ` : ''}
  `)}
`


export const StyledMapHighlightedContainer = styled.div`
  position: relative;
  z-index: 1;
`

export const StyledMapHighlightedHeader = styled.header`
  display: grid;
  grid-template-areas:
    "region image"
    "title image";
  grid-template-columns: 11fr 1fr;
  position: relative;
  row-gap: ${({ theme }) => theme.rem(4)};

  div${Richtext}{
    color: ${({ theme }) => theme.colors.primary.default()};
    margin-bottom: ${({ theme }) => theme.rem(4)};
  }

  div${Label}{
    color: ${({ theme }) => theme.colors.secondary.default}
  }
  img{
    display: none;
    ${({ theme }) => theme.mediaQuery.md(`
      display: inline-block;
    `)}
  }
`

export const StyledMapHighlightedContent = styled.main`
  display: block;
  margin-top: ${({ theme }) => theme.rem(16)};
`

export const StyledMapHighlightedClose = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  align-self: start;
  justify-self: flex-end;
  ${({ theme }) => theme.mediaQuery.md(`
    display: none;
  `)}
`
