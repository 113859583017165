import styled from 'styled-components'


export const StyledTabsHeader = styled.header`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overscroll-behavior: contain;
  &::-webkit-scrollbar { height: 1px; }
  column-gap: ${({ theme })=> theme.rem(16)};
  /* &::-webkit-scrollbar-track { background: $white }

  &::-webkit-scrollbar-thumb { background-color: $secondary } */
`
export const StyledTabs = styled.div`
  width: 100%;

  ${({
    full,
    theme
  })=> full && `
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    ${StyledTabsHeader}{
      padding: 0 24px;
    }

    ${theme.mediaQuery.md(`
      width: 100%;
      transform:none;
      left: unset;

      ${StyledTabsHeader}{
        padding: 0;
      }
    `)}
  `}


`

export const StyledTabsHeaderButton = styled.button`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: background-color ease-in-out .4s, color ease-in-out .4s, border-color ease-in-out .4s;
  border-radius: 32px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary.default()};
  color: ${({
    theme,
    selected
  }) => selected ? theme.colors.grayscale.white() : theme.colors.primary.default()};
  background-color: ${({
    theme,
    selected
  }) => selected ? theme.colors.primary.default() : theme.colors.background.beige};
  ${({ theme }) => theme.paddingY(10)};
  ${({ theme }) => theme.paddingX(16)};

  &:hover{
    color: ${({ theme }) => theme.colors.grayscale.white()};
    background-color: ${({ theme }) => theme.colors.primary.default()};
  }

`

export const StyledTabsContent = styled.main`
  margin-top: ${({ theme })=> theme.rem(72)};
`
