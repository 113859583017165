import React from 'react'

import { Container } from '@/atoms/Layout/Grid'
import WithHeading from '@/molecules/WithHeading'
import Paragraph from '@/molecules/Paragraph'
import RegionList from '@/molecules/RegionList'

import {
  StyledEstablishment,
  StyledEstablishmentContent,
  StyledEstablishmentMap,
} from './styles'

const Establishment = ({
  heading,
  paragraph,
  establishments,
}) => (
  <Container>
    <StyledEstablishment>
      <StyledEstablishmentContent>
        {
          heading &&
        <WithHeading heading={heading} />
        }
        <Paragraph className="Establishment_paragraph" {...paragraph} />
      </StyledEstablishmentContent>
      <StyledEstablishmentMap>
        <RegionList items={establishments}/>
      </StyledEstablishmentMap>
    </StyledEstablishment>
  </Container>
)

export default Establishment
