import SectionContainer from '@/molecules/SectionContainer'

import { default as BackComponent } from '@/molecules/Back'

const Back = ({
  title, href
}) => {
  return (
    <SectionContainer style={ { marginBlock: 'unset' }}>
      <BackComponent title={title} href={href} />
    </SectionContainer>
  )
}

export default Back
