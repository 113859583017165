import styled from 'styled-components'

export const StyledInfos = styled.div`
  margin-top: ${({ theme }) => theme.rem(60)};
  ${({ theme }) => theme.mediaQuery.md(`
    margin-top: ${theme.rem(120)};
  `)};

  .Heading_title span{
    margin: 0 auto;
    text-align: center;
  }

  .Heading_subtitle{
    margin: ${({ theme }) => theme.rem(16)} auto 0;
    text-align: center;
    font-weight: 700;
    max-width: 100%;
    font-family: ${({ theme }) => theme.fonts.title};
    text-transform: uppercase;
    ${({ theme }) => theme.typo('subheading1')};
  }
`
export const StyledInfosMain = styled.main`
  > div {
    margin-block-start: ${({ theme }) => theme.rem(32)};
  }
`
