import styled from 'styled-components'

export const StyledBalanceList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(64)};
  flex-direction: column;
  ${({ theme }) => theme.mediaQuery.md(`
    flex-direction: row;
  `)}
`
export const StyledBalance = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md(`
  max-width: 50%;
    flex-direction: row;
  `)}
  > * + *{
    margin-block-end: ${({ theme }) => theme.rem(32)};
  }

  h4{
    color: ${({ theme }) => theme.colors.secondary.default};
    margin-bottom: ${({ theme }) => theme.rem(32)};
  }

  img{
    display: table;
    margin-left: auto;
    margin-right: auto;

    max-width: 280px;
  }

  .Balance_download{
    display: flex;
    margin: 0 auto;
    flex-shrink: 0;
    ${({ theme }) => theme.size(230, true)};
  }
`
