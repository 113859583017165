import * as Models from 'models'

export const checkHomepageSlug = slug => ['home', 'homepage'].includes(slug)


export const generateSlug = (attributes, generateArraySlug = false) => {
  if (attributes.homepage || !generateArraySlug && checkHomepageSlug(attributes.slug)) return false

  if (!attributes.slug) return []

  if (!!attributes.parent) return [attributes.parent, ...[...attributes.slug.split('/')]]

  return [...attributes.slug.split('/')]
}


export const generateHref = (attributes) => {
  const slugFormatted = generateSlug(attributes)
  if (!slugFormatted) return '/'
  return `/${slugFormatted.join('/')}`
}


export const normalizeData = async (dataToNormalize = {}, additionalData, isHomepage) => {
  if (isHomepage) return [await Models.Homepage(dataToNormalize)]
  const data = []

  for (const section of dataToNormalize.sections) {
    let _section = section

    if (section.internalNavigation) {
      _section.target = String(section.internalNavigation).toLocaleLowerCase().replace(/\s+/g, '-')
    }

    const type = section.type ? section.type.replace(/-/g, '') : null

    if (Models[type]) {
      _section = await Models[type](_section, additionalData)
    }
    data.push(_section)
  }

  return data
}

export const normalizeMetadata = async (data, options = {}) => {
  const metadata = data.seo || {}
  metadata.defaultSeo = {}
  metadata.defaultSeo.title = `${options.metaDefaultTitle ? options.metaDefaultTitle + ' | ' : ''}${metadata.metaTitle || data.title}`
  metadata.defaultSeo.description = metadata.metaDescription || data.excerpt || options.metaDefaultDescription || ''
  metadata.defaultSeo.image = metadata.metaImage || {}

  return metadata
}


export const normalizeAdditionalData = async (data) => {
  if (!data) return {}
  const {
    slug,
    withNavigation = false,
    showFooter = true,
    title,
  } = data.attributes

  return {
    slug,
    withNavigation,
    showFooter,
    title,
  }
}

const getPageLink = ({ data }, currentPage) => {
  const {
    title,
    slug
  } = data.attributes

  const $isCurrentPage = !!currentPage && currentPage === slug
  const href = generateHref(data.attributes)

  return {
    label: title,
    href,
    slug,
    $isCurrentPage,
  }
}

export const normalizeMenuItem = ({
  url,
  label,
  page = null
}, currentPage) => {
  const isInternal = !!page

  const link = isInternal
    ? getPageLink(page, currentPage)
    : {
      href: url,
      label,
      target: '_blank'
    }

  return link
}
