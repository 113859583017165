import React from 'react'
import {
  LinksAccordion,
  LinksAccordionHeader,
  LinksAccordionContent,
  LinksAccordionHeaderContent,
  LinksAccordionToggle
} from './styles.js'

import Button from '@/atoms/Button'
import { Paragraph } from '@/atoms/Typography'
import { Spacer } from '@/atoms/Layout'

const Links = ({
  refs, accordion, tabIndex, disabled, title, links, handleClick, passedKey
}) => {
  return (
    <LinksAccordion tabIndex={tabIndex} ref={refs.wrapper} disabled={disabled} key={passedKey} aria-expanded={accordion.open}>
      <LinksAccordionHeader
        ref={refs.header}
        open={accordion.open}
      >
        <LinksAccordionHeaderContent>
          <Paragraph typo={'paragraph2'} bold>
            { title }
          </Paragraph>
        </LinksAccordionHeaderContent>
        <LinksAccordionToggle open={accordion.open}>
          <Button iconStart={'chevron-down'} tertiary handleClick={() => handleClick(accordion)}/>
        </LinksAccordionToggle>
      </LinksAccordionHeader>
      <LinksAccordionContent
        ref={refs.content}
        open={accordion.open}
        role={'region'}
      >
        { links ? links.map(({
          label, url
        }, index) => {
          return (
            <>
              <Button quaternary label={label} href={url} key={index} />
              <Spacer xs={2} />
            </>
          )
        }) : null}
      </LinksAccordionContent>
    </LinksAccordion>
  )
}

export default Links
