import React from 'react'

import Wave from '@/molecules/Wave'
import Drop from '@/atoms/Drop'
import {
  StyledWithWave,
  StyledWithWaveContent,
  StyledWithWaveDecorator,
} from './styles'

const WithWave = ({
  children,
  id,
  position,
  withDrop = false,
  $fixed = false,
  template = 'primary',
  limit
}) => {
  return (
    <StyledWithWave
      position={position}
      className='WithWave'
      $fixed={$fixed}
    >
      <StyledWithWaveDecorator>
        <Wave id={id} position={position} template={template} limit={limit} />
        {
          withDrop &&
          <Drop animated={false} {...withDrop}/>
        }
      </StyledWithWaveDecorator>

      {
        children &&
        <StyledWithWaveContent template={template} $fixed={$fixed}>
          {children}
        </StyledWithWaveContent>
      }

    </StyledWithWave>
  )
}

export default WithWave
