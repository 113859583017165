import styled from 'styled-components'


export const StyledStandardModal = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  height: 100%;
  width: 100%;
  opacity: 0;

  #establishment-modal &{
    overflow: auto;
    align-items: flex-start;

    ${({ theme }) => theme.mediaQuery.md(`
    align-items: center;
      overflow: hidden;
    `)}
  }
`

export const StyledStandardModalContainer = styled.div`

  #establishment-modal &{
    height: auto;
    ${({ theme }) => theme.paddingY(100)};
    ${({ theme }) => theme.mediaQuery.md(`
    height: 100%;
    ${theme.paddingY(0)};
    `)}
  }`

export const StyledStandardModalClose = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grayscale.white()};
  text-transform: uppercase;
  margin-left: auto;
  margin-bottom: ${({ theme }) => theme.rem(16)};
  border: none;
`
