import React, { useState } from 'react'

import { Label } from '@/atoms/Typography'
import Button from '@/atoms/Button'
import Icon from '@/atoms/Icon'

import ImageMask from '@/molecules/ImageMask'
import VideoModal from '@/molecules/Modals/Video'

import {
  StyledVideoWithModal,
  StyledVideoCta
} from './styles'

const VideoWithModal = ({
  video,
  preview = {},
  className = null
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onButtonClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <StyledVideoWithModal className={className}>
      <Button onClick={onButtonClick}>
        <ImageMask {...preview}/>
        <StyledVideoCta>
          <Icon icon="play" />
          <Label as="span" typo="link3">Guarda il<br/> video</Label>
        </StyledVideoCta>
      </Button>
      <VideoModal video={video} isOpen={isOpen} modalHandler={onButtonClick} hideCloseButton/>
    </StyledVideoWithModal>
  )
}

export default VideoWithModal
