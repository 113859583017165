import React, { useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  Formik,
  Form
} from 'formik'

import { fetchData } from 'api'

import RichText from '@/atoms/RichText'
import Button from '@/atoms/Button'
import Icon from '@/atoms/Icon'

import Fieldset from './Fieldset'

import {
  StyledFormSubmit,
  StyledFormFooter
} from './styles'

const FormModal = dynamic(() => import('@/molecules/Modals/FormModal'), { ssr: false })

const CustomForm = ({
  endpoint,
  successMessage,
  initialValues,
  fieldsets = [],
  validationSchema = {},
  validateOnMount = false,
  submitButton = null,
  onSubmit = null,
  className = null,
  settings = null,
  method,
  footer,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    locale,
    query
  } = useRouter()

  const getErrors = (errors) => {
    const { details: { errors: errorsArray }  } = errors
    return errorsArray.reduce((acc, curr) => {
      const {
        path, message
      } = curr

      acc[path[0]] = message
      return acc
    }, {})
  }

  return (
    <>
      <Formik
        validateOnMount={validateOnMount}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values, {
          resetForm,
          setErrors,
        }) => {
          if (endpoint) {
            const urlEndpoint = new URL(endpoint)
            urlEndpoint.searchParams.set('locale', locale)

            const formData = new FormData()

            for (const val in values) {
              formData.append(val, values[val])
            }

            if (!!Object.keys(query).length) {
              for (const params in query) {
                formData.append(params, query[params])
              }
            }

            const response = await fetchData(urlEndpoint, {
              method,
              body: formData
            })

            if (response.error) {
              const errors = await getErrors(response.error)
              await setErrors(errors)
              return
            }
          }

          await resetForm()
          setIsOpen(true)

          if (onSubmit) await onSubmit(values)
        }}
      >
        {({
          isValid,
          isSubmitting,
          errors
        }) => (
          <Form className={className}>
            {
              fieldsets &&
              !!fieldsets.length &&
              fieldsets.map((fieldset, idx) => <Fieldset key={idx} {...fieldset} />)
            }

            {
              footer &&
              footer.length &&
              <StyledFormFooter>
                {
                  footer.map(item => (
                    <RichText key={item}>
                      {item}
                    </RichText>
                  ))
                }
              </StyledFormFooter>
            }
            {
              submitButton &&
              <StyledFormSubmit>
                <Button
                  className="FormSubmit"
                  as="Button"
                  type="submit"
                  disabled={!isValid || !!Object.entries(errors).length || isSubmitting}
                  {...submitButton}
                />
              </StyledFormSubmit>
            }
          </Form>
        )}
      </Formik>

      {
        settings &&
        !settings.hideSuccessMessage &&
        <FormModal
          isOpen={isOpen}
          modalHandler={() => setIsOpen(false)}
        >
          <Icon useIconColor icon="form-answer"/>
          <RichText
            className="SuccessMessage"
          >{successMessage}</RichText>
          <Button
            onClick={() => setIsOpen(false)}
          > chiudi </Button>
        </FormModal>
      }

    </>
  )
}

export default CustomForm
