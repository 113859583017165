import Frame from '@/atoms/Frame'
import CustomImage from '@/atoms/Image'
import CircleBoxes from '@/molecules/CircleBoxes'
import ImageFramed from '@/molecules/ImageFramed'
import {
  useMemo, useRef
} from 'react'

import Richtext from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'
import {
  StyledAdditionalInfo,
  StyledAdditionalInfos,
  StyledSingleProduct,
  StyledSingleProductAdditionalInfo,
  StyledSingleProductAdditionalInfoContent,
  StyledSingleProductBoxes,
  StyledSingleProductImage,
  StyledSingleProductLogo,
  StyledSingleProductMain,
} from './styles'

const SingleProduct = ({
  additionalInfo,
  info,
  logo,
  product,
}) => {
  const ref = useRef(null)
  const gotProduct = !!product.data
  const imageFrameProps = {
    frameProps: {
      border: !gotProduct,
      background: gotProduct ? 'radial-gradient(#fff, #d6dbe2)' : false,
      backgroundColor: 'transparent'
    }
  }
  const Wrapper = ({
    children,
    ...rest
  }) => gotProduct ? <Frame className='Product_frame' {...rest}>{children}</Frame> : <>{children}</>

  const circleBoxesParams = useMemo(() => {
    return info.length <= 2
      ? {
        positions: 4, offset: 3,
      } : {
        positions: 6, offset: 3,
      }
  }, [info])

  console.log('info', info)

  return (
    <StyledSingleProduct>
      {
        gotProduct &&
        logo.data &&
        <StyledSingleProductLogo>
          <CustomImage className="SingleProduct_logo"  {...logo} />
        </StyledSingleProductLogo>
      }
      <StyledSingleProductMain>
        <StyledSingleProductImage>
          <Wrapper border outerBorder className="Product_image">
            <ImageFramed
              image={gotProduct ? product : logo}
              {...imageFrameProps}
            />
          </Wrapper>

          {
            info &&
            !!info.length &&
            <StyledSingleProductBoxes
              items={info.length}
              className='Boxes_wrapper' ref={ref}>
              <CircleBoxes
                containerRef={ref}
                items={info}
                columnDisposition={2}
                {...circleBoxesParams}
              />
            </StyledSingleProductBoxes>
          }


        </StyledSingleProductImage>
      </StyledSingleProductMain>

      {
        additionalInfo &&
        <StyledSingleProductAdditionalInfo>
          {
            additionalInfo.image &&
            additionalInfo.image.data &&
            <CustomImage {...additionalInfo.image} />
          }

          <StyledSingleProductAdditionalInfoContent>
            {
              additionalInfo.title &&
              <Label typo="h5" $title left bold dangerouslySetInnerHTML={{ __html: additionalInfo.title }} />
            }
            {
              additionalInfo.info &&
              !!additionalInfo.info.length &&
              <StyledAdditionalInfos>
                {
                  additionalInfo.info.map(({
                    title,
                    description
                  }) => (
                    <StyledAdditionalInfo key={title}>
                      <Label
                        as="strong"
                        typo="h5"
                        $title
                        bold
                      >
                        {title}
                      </Label>
                      <Richtext>{description}</Richtext>
                    </StyledAdditionalInfo>
                  ))
                }
              </StyledAdditionalInfos>
            }
          </StyledSingleProductAdditionalInfoContent>
        </StyledSingleProductAdditionalInfo>
      }
    </StyledSingleProduct>
  )
}

export default SingleProduct
