import styled from 'styled-components'

export const StyledAttachments = styled.div`
  margin-top: ${({ theme }) => theme.rem(40)};
 > * + * {
    margin-block-start: ${({ theme })=> theme.rem(40)};
  }

`
export const StyledAttachment = styled.div`
  a{
    display: flex;
    align-items: center;
    text-align: right;
    font-weight: bold;
    ${({ theme }) => theme.typo('link2')};
    column-gap: ${({ theme }) => theme.rem(24)};
    color: ${({ theme })=> theme.colors.secondary.default};

    &:hover{
      text-decoration: underline;
    }

    i{
      border-radius: 50%;
      border: 1px solid ${({ theme })=> theme.colors.secondary.default};
      font-size:  ${({ theme }) => theme.rem(40)};

      svg{
        max-width: 40%;
        display: table;
        margin: 0 auto;
      }
    }
  }
`
