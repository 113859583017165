import React, { useState } from 'react'

import Tabs from '@/atoms/Tabs'
import Content from './Content'

const Storyline = ({
  header,
  content
}) => {
  const [current, setCurrent] = useState(content[0].id)
  return (
    <Tabs
      full
      header={header}
      selectedTab={current}
      onTabClick={setCurrent}
    >
      <Content
        current={current}
        items={content}
      />
    </Tabs>
  )
}

export default Storyline
