import React from 'react'

import InfoGroup from './InfoGroup'

import { StyledUnitsInfo } from './styles'

const UnitsInfo = ({ units }) => {
  return (
    units &&
    !!units.length &&
    <StyledUnitsInfo>
      {
        units.map(unit => <InfoGroup key={unit.id + unit.description} {...unit}/>)
      }
    </StyledUnitsInfo>
  )
}

export default UnitsInfo
