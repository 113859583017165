import styled from 'styled-components'
import styledMap from 'styled-map'

export const StyledCircleBoxes = styled.div`
display: grid;
align-items: start;
justify-items: center;
z-index: -1;
row-gap: ${({ theme }) => theme.rem(36)};

${styledMap('columnDisposition', {
    1: 'grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));',
    3: 'grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));',
    4: 'grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));',
    2: 'grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));',
  })};


${({ theme }) => theme.mediaQuery.md(`
  display: block;
  aspect-ratio: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`)}
`
