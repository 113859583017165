import styled from 'styled-components'

export const StyledDrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 40px;
  margin: 0 auto;

  svg{
    height: auto;
    flex-shrink: 0;
    position: relative;
  }

  #Drop_top{
    max-width: 20px;
    width: 100%;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 44px;
    `)}
  }

  #Drop_bottom{
    max-width: 28px;
    width: 100%;
    bottom: 2px;

    ${({ theme }) => theme.mediaQuery.md(`
      max-width: 39px;
    `)}
  }

  #Drop_body{
    background-color: currentColor;
    height: 170px;
    width: 2px;
    position: relative;
    z-index: 2;

    ${({ theme }) => theme.mediaQuery.md(`
      width: 3px;
    `)}

  }
`
