import styled from 'styled-components'

export const StyledCircleBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 1.25rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;

  ${({ theme }) =>
    theme.mediaQuery.sm(`
    column-gap: ${theme.rem(72)};
    justify-content: space-evenly;
    width: fit-content;
    flex-wrap: nowrap;
  `)}
`
