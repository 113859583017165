import React from 'react'

import SectionContainer from '@/molecules/SectionContainer'
import WithWave from '@/molecules/WithWave'
import NextPageLink from '@/molecules/NextPageLink'

import CustomImage from '@/atoms/Image'

import { StyledNextPage } from './styles'

const NextPage = ({
  label,
  image,
  nextPage: nextPageProps
}) => {
  return (
    <SectionContainer
      style={{
        marginBottom: 0,
        paddingBottom: 0,
        overflowX: 'hidden'
      }}
      $fluid
    >
      <StyledNextPage>
        <NextPageLink {...nextPageProps} label={label}>
          <WithWave
            template='transparent'
            className="WithWave"
            id="nextPageWave"
            position="top"
            limit="100px"
            withDrop={{ hideBase: true }}
            $fixed
          >
            <CustomImage {...image} />
          </WithWave>
        </NextPageLink>
      </StyledNextPage>
    </SectionContainer>
  )
}


export default NextPage
