import React from 'react'
import DefaultLayout from 'layouts/default'
import SectionBuilder from '@/organisms/SectionBuilder'

import {
  writeFile,
  readFile
} from 'utils/fs-helpers'

import {
  getPageData,
  getEntity,
  getOptions,
  getMenus
} from 'api'

import {
  generateSlug,
  normalizeData,
  normalizeMetadata,
  normalizeAdditionalData,
  checkHomepageSlug,
} from 'api/utils'

import { default as normalizeFooterData } from 'models/Footer'
import { default as normalizeHeaderData } from 'models/Header'
import { default as normalizeLateralNavigationData } from 'models/LateralNavigation'

export const getStaticPaths = async () => {
  const entitiesToGet = [
    {
      entity: ['homepage'],
      params: { populate: 'deep', }
    },
    {
      entity: ['pages', 'projects'],
      params: {
        populate: 'deep',
        'pagination[pageSize]': 100,
      }
    }
  ]

  const entities = entitiesToGet.reduce((acc, curr) => {
    if (!Array.isArray(curr.entity)) return [...acc, curr]

    curr.entity.forEach(collection => {
      acc.push({
        entity: collection,
        params: curr.params
      })
    })

    return acc
  }, [])

  const pages = await Promise.all(entities.map(({
    entity,
    params
  }) => getEntity({
    entity,
    params
  })))

  const paths = pages.reduce((acc, { data }) => {
    if (!data) return acc

    const pathData = Array.isArray(data) ? data : [data]
    const pathsList = pathData.map(({
      id,
      attributes
    }) => {
      return {
        params: {
          id,
          slug: generateSlug(attributes)
        },
        // locale: attributes.locale
      }
    })

    return  [...acc, ...pathsList]
  }, [])


  try {
    const pagesCache = pages.reduce((acc, {
      data,
      entity
    }) => {
      if (!data || data.length === 0) return acc

      const pathData = Array.isArray(data) ? data : [data]
      const pathsCached = pathData.map(({
        id,
        attributes,
      }) => {
        return {
          id,
          slug: generateSlug(attributes, true),
          isHomepage: checkHomepageSlug(attributes.slug),
          entity,
        }
      })


      return  [...acc, ...pathsCached]
    }, [])

    await writeFile('static-paths', JSON.stringify([...pagesCache]), 'utf-8')
  } catch (error) {
    console.error('Generate cache file error', error)
  }

  return {
    paths: paths,
    fallback: false
  }
}

export const getStaticProps = async ({ params }) => {
  const cacheFile = await readFile('static-paths', 'utf-8')
  const staticPaths = JSON.parse(cacheFile)

  const {
    id,
    slug,
    entity,
    isHomepage
  } = staticPaths.find((path) => {
    return (params.slug ? path.slug.join('/') === params.slug.join('/') : path.isHomepage)
  })

  const currentPage = isHomepage ? slug : slug.join('/')
  const getData = isHomepage ? getEntity : getPageData

  const [page, menus = {}, options] = await Promise.all([
    getData({
      entity,
      id,
      params: { populate: 'deep' }
    }),
    getMenus(),
    getOptions(),
  ])

  if (!page.data) return false
  const data = !!page.data && Array.isArray(page.data) ? page.data[0] : page.data

  const additionalData = await normalizeAdditionalData(data)
  const sections =  await normalizeData(data.attributes, additionalData, isHomepage)
  const metadata = await normalizeMetadata(data.attributes)

  const footer =  normalizeFooterData(menus.footer, currentPage)
  const header = normalizeHeaderData(menus.header, currentPage)
  const lateral = normalizeLateralNavigationData(sections)

  return {
    props: {
      currentPage,
      entity: entity,
      metadata,
      options: {
        isHomepage,
        lateral,
        ...options,
      },
      sections,
      additionalData,
      menus: {
        footer,
        header,
      },
    }
  }
}

export default function Page({
  sections,
  metadata,
  currentPage,
  entity
}) {

  return (
    <DefaultLayout metadata={metadata} currentEntity={entity}>
      <SectionBuilder data={sections} currPage={currentPage}/>
    </DefaultLayout>
  )
}
