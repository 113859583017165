import styled from 'styled-components'
import { motion } from 'framer-motion'

import Button from '@/atoms/Button'

const common = `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledRegionContent = styled(motion.div)`
  ${common};
  height: 100%;
  align-items: stretch;
  position: relative;

  .Modal_close{
    place-self: start;
    top: 40px;
    right: 40px;
    position: absolute;
  }
`
export const StyledRegionContentSteps = styled(motion.div)`
  display: flex;
  width: 100%;
`

export const StyledRegionContentStepContainer = styled(motion.div)`
  display: inline-flex;
  width: 100%;
  overflow: auto;
  ${({ theme }) => theme.mediaQuery.md(`
    overflow: initial;
  `)}
  `

export const StyledRegionContentStep = styled(motion.div)`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.rem(40)} 0`};
`

export const StyledRegionContentCities = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  /* ${({ rows }) =>`
      grid-template-rows: repeat(${rows <= 8 ? 8 : rows},1fr);
    `} */

  /* grid-auto-flow: column; */

  flex-wrap: wrap;
  max-height: 100%;
  overflow: auto;
  margin-top: ${({ theme }) => theme.rem(56)};
  margin-bottom: auto;

  ${({ theme }) => theme.paddingX(40)};
  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: repeat(2,1fr);
    column-gap: ${theme.rem(60)};
  `)}
`

export const StyledRegionContentCityButton = styled(Button)`
  display: block;
  text-align: left;
  ${({ theme }) => theme.paddingY(16)};
  color:${({ theme }) => theme.colors.primary.default()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale.gray2};
  border-radius: 0;

  .CityButton_icon{
    font-size: ${({ theme }) => theme.rem(28)};
    color: ${({ theme }) => theme.colors.grayscale.black()};
    margin-right: ${({ theme }) => theme.rem(15)} !important;
  }
  > span{
    display: grid;
    grid-template-areas: "icon city cta";
    grid-template-columns: 1fr 19fr 2fr;
    align-items: flex-end;
    text-transform: none;
  }

  .CityButton_cta{
    display: flex;
    align-items: center;
    column-gap: ${({ theme }) => theme.rem(8)};
    i{
      font-size: ${({ theme }) => theme.rem(12)};
    }
  }
`

export const StyledRegionContentStepHeader = styled.div`
  display: grid;
  grid-template-areas:
    "icon empty"
    "title title"
    "description description";
  grid-template-columns: 1fr 11fr;
  padding: ${({ theme }) => `0 ${theme.rem(40)}`};
  color: ${({ theme }) => theme.colors.primary.default()};

  ${({ theme }) => theme.mediaQuery.md(`
    grid-template-columns: 11fr 1fr;
    grid-template-areas:
      "title icon"
      "description description";
  `)};

  .Header_title{
    grid-area: title;
    text-align: center;
    margin-top: ${({ theme }) => theme.rem(64)};
    ${({ theme }) => theme.mediaQuery.md(`
      text-align: left;
    `)}
  }

  .Back{
    grid-area: icon;
    margin-right: ${({ theme }) => theme.rem(32)};
    color: ${({ theme }) => theme.colors.grayscale.black()};
    height: 20px;
    i{
      font-size: ${({ theme }) => theme.rem(20)};
    }
  }

  .Header_description{
    grid-area: description;
    margin-top: ${({ theme }) => theme.rem(16)};

  }
`
